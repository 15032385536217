<template>
  <div>
    <dt-layout-dialog ref="assuserDiaRef" title="关联用户" cssname="mind-dia" @hide="diaHide">
      <!-- v-slot:context="{ setSlotRef, getDialogRef }" -->
      <template v-slot:context="">
        <div class="assuser-wrap">
          <div class="assuser-top">
            <el-select v-model="formData.dept_ids" class="dt-base-select" placeholder="请选择单位" @change="deptChange">
              <el-option v-for="item in deptList" :value="item.value" :label="item.label" :key="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="assuser-bottom cs-flex-ss">
            <div class="assuser-user">
              <el-radio-group v-model="formData.user_ids" class="assuser-user-ul cs-flex-col-ss">
                <el-radio v-for="item in userList" :key="item.id" :label="item.id" :value="item.id" class="assuser-user-li">{{ item.name }}</el-radio>
              </el-radio-group>
            </div>
            <div class="assuser-role">
              <div class="cs-flex-bc cs-width-p100 assuser-role-top">
                <span>已选: {{ formData.role_ids ? 1 : 0 }}个角色</span>
                <el-button text class="clear-role" @click.stop="clearRole">清空</el-button>
              </div>
              <el-radio-group v-model="formData.role_ids" class="assuser-role-ul cs-flex-col-ss" @change="roleChange">
                <el-radio :label="item.id" :value="item.id" v-for="item in roleList" class="assuser-role-li" :key="item.id">{{ item.name }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="cs-flex dt-insert-bar">
            <el-button @click="addTag" type="primary">保存</el-button>
            <el-button @click="closeFn">取消</el-button>
          </div>
        </div>
      </template>
    </dt-layout-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "assuserDia",
  title: "关联用户",
});
</script>
<script setup lang="ts">
import * as Toast from "@darrytech/extends/utilities/toastutil";
import {
  inject,
  onMounted,
  reactive,
  ref,
  toRaw,
  onBeforeUnmount,
  shallowRef,
  nextTick,
} from "vue";
const emits = defineEmits(["submitForm"]);
const network: any = inject("$network");
const assuserDiaRef = ref();
const curJie = reactive({
  id: undefined,
  level: undefined,
  name: undefined,
  pid: undefined,
});
const formData = reactive({
  dept_ids: undefined,
  role_ids: undefined,
  user_ids: undefined,
}) as any;
const deptList = ref([]) as any;
const roleList = ref([]) as any;
const userList = ref([]) as any;
const curRole = ref();
const getDept = (type: any, regionid: any) => {
  //region_id: regionid
  network.dteqms
    .listFindDeptByType({ types: type, region_id: regionid })
    .then((rsp: any) => {
      deptList.value = rsp.rows.map((v) => {
        return { value: v.id, label: v.name };
      });
      assuserDiaRef.value.show();
    });
};
const getRole = (dept_id: any) => {
  network.dteqms
    .listBaseRole({ dept_id: dept_id, pageIndex: 1, pageSize: 99999 })
    .then((rsp: any) => {
      roleList.value = rsp.rows;
    });
};

const getUser = (dept_id: any) => {
  network.dteqms
    .listBaseUser({ dept_id: dept_id, pageIndex: 1, pageSize: 99999 })
    .then((rsp: any) => {
      userList.value = rsp.rows;
    });
};
const deptChange = (val: any) => {
  formData.role_ids = undefined;
  formData.user_ids = undefined;
  getRole(val);
  getUser(val);
};
const clearRole = () => {
  formData.role_ids = undefined;
  curRole.value = undefined;
};
const roleChange = (val: any) => {
  curRole.value = roleList.value.find((item: any) => item.id == val);
};
const addTag = () => {
  if (!formData.dept_ids) {
    Toast.error(`请选择单位！`);
    return false;
  }
  if (!formData.user_ids) {
    Toast.error(`请选择人员！`);
    return false;
  }
  if (!formData.role_ids) {
    Toast.error(`请选择角色！`);
    return false;
  }
  let item = {
    di: formData.dept_ids,
    dn:
      deptList.value?.find((v: any) => v.value == formData.dept_ids)?.label ||
      "",

    ui: formData.user_ids,
    un:
      userList.value?.find((v: any) => v.id == formData.user_ids)?.label || "",

    ri: formData.role_ids,
    rn:
      roleList.value?.find((v: any) => v.id == formData.role_ids)?.label || "",
    g: curRole.value ? curRole.value.grants : "",
  };
  // 调用接口
  let tags: any = [];
  tags.push(item);
  let param = {
    data: JSON.stringify(tags),
    project_id: curJie.id,
  };
  network.dteqms.saveOrUpdateProjectGrants(param).then(() => {
    Toast.info(`操作成功！`);
    closeFn();
    emits("submitForm");
  });
};
const closeFn = () => {
  assuserDiaRef.value.hide();
  diaHide();
};

const diaHide = () => {
  Object.assign(formData, {
    dept_ids: undefined,
    role_ids: undefined,
    user_ids: undefined,
  });
  roleList.value = [];
  userList.value = [];
};
const show = (param: any, typeDept: string, regionid: string) => {
  Object.assign(
    curJie,
    {
      id: undefined,
      level: undefined,
      name: undefined,
      pid: undefined,
    },
    param
  );
  getDept(typeDept, regionid);
};
defineExpose({ show });
</script>
<style lang="scss">
.mind-dia {
  .el-dialog__body {
    .dt-dialog-header {
      width: auto;
    }
    .assuser-wrap {
      width: 876px;
      background: #fff;
      padding: 0 20px 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
.assuser-top {
  padding-bottom: 20px;
}
.assuser-bottom {
  width: 100%;
  height: 310px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #dee0e3;
  .assuser-user-ul,
  .assuser-role-ul {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 12px;
    .assuser-user-li,
    .assuser-role-li {
      width: 100%;
      height: 36px;
      font-size: 14px;
      color: #333333;
      margin: 0 0 5px;
    }
  }
  .assuser-user {
    width: 50%;
    border-right: 1px solid #dee0e3;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
    }
    :deep(.assuser-user-ul) {
      .assuser-user-li {
        .el-radio__input {
          display: none !important;
        }
        .el-radio__label {
          box-sizing: border-box;
          width: 100%;
          line-height: 36px;
          color: #333333;
        }
        &.is-checked {
          background: #e1ecff;
          border-radius: 4px 4px 4px 4px;
          .el-radio__label {
            color: #2c66ff;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              width: 12px;
              height: 9px;
              background: url("@/assets/imgs/layout/icon-check.png") no-repeat
                center 0;
              background-size: 12px auto;
            }
          }
        }
      }
    }
  }
  .assuser-role {
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
    }
    .assuser-role-top {
      box-sizing: border-box;
      padding: 10px 12px 0;
    }

    .clear-role {
      font-size: 14px;
      color: #2c66ff;
    }
  }
}
</style>
