/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface JSONObject {
  empty?: boolean;
  [key: string]: any;
}

export type DataBuffer = object;

export namespace Dtsystem {
  /**
   * No description
   * @tags c
   * @name UploadMinio
   * @summary 上传视频/图片
   * @request POST:/dtsystem/upload_minio
   * @secure
   */
  export namespace UploadMinio {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      /**
       * 文件
       * @format binary
       */
      filePart: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags a
   * @name ReloadConfigure
   * @summary 刷新接口及以功能
   * @request POST:/dtsystem/reloadConfigure
   * @secure
   */
  export namespace ReloadConfigure {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags c
   * @name LinkUpload
   * @summary 根据链接上传视频/图片
   * @request POST:/dtsystem/link_upload
   * @secure
   */
  export namespace LinkUpload {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 文件路径 */
      fileUrl: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags a
   * @name FuncConfigure
   * @summary 获取功能页面配置
   * @request POST:/dtsystem/funcConfigure
   * @secure
   */
  export namespace FuncConfigure {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称 */
      module: string;
      /** 功能名称 */
      funcname: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags d
   * @name DynamicInvoke
   * @summary 动态调用模块接口
   * @request POST:/dtsystem/dynamicInvoke
   * @secure
   */
  export namespace DynamicInvoke {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称 */
      module____: string;
      /** 接口名称 */
      apiname____: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags a
   * @name ApiConfigure
   * @summary 获取接口显示配置
   * @request POST:/dtsystem/apiConfigure
   * @secure
   */
  export namespace ApiConfigure {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称 */
      module: string;
      /** 接口名称 */
      apiname: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags c
   * @name Minio
   * @summary 动态调用minio
   * @request GET:/dtsystem/minio/**
   * @secure
   */
  export namespace Minio {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DataBuffer[];
  }
  /**
   * No description
   * @tags b
   * @name UserLogout
   * @summary 用户退出登录
   * @request POST:/dtsystem/userLogout
   * @secure
   */
  export namespace UserLogout {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 动态名称 */
      name?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags b
   * @name UserLogin
   * @summary 用户登录鉴权
   * @request POST:/dtsystem/userLogin
   * @secure
   */
  export namespace UserLogin {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 动态名称 */
      name?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name SortOutput
   * @summary 排序输出参数
   * @request POST:/dtsystem/sortOutput
   * @secure
   */
  export namespace SortOutput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 出参ID */
      id?: string;
      /** 出参名称 */
      name?: string;
      /** 上移下移 */
      upper?: string;
      /** 出参描述 */
      memo?: string;
      /** 出参版本 */
      version?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 组件模板 */
      template?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 接口ID */
      api_id?: string;
      /** 列表宽度 */
      width?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name SortOutputM
   * @summary 排序输出参数(移动端)
   * @request POST:/dtsystem/sortOutputM
   * @secure
   */
  export namespace SortOutputM {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 出参ID */
      id?: string;
      /** 上移下移 */
      upper?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name SortInput
   * @summary 排序输入参数
   * @request POST:/dtsystem/sortInput
   * @secure
   */
  export namespace SortInput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 上移下移 */
      upper?: string;
      /** 入参名称 */
      name?: string;
      /** 入参ID */
      id?: string;
      /** 入参描述 */
      memo?: string;
      /** 入参版本 */
      version?: string;
      /** 默认取值 */
      defvalue?: string;
      /** 是否必填 */
      isnotnull?: string;
      /** 验证规则 */
      validate?: string;
      /** 是否只读 */
      readonly?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 组件模板 */
      template?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 接口ID */
      api_id?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name SortInputM
   * @summary 排序输入参数(移动端)
   * @request POST:/dtsystem/sortInputM
   * @secure
   */
  export namespace SortInputM {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 上移下移 */
      upper?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name SortGroup
   * @summary 排序系统功能分组
   * @request POST:/dtsystem/sortGroup
   * @secure
   */
  export namespace SortGroup {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 系统所属分组ID */
      id?: string;
      /** 上移下移 */
      upper?: string;
      /** 名称 */
      "group_name "?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name SortFunct
   * @summary 排序功能页面
   * @request POST:/dtsystem/sortFunct
   * @secure
   */
  export namespace SortFunct {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 功能ID */
      id?: string;
      /** 上下移动 */
      upper?: string;
      /** 名称 */
      name?: string;
      /** 描述 */
      memo?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags sys-config-controller
   * @name ReloadAllConfigure
   * @summary 刷新接口及以功能
   * @request POST:/dtsystem/reloadAllConfigure
   * @secure
   */
  export namespace ReloadAllConfigure {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name QuoteFunct
   * @summary 查询引用页面
   * @request POST:/dtsystem/quoteFunct
   * @secure
   */
  export namespace QuoteFunct {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块id */
      module_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags schema-controller
   * @name PreviewSql
   * @summary 根据数据表格预览SQL
   * @request POST:/dtsystem/previewSQL
   * @secure
   */
  export namespace PreviewSql {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表格名称 */
      table: string;
      /**
       * 生成类型(1插入2删除3修改4查询5详情)
       * @format int32
       */
      apitype: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags login-controller
   * @name Login
   * @summary 登录
   * @request POST:/dtsystem/login
   * @secure
   */
  export namespace Login {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 登录名 */
      name: string;
      /** 密码 */
      pass?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListValidation
   * @summary 查询表单效验规则
   * @request POST:/dtsystem/listValidation
   * @secure
   */
  export namespace ListValidation {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表单类型 */
      type?: string;
      /** 描述 */
      memo?: string;
      /** 错误提示信息 */
      msg?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 正则 */
      content?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListUser
   * @summary 查询系统用户配置
   * @request POST:/dtsystem/listUser
   * @secure
   */
  export namespace ListUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 用户姓名 */
      name?: string;
      /** 所属角色 */
      role?: string;
      /** 真实姓名 */
      rname?: string;
      /** 身份证号 */
      cardid?: string;
      /** 手机号 */
      phone?: string;
      /** 用户ID */
      id?: string;
      /** 用户密码 */
      pass?: string;
      /** 用户授权 */
      grants?: string;
      /** 随机秘钥 */
      sailt?: string;
      /** 当前状态 */
      status?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListUserById
   * @summary 根据id拿名称
   * @request POST:/dtsystem/listUserById
   * @secure
   */
  export namespace ListUserById {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 真实姓名 */
      rname?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListTemplate
   * @summary 查询系统组件配置
   * @request POST:/dtsystem/listTemplate
   * @secure
   */
  export namespace ListTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 组件名称 */
      name?: string;
      /** 组件类型 */
      type?: string;
      /** 组件ID */
      id?: string;
      /** 绑定组件 */
      bind?: string;
      /** 组件配置 */
      config?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListStudys
   * @summary studyList
   * @request POST:/dtsystem/listStudys
   * @secure
   */
  export namespace ListStudys {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 名称 */
      name?: string;
      /** 年龄 */
      age?: string;
      /** 性别 */
      sex?: string;
      /** 手机号 */
      phone?: string;
      /** 星座 */
      startsign?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags sys-config-controller
   * @name ListSql
   * @summary 根据模块和接口名查sql
   * @request POST:/dtsystem/listSql
   * @secure
   */
  export namespace ListSql {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListSms
   * @summary 查询短信记录
   * @request POST:/dtsystem/listSms
   * @secure
   */
  export namespace ListSms {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 发送状态 */
      status?: string;
      /** 短信类型 */
      type?: string;
      /** 手机号 */
      phone?: string;
      /** 短信内容 */
      sms?: string;
      /** 发送时间 */
      send_time?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 短信参数(json) */
      para?: string;
      /** 时效性(分钟) */
      time?: string;
      /** 回执时间 */
      receipt_time?: string;
      /** 回复时间 */
      reply_time?: string;
      /** 回复内容 */
      reply_content?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListSmsTemplate
   * @summary 查询短信模板
   * @request POST:/dtsystem/listSmsTemplate
   * @secure
   */
  export namespace ListSmsTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      status?: string;
      /** 模板类型 */
      type?: string;
      /** 短信模板ID */
      template_id?: string;
      /** 短信模板内容 */
      template?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListRole
   * @summary 查询系统角色配置
   * @request POST:/dtsystem/listRole
   * @secure
   */
  export namespace ListRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 角色名称 */
      name?: string;
      /** 角色描述 */
      memo?: string;
      /** 角色授权 */
      grants?: string;
      /** 角色ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListQuoteFunct
   * @summary 返回页面数据
   * @request POST:/dtsystem/listQuoteFunct
   * @secure
   */
  export namespace ListQuoteFunct {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块id */
      module_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListProject
   * @summary 查询系统项目配置
   * @request POST:/dtsystem/listProject
   * @secure
   */
  export namespace ListProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 项目名称 */
      name?: string;
      /** 项目描述 */
      memo?: string;
      /** 公司名称 */
      company?: string;
      /** 项目ID */
      id?: string;
      /** 项目人员 */
      pro_user?: string;
      /** 项目经理 */
      manager?: string;
      /** 项目地址 */
      address?: string;
      /** 项目版本 */
      pro_version?: string;
      /** 产品设计背景 */
      pro_background?: string;
      /** 总体需求 */
      total_demand?: string;
      /** 业务功能树 */
      function_tree?: string;
      /** 业务角色描述 */
      role_describe?: string;
      /** 界面设计原则内容 */
      des_principle?: string;
      /** 界面设计要求内容 */
      ask_principle?: string;
      /** 性能要求内容 */
      performance?: string;
      /** 其他需求 */
      other_ask?: string;
      /** 附件 */
      annex?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListProcess
   * @summary 查询流程模板
   * @request POST:/dtsystem/listProcess
   * @secure
   */
  export namespace ListProcess {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 流程模板名称 */
      name?: string;
      /** 流程模板描述 */
      describe?: string;
      /** 流程模板ID */
      id?: string;
      /** 流程模板内容 */
      content?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListProcessApi
   * @summary 查询接口流程模板表
   * @request POST:/dtsystem/listProcessApi
   * @secure
   */
  export namespace ListProcessApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 名称 */
      name?: string;
      /** 描述 */
      describe?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 内容 */
      content?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListOutput
   * @summary 查询接口输出参数
   * @request POST:/dtsystem/listOutput
   * @secure
   */
  export namespace ListOutput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 出参ID */
      id?: string;
      /** 出参名称 */
      name?: string;
      /** 出参描述 */
      memo?: string;
      /** 出参版本 */
      version?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 组件模板 */
      template?: string;
      /** 接口ID */
      api_id?: string;
      /** 列表宽度 */
      width?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 客户端类型 */
      client_type?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListModule
   * @summary 查询系统功能模块
   * @request POST:/dtsystem/listModule
   * @secure
   */
  export namespace ListModule {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称 */
      name?: string;
      /** 模块描述 */
      memo?: string;
      /** 模块ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListManyFunct
   * @summary 根据系统查询多个功能页面
   * @request POST:/dtsystem/listManyFunct
   * @secure
   */
  export namespace ListManyFunct {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 接口名称 */
      name?: string;
      /** 接口描述 */
      memo?: string;
      /** 所属模块 */
      module_id?: string;
      /** 执行语句 */
      sqltext?: string;
      /** 执行类型(1框架2模块) */
      ivktype?: string;
      /** 接口类型(1数据2混合) */
      apitype?: string;
      /** 本地方法 */
      ivkfunc?: string;
      /** 创建者 */
      create_user?: string;
      /** 流程语句 */
      scptext?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListJobTemplate
   * @summary 查询系统作业模板
   * @request POST:/dtsystem/listJobTemplate
   * @secure
   */
  export namespace ListJobTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 模板名称 */
      name?: string;
      /** 所属模块 */
      module_id?: string;
      /** 接口id */
      api_id?: string;
      /** 内容 */
      content?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListInput
   * @summary 查询接口输入参数
   * @request POST:/dtsystem/listInput
   * @secure
   */
  export namespace ListInput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 入参ID */
      id?: string;
      /** 入参名称 */
      name?: string;
      /** 入参描述 */
      memo?: string;
      /** 入参版本 */
      version?: string;
      /** 默认取值 */
      defvalue?: string;
      /** 是否必填 */
      isnotnull?: string;
      /** 验证规则 */
      validate?: string;
      /** 是否只读 */
      readonly?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 组件模板 */
      template?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 接口ID */
      api_id?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 客户端类型 */
      client_type?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListGroup
   * @summary 查询系统功能分组
   * @request POST:/dtsystem/listGroup
   * @secure
   */
  export namespace ListGroup {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 组名称 */
      group_name?: string;
      /** 所属模块 */
      module_id?: string;
      /** 系统所属分组ID */
      id?: string;
      /** 英文名称 */
      english_name?: string;
      /** 排序 */
      sort?: string;
      /** 图标 */
      icon?: string;
      /** 默认图标 */
      default_icon?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListFunct
   * @summary 查询系统功能页面
   * @request POST:/dtsystem/listFunct
   * @secure
   */
  export namespace ListFunct {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 功能名称 */
      name?: string;
      /** 功能描述 */
      memo?: string;
      /** 所属模块 */
      module_id?: string;
      /** 所属分组 */
      group_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListErrcode
   * @summary 查询错误编码配置
   * @request POST:/dtsystem/listErrcode
   * @secure
   */
  export namespace ListErrcode {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 错误描述 */
      errdesc?: string;
      /** 错误级别 */
      errlevel?: string;
      /** 错误类型 */
      errtype?: string;
      /** 所属模块 */
      module_id?: string;
      /** 错误ID */
      id?: string;
      /** 错误编码 */
      errcode?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListEnviron
   * @summary 查询项目环境配置
   * @request POST:/dtsystem/listEnviron
   * @secure
   */
  export namespace ListEnviron {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 环境名称 */
      name?: string;
      /** 环境描述 */
      memo?: string;
      /** 所属项目 */
      project_id?: string;
      /** 环境ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListDialogBox
   * @summary 查询弹框组件表
   * @request POST:/dtsystem/listDialogBox
   * @secure
   */
  export namespace ListDialogBox {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 组件名称 */
      name?: string;
      /** 所属模块 */
      module_id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 上传文件 */
      upload_file?: string;
      /** 组件模板 */
      template?: string;
      /** 功能流程 */
      workflow?: string;
      /** 功能权限 */
      grants?: string;
      /** 功能配置 */
      configure?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListDefect
   * @summary 查询缺陷记录
   * @request POST:/dtsystem/listDefect
   * @secure
   */
  export namespace ListDefect {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属模块 */
      from_module?: string;
      /** 所属页面 */
      funct_id?: string;
      /** 状态 */
      status?: string;
      /** 优先级 */
      priority?: string;
      /** 严重程度 */
      severity?: string;
      /** 指派给 */
      assign?: string;
      /** 解决方案 */
      solution?: string;
      /** 缺陷记录ID */
      id?: string;
      /** 标题 */
      title?: string;
      /** 描述 */
      describe?: string;
      /** 重现步骤 */
      step_reproduction?: string;
      /** 附件 */
      annex?: string;
      /** 视频 */
      vedio_path?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListDefectBaseDict
   * @summary 查询缺陷记录基础字典
   * @request POST:/dtsystem/listDefectBaseDict
   * @secure
   */
  export namespace ListDefectBaseDict {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 模块ID */
      module?: string;
      /** 分组ID */
      group_id?: string;
      /** 分组名称 */
      group_name?: string;
      /** 枚举ID */
      item_id?: string;
      /** 枚举名称 */
      item_name?: string;
      /** 排序 */
      sort?: string;
      /** 备注 */
      remark?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListBaseDict
   * @summary 查询基础字典
   * @request POST:/dtsystem/listBaseDict
   * @secure
   */
  export namespace ListBaseDict {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 模块ID */
      module?: string;
      /** 分组ID */
      group_id?: string;
      /** 分组名称 */
      group_name?: string;
      /** 枚举ID */
      item_id?: string;
      /** 枚举名称 */
      item_name?: string;
      /** 排序 */
      sort?: string;
      /** 备注 */
      remark?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name ListApi
   * @summary 查询系统数据接口
   * @request POST:/dtsystem/listApi
   * @secure
   */
  export namespace ListApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 接口名称 */
      name?: string;
      /** 接口描述 */
      memo?: string;
      /** 所属模块 */
      module_id?: string;
      /** 执行类型 */
      ivktype?: string;
      /** dffd */
      dfsfd?: string;
      /** 接口ID */
      id?: string;
      /** 执行语句 */
      sqltext?: string;
      /** 接口类型 */
      apitype?: string;
      /** 本地方法 */
      ivkfunc?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 流程语句 */
      scptext?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoValidation
   * @summary 表单效验规则详情
   * @request POST:/dtsystem/infoValidation
   * @secure
   */
  export namespace InfoValidation {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 表单类型ID(dtsystem_template) */
      type?: string;
      /** 正则表达式内容 */
      content?: string;
      /** 描述 */
      memo?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoUser
   * @summary 详情系统用户配置
   * @request POST:/dtsystem/infoUser
   * @secure
   */
  export namespace InfoUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 用户ID */
      id?: string;
      /** 用户姓名 */
      name?: string;
      /** 用户密码 */
      pass?: string;
      /** 所属角色 */
      role?: string;
      /** 用户授权 */
      grants?: string;
      /** 手机号码 */
      phone?: string;
      /** 随机秘钥 */
      sailt?: string;
      /** 真实姓名 */
      rname?: string;
      /** 身份证号 */
      cardid?: string;
      /** 当前状态 */
      status?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoTemplate
   * @summary 详情系统组件配置
   * @request POST:/dtsystem/infoTemplate
   * @secure
   */
  export namespace InfoTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 组件ID */
      id?: string;
      /** 组件名称 */
      name?: string;
      /** 组件类型 */
      type?: string;
      /** 绑定组件 */
      bind?: string;
      /** 组件配置 */
      config?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoStudy
   * @summary studyRowline
   * @request POST:/dtsystem/infoStudy
   * @secure
   */
  export namespace InfoStudy {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 名称 */
      name?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoSms
   * @summary 短信记录详情
   * @request POST:/dtsystem/infoSms
   * @secure
   */
  export namespace InfoSms {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 发送状态(0:未发送;1:失败;2:已发送;3:已回执;4:已回复) */
      status?: string;
      /** 短信类型(1:验证码;2:通知) */
      type?: string;
      /** 手机号 */
      phone?: string;
      /** 短信内容 */
      sms?: string;
      /** 短信参数(json) */
      para?: string;
      /** 时效性(分钟) */
      time?: string;
      /** 发送时间 */
      send_time?: string;
      /** 回执时间 */
      receipt_time?: string;
      /** 回复时间 */
      reply_time?: string;
      /** 回复内容 */
      reply_content?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoSmsTemplate
   * @summary 短信模板详情
   * @request POST:/dtsystem/infoSmsTemplate
   * @secure
   */
  export namespace InfoSmsTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核状态(0:未审核;1:未通过;2:已通过) */
      status?: string;
      /** 模板类型(1:验证码;2:通知) */
      type?: string;
      /** 短信模板ID */
      template_id?: string;
      /** 短信模板内容 */
      template?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoRole
   * @summary 详情系统角色配置
   * @request POST:/dtsystem/infoRole
   * @secure
   */
  export namespace InfoRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 角色名称 */
      name?: string;
      /** 角色描述 */
      memo?: string;
      /** 角色授权 */
      grants?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 角色ID */
      id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoProject
   * @summary 详情系统项目配置
   * @request POST:/dtsystem/infoProject
   * @secure
   */
  export namespace InfoProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 项目ID */
      id?: string;
      /** 项目名称 */
      name?: string;
      /** 项目描述 */
      memo?: string;
      /** 项目人员 */
      pro_user?: string;
      /** 项目经理 */
      manager?: string;
      /** 项目地址 */
      address?: string;
      /** 项目版本 */
      pro_version?: string;
      /** 公司名称 */
      company?: string;
      /** 总体需求 */
      total_demand?: string;
      /** 产品设计背景 */
      pro_background?: string;
      /** 业务功能树 */
      function_tree?: string;
      /** 业务角色描述 */
      role_describe?: string;
      /** 界面设计原则内容 */
      des_principle?: string;
      /** 界面设计要求内容 */
      ask_principle?: string;
      /** 性能要求内容 */
      performance?: string;
      /** 其他需求 */
      other_ask?: string;
      /** 附件 */
      annex?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoProcess
   * @summary 详情流程模板
   * @request POST:/dtsystem/infoProcess
   * @secure
   */
  export namespace InfoProcess {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 流程模板ID */
      id?: string;
      /** 流程模板名称 */
      name?: string;
      /** 流程模板描述 */
      describe?: string;
      /** 流程模板内容 */
      content?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoProcessApi
   * @summary 接口流程模板表详情
   * @request POST:/dtsystem/infoProcessApi
   * @secure
   */
  export namespace InfoProcessApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 名称 */
      name?: string;
      /** 内容 */
      content?: string;
      /** 描述 */
      describe?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoOutput
   * @summary 详情接口输出参数
   * @request POST:/dtsystem/infoOutput
   * @secure
   */
  export namespace InfoOutput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 出参ID */
      id?: string;
      /** 出参名称 */
      name?: string;
      /** 出参描述 */
      memo?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 出参版本 */
      version?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 组件模板 */
      template?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 接口ID */
      api_id?: string;
      /** 列表宽度 */
      width?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoModule
   * @summary 详情系统功能模块
   * @request POST:/dtsystem/infoModule
   * @secure
   */
  export namespace InfoModule {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块ID */
      id?: string;
      /** 模块名称 */
      name?: string;
      /** 模块描述 */
      memo?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoJobTemplate
   * @summary 系统作业模板详情
   * @request POST:/dtsystem/infoJobTemplate
   * @secure
   */
  export namespace InfoJobTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 模板名称 */
      name?: string;
      /** 所属模块 */
      module_id?: string;
      /** 接口id */
      api_id?: string;
      /** 内容 */
      content?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoInput
   * @summary 详情接口输入参数
   * @request POST:/dtsystem/infoInput
   * @secure
   */
  export namespace InfoInput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 入参ID */
      id?: string;
      /** 入参名称 */
      name?: string;
      /** 入参描述 */
      memo?: string;
      /** 入参版本 */
      version?: string;
      /** 默认取值 */
      defvalue?: string;
      /** 验证规则 */
      validate?: string;
      /** 是否必填 */
      isnotnull?: string;
      /** 是否只读 */
      readonly?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 组件模板 */
      template?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 接口ID */
      api_id?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoGroup
   * @summary 详情系统功能分组
   * @request POST:/dtsystem/infoGroup
   * @secure
   */
  export namespace InfoGroup {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 系统所属分组ID */
      id?: string;
      /** 所属组名称 */
      group_name?: string;
      /** 英文名称 */
      english_name?: string;
      /** 所属模块id */
      module_id?: string;
      /** 排序 */
      sort?: string;
      /** 图标 */
      icon?: string;
      /** 默认图标 */
      default_icon?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoFunct
   * @summary 详情系统功能页面
   * @request POST:/dtsystem/infoFunct
   * @secure
   */
  export namespace InfoFunct {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 功能名称 */
      name?: string;
      /** 功能描述 */
      memo?: string;
      /** 功能配置 */
      configure?: string;
      /** 所属模块 */
      module_id?: string;
      /** 组件模板 */
      template?: string;
      /** 功能流程 */
      workflow?: string;
      /** 功能权限 */
      grants?: string;
      /** 功能ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoErrcode
   * @summary 详情错误编码配置
   * @request POST:/dtsystem/infoErrcode
   * @secure
   */
  export namespace InfoErrcode {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 错误ID */
      id?: string;
      /** 错误编码 */
      errcode?: string;
      /** 错误描述 */
      errdesc?: string;
      /** 错误级别(1普通2严重） */
      errlevel?: string;
      /** 错误类型(1系统2业务） */
      errtype?: string;
      /** 所属模块 */
      module_id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoEnviron
   * @summary 详情项目环境配置
   * @request POST:/dtsystem/infoEnviron
   * @secure
   */
  export namespace InfoEnviron {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 环境ID */
      id?: string;
      /** 环境名称 */
      name?: string;
      /** 环境描述 */
      memo?: string;
      /** 所属项目 */
      project_id?: string;
      /** 创建者 */
      create_user?: string;
      /** 更新时间 */
      update_time?: string;
      /** 创建时间 */
      create_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoDialogBox
   * @summary 弹框组件表详情
   * @request POST:/dtsystem/infoDialogBox
   * @secure
   */
  export namespace InfoDialogBox {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 组件名称 */
      name?: string;
      /** 上传文件 */
      upload_file?: string;
      /** 所属模块 */
      module_id?: string;
      /** 组件模板 */
      template?: string;
      /** 功能流程 */
      workflow?: string;
      /** 功能权限 */
      grants?: string;
      /** 功能配置 */
      configure?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoDefect
   * @summary 详情缺陷记录
   * @request POST:/dtsystem/infoDefect
   * @secure
   */
  export namespace InfoDefect {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属模块(1-模块2-子模块) */
      from_module?: string;
      /** 状态(新提交(默认)) */
      status?: string;
      /** 优先级(1-最高2-较高3-普通(默认)4-较低5-最低) */
      priority?: string;
      /** 严重程度(1-致命2-严重3-一般(默认)4-建议) */
      severity?: string;
      /** 指派给 */
      assign?: string;
      /** 标题 */
      title?: string;
      /** 描述 */
      describe?: string;
      /** 附件 */
      annex?: string;
      /** 解决方案(1-已修复2-转需求) */
      solution?: string;
      /** 视频 */
      vedio_path?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 缺陷记录ID */
      id?: string;
      /** 重现步骤 */
      step_reproduction?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name InfoApi
   * @summary 详情系统数据接口
   * @request POST:/dtsystem/infoApi
   * @secure
   */
  export namespace InfoApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 接口ID */
      id?: string;
      /** 接口名称 */
      name?: string;
      /** 接口描述 */
      memo?: string;
      /** 所属模块 */
      module_id?: string;
      /** 执行语句 */
      sqltext?: string;
      /** 执行类型(1框架2模块) */
      ivktype?: string;
      /** 接口类型(1数据2混合) */
      apitype?: string;
      /** 本地方法 */
      ivkfunc?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 流程语句 */
      scptext?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags other-controller
   * @name GetFunctDate
   * @summary 查询项目下的页面
   * @request POST:/dtsystem/get_funct_date
   * @secure
   */
  export namespace GetFunctDate {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 项目id
       * @format int32
       */
      id: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags other-controller
   * @name GenerateManyApi
   * @summary 批量生成接口
   * @request POST:/dtsystem/generate_many_api
   * @secure
   */
  export namespace GenerateManyApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块 */
      module: string;
      /** 表名称 */
      table?: string;
      /** 生成类型(1插入2删除3修改4查询5详情6其他) */
      apitype: string;
      /** 接口名称 */
      apiname?: string;
      /** 接口描述 */
      apimemo?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags schema-controller
   * @name GenerateApi
   * @summary 根据数据表格生成接口
   * @request POST:/dtsystem/generateAPI
   * @secure
   */
  export namespace GenerateApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表格名称 */
      table: string;
      /**
       * 生成类型(1插入2删除3修改4查询5详情)
       * @format int32
       */
      apitype: number;
      /** 接口名称 */
      apiname?: string;
      /** 接口描述 */
      apimemo: string;
      /** 模块名称 */
      module?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags product-controller
   * @name ExportSimplifyDoc
   * @summary 导出需求概要描述
   * @request POST:/dtsystem/exportSimplifyDoc
   * @secure
   */
  export namespace ExportSimplifyDoc {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称列表 */
      modules?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags product-controller
   * @name ExportRequirementDoc
   * @summary 导出详细需求文档
   * @request POST:/dtsystem/exportRequirementDoc
   * @secure
   */
  export namespace ExportRequirementDoc {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称列表 */
      modules?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags product-controller
   * @name ExportProductRelease
   * @summary 导出项目部署文件
   * @request POST:/dtsystem/exportProductRelease
   * @secure
   */
  export namespace ExportProductRelease {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称列表 */
      modules?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags product-controller
   * @name ExportInterfaceDoc
   * @summary 导出接口设计文档
   * @request POST:/dtsystem/exportInterfaceDoc
   * @secure
   */
  export namespace ExportInterfaceDoc {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称列表 */
      modules?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags product-controller
   * @name ExportDatabaseDoc
   * @summary 导出数据结构设计
   * @request POST:/dtsystem/exportDatabaseDoc
   * @secure
   */
  export namespace ExportDatabaseDoc {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称列表 */
      modules?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags other-controller
   * @name ExpRequirements
   * @summary 需求文档导出
   * @request POST:/dtsystem/exp_requirements
   * @secure
   */
  export namespace ExpRequirements {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 项目id
       * @format int32
       */
      id: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags schema-controller
   * @name ExecuteSql
   * @summary 根据参数动态执行SQL
   * @request POST:/dtsystem/executeSQL
   * @secure
   */
  export namespace ExecuteSql {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 执行语句 */
      sql: string;
      /** 动态参数 */
      param?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditValidation
   * @summary 编辑表单效验规则
   * @request POST:/dtsystem/editValidation
   * @secure
   */
  export namespace EditValidation {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表单类型 */
      type?: string;
      /** 描述 */
      memo?: string;
      /** 正则 */
      content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags login-controller
   * @name EditUser
   * @summary 编辑系统用户配置
   * @request POST:/dtsystem/editUser
   * @secure
   */
  export namespace EditUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 用户id
       * @format int32
       */
      id: number;
      /** 用户名 */
      name?: string;
      /** 密码 */
      pass?: string;
      /**
       * 所属角色
       * @format int32
       */
      role?: number;
      /** 用户授权 */
      grants?: string;
      /** 手机号码 */
      phone?: string;
      /** 真实姓名 */
      rname?: string;
      /** 身份证号码 */
      cardid?: string;
      /**
       * 用户状态
       * @format int32
       */
      status?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditTemplate
   * @summary 编辑系统组件配置
   * @request POST:/dtsystem/editTemplate
   * @secure
   */
  export namespace EditTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 组件名称 */
      name?: string;
      /** 组件类型 */
      type?: string;
      /** 绑定组件 */
      bind?: string;
      /** 组件配置 */
      config?: string;
      /** 组件ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditStudy
   * @summary studyUpdate
   * @request POST:/dtsystem/editStudy
   * @secure
   */
  export namespace EditStudy {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 名称 */
      name?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditSms
   * @summary 编辑短信记录
   * @request POST:/dtsystem/editSms
   * @secure
   */
  export namespace EditSms {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 发送状态 */
      status?: string;
      /** 短信类型 */
      type?: string;
      /** 手机号 */
      phone?: string;
      /** 短信内容 */
      sms?: string;
      /** 短信参数 */
      para?: string;
      /** 时效性(分钟) */
      time?: string;
      /** 发送时间 */
      send_time?: string;
      /** 回执时间 */
      receipt_time?: string;
      /** 回复时间 */
      reply_time?: string;
      /** 回复内容 */
      reply_content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditSmsTemplate
   * @summary 编辑短信模板
   * @request POST:/dtsystem/editSmsTemplate
   * @secure
   */
  export namespace EditSmsTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      status?: string;
      /** 模板类型 */
      type?: string;
      /** 短信模板ID */
      template_id?: string;
      /** 短信模板内容 */
      template?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditRole
   * @summary 编辑系统角色配置
   * @request POST:/dtsystem/editRole
   * @secure
   */
  export namespace EditRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 角色名称 */
      name?: string;
      /** 角色授权 */
      grants?: string;
      /** 角色描述 */
      memo?: string;
      /** 角色ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditProject
   * @summary 编辑系统项目配置
   * @request POST:/dtsystem/editProject
   * @secure
   */
  export namespace EditProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 项目ID */
      id?: string;
      /** 项目名称 */
      name?: string;
      /** 项目描述 */
      memo?: string;
      /** 项目人员 */
      pro_user?: string;
      /** 项目经理 */
      manager?: string;
      /** 项目地址 */
      address?: string;
      /** 项目版本 */
      pro_version?: string;
      /** 公司名称 */
      company?: string;
      /** 产品设计背景 */
      pro_background?: string;
      /** 总体需求 */
      total_demand?: string;
      /** 业务功能树 */
      function_tree?: string;
      /** 业务角色描述 */
      role_describe?: string;
      /** 界面设计原则内容 */
      des_principle?: string;
      /** 界面设计要求内容 */
      ask_principle?: string;
      /** 性能要求内容 */
      performance?: string;
      /** 其他需求 */
      other_ask?: string;
      /** 附件 */
      annex?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditProcess
   * @summary 编辑流程模板
   * @request POST:/dtsystem/editProcess
   * @secure
   */
  export namespace EditProcess {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 流程模板名称 */
      name?: string;
      /** 流程模板描述 */
      describe?: string;
      /** 流程模板内容 */
      content?: string;
      /** 流程模板ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditProcessApi
   * @summary 编辑接口流程模板表
   * @request POST:/dtsystem/editProcessApi
   * @secure
   */
  export namespace EditProcessApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 名称 */
      name?: string;
      /** 内容 */
      content?: string;
      /** 描述 */
      describe?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditOutput
   * @summary 编辑接口输出参数
   * @request POST:/dtsystem/editOutput
   * @secure
   */
  export namespace EditOutput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 出参ID */
      id?: string;
      /** 出参名称 */
      name?: string;
      /** 出参描述 */
      memo?: string;
      /** 出参版本 */
      version?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 组件模板 */
      template?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 接口ID */
      api_id?: string;
      /** 列表宽度 */
      width?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 分组编码 */
      group_code?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditModule
   * @summary 编辑系统功能模块
   * @request POST:/dtsystem/editModule
   * @secure
   */
  export namespace EditModule {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称 */
      name?: string;
      /** 模块描述 */
      memo?: string;
      /** 创建人 */
      create_name?: string;
      /** 创建时间 */
      create_time?: string;
      /** 模块ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditJobTemplate
   * @summary 编辑系统作业模板
   * @request POST:/dtsystem/editJobTemplate
   * @secure
   */
  export namespace EditJobTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 模板名称 */
      name?: string;
      /** 所属模块 */
      module_id?: string;
      /** 接口id */
      api_id?: string;
      /** 内容 */
      content?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditInput
   * @summary 编辑接口输入参数
   * @request POST:/dtsystem/editInput
   * @secure
   */
  export namespace EditInput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 入参ID */
      id?: string;
      /** 入参名称 */
      name?: string;
      /** 入参描述 */
      memo?: string;
      /** 入参版本 */
      version?: string;
      /** 默认取值 */
      defvalue?: string;
      /** 是否必填 */
      isnotnull?: string;
      /** 验证规则 */
      validate?: string;
      /** 是否只读 */
      readonly?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 组件模板 */
      template?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 接口ID */
      api_id?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditGroup
   * @summary 编辑系统功能分组
   * @request POST:/dtsystem/editGroup
   * @secure
   */
  export namespace EditGroup {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属组名称 */
      group_name?: string;
      /** 英文名称 */
      english_name?: string;
      /** 所属模块 */
      module_id?: string;
      /** 图标 */
      icon?: string;
      /** 默认图标 */
      default_icon?: string;
      /** 分组描述 */
      group_memo?: string;
      /** 系统所属分组ID */
      id?: string;
      /** 排序 */
      sort?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditFunct
   * @summary 编辑系统功能页面
   * @request POST:/dtsystem/editFunct
   * @secure
   */
  export namespace EditFunct {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 功能ID */
      id?: string;
      /** 功能名称 */
      name?: string;
      /** 功能描述 */
      memo?: string;
      /** 功能配置 */
      configure?: string;
      /** 所属模块 */
      module_id?: string;
      /** 组件模板 */
      template?: string;
      /** 功能流程 */
      workflow?: string;
      /** 功能权限 */
      grants?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 详细需求 */
      det_info?: string;
      /** 所属组 */
      group_id?: string;
      /** 排序 */
      sort?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditErrcode
   * @summary 编辑错误编码配置
   * @request POST:/dtsystem/editErrcode
   * @secure
   */
  export namespace EditErrcode {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 错误编码 */
      errcode?: string;
      /** 错误描述 */
      errdesc?: string;
      /** 错误级别 */
      errlevel?: string;
      /** 错误类型 */
      errtype?: string;
      /** 所属模块 */
      module_id?: string;
      /** 错误ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditEnviron
   * @summary 编辑项目环境配置
   * @request POST:/dtsystem/editEnviron
   * @secure
   */
  export namespace EditEnviron {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 环境名称 */
      name?: string;
      /** 环境描述 */
      memo?: string;
      /** 所属项目 */
      project_id?: string;
      /** 环境ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditDialogBox
   * @summary 编辑弹框组件表
   * @request POST:/dtsystem/editDialogBox
   * @secure
   */
  export namespace EditDialogBox {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 组件名称 */
      name?: string;
      /** 上传文件 */
      upload_file?: string;
      /** 所属模块 */
      module_id?: string;
      /** 组件模板 */
      template?: string;
      /** 功能流程 */
      workflow?: string;
      /** 功能权限 */
      grants?: string;
      /** 功能配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditDefect
   * @summary 编辑缺陷记录
   * @request POST:/dtsystem/editDefect
   * @secure
   */
  export namespace EditDefect {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属模块 */
      from_module?: string;
      /** 所属页面 */
      funct_id?: string;
      /** 状态 */
      status?: string;
      /** 优先级 */
      priority?: string;
      /** 严重程度 */
      severity?: string;
      /** 指派给 */
      assign: string;
      /** 解决方案 */
      solution?: string;
      /** 附件 */
      annex?: string;
      /** 重现步骤 */
      step_reproduction?: string;
      /** 视频 */
      vedio_path?: string;
      /** 标题 */
      title?: string;
      /** 描述 */
      describe?: string;
      /** 缺陷ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name EditApi
   * @summary 编辑系统数据接口
   * @request POST:/dtsystem/editApi
   * @secure
   */
  export namespace EditApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 接口ID */
      id?: string;
      /** 接口名称 */
      name?: string;
      /** 接口描述 */
      memo?: string;
      /** 所属模块 */
      module_id?: string;
      /** 执行语句 */
      sqltext?: string;
      /** 执行类型 */
      ivktype?: string;
      /** 接口类型 */
      apitype?: string;
      /** 本地方法 */
      ivkfunc?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 流程语句 */
      scptext?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteValidation
   * @summary 删除表单效验规则
   * @request POST:/dtsystem/deleteValidation
   * @secure
   */
  export namespace DeleteValidation {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 表单类型ID(dtsystem_template) */
      type?: string;
      /** 正则表达式内容 */
      content?: string;
      /** 描述 */
      memo?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteUser
   * @summary 删除系统用户配置
   * @request POST:/dtsystem/deleteUser
   * @secure
   */
  export namespace DeleteUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 用户ID */
      id?: string;
      /** 用户姓名 */
      name?: string;
      /** 用户密码 */
      pass?: string;
      /** 所属角色 */
      role?: string;
      /** 用户授权 */
      grants?: string;
      /** 手机号码 */
      phone?: string;
      /** 随机秘钥 */
      sailt?: string;
      /** 真实姓名 */
      rname?: string;
      /** 身份证号 */
      cardid?: string;
      /** 当前状态 */
      status?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteTemplate
   * @summary 删除系统组件配置
   * @request POST:/dtsystem/deleteTemplate
   * @secure
   */
  export namespace DeleteTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 组件ID */
      id?: string;
      /** 组件名称 */
      name?: string;
      /** 组件类型（1基础2地理3图表3组合） */
      type?: string;
      /** 绑定组件 */
      bind?: string;
      /** 组件配置 */
      config?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteStudy
   * @summary studyDelete
   * @request POST:/dtsystem/deleteStudy
   * @secure
   */
  export namespace DeleteStudy {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 名称 */
      name?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteSms
   * @summary 删除短信记录
   * @request POST:/dtsystem/deleteSms
   * @secure
   */
  export namespace DeleteSms {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 发送状态(0:未发送;1:失败;2:已发送;3:已回执;4:已回复) */
      status?: string;
      /** 短信类型(1:验证码;2:通知) */
      type?: string;
      /** 手机号 */
      phone?: string;
      /** 短信内容 */
      sms?: string;
      /** 短信参数(json) */
      para?: string;
      /** 时效性(分钟) */
      time?: string;
      /** 发送时间 */
      send_time?: string;
      /** 回执时间 */
      receipt_time?: string;
      /** 回复时间 */
      reply_time?: string;
      /** 回复内容 */
      reply_content?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteSmsTemplate
   * @summary 删除短信模板
   * @request POST:/dtsystem/deleteSmsTemplate
   * @secure
   */
  export namespace DeleteSmsTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核状态(0:未审核;1:未通过;2:已通过) */
      status?: string;
      /** 模板类型(1:验证码;2:通知) */
      type?: string;
      /** 短信模板ID */
      template_id?: string;
      /** 短信模板内容 */
      template?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteRole
   * @summary 删除系统角色配置
   * @request POST:/dtsystem/deleteRole
   * @secure
   */
  export namespace DeleteRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 角色ID */
      id?: string;
      /** 角色名称 */
      name?: string;
      /** 角色描述 */
      memo?: string;
      /** 角色授权 */
      grants?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteProject
   * @summary 新增系统项目配置
   * @request POST:/dtsystem/deleteProject
   * @secure
   */
  export namespace DeleteProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 项目ID */
      id?: string;
      /** 项目名称 */
      name?: string;
      /** 项目描述 */
      memo?: string;
      /** 项目人员 */
      pro_user?: string;
      /** 项目经理 */
      manager?: string;
      /** 项目地址 */
      address?: string;
      /** 项目版本 */
      pro_version?: string;
      /** 公司名称 */
      company?: string;
      /** 产品设计背景 */
      pro_background?: string;
      /** 总体需求 */
      total_demand?: string;
      /** 业务功能树 */
      function_tree?: string;
      /** 业务角色描述 */
      role_describe?: string;
      /** 界面设计原则内容 */
      des_principle?: string;
      /** 界面设计要求内容 */
      ask_principle?: string;
      /** 性能要求内容 */
      performance?: string;
      /** 其他需求 */
      other_ask?: string;
      /** 附件 */
      annex?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteProcess
   * @summary 删除流程模板
   * @request POST:/dtsystem/deleteProcess
   * @secure
   */
  export namespace DeleteProcess {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 流程模板ID */
      id?: string;
      /** 流程模板名称 */
      name?: string;
      /** 流程模板描述 */
      describe?: string;
      /** 流程模板内容 */
      content?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteProcessApi
   * @summary 删除接口流程模板表
   * @request POST:/dtsystem/deleteProcessApi
   * @secure
   */
  export namespace DeleteProcessApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 名称 */
      name?: string;
      /** 内容 */
      content?: string;
      /** 描述 */
      describe?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteOutput
   * @summary 删除接口输出参数
   * @request POST:/dtsystem/deleteOutput
   * @secure
   */
  export namespace DeleteOutput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 出参ID */
      id?: string;
      /** 出参名称 */
      name?: string;
      /** 出参描述 */
      memo?: string;
      /** 出参版本 */
      version?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 组件模板 */
      template?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 接口ID */
      api_id?: string;
      /** 列表宽度 */
      width?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteModule
   * @summary 删除系统功能模块
   * @request POST:/dtsystem/deleteModule
   * @secure
   */
  export namespace DeleteModule {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称 */
      name?: string;
      /** 模块描述 */
      memo?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 模块ID */
      id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteJobTemplate
   * @summary 删除系统作业模板
   * @request POST:/dtsystem/deleteJobTemplate
   * @secure
   */
  export namespace DeleteJobTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 模板名称 */
      name?: string;
      /** 所属模块 */
      module_id?: string;
      /** 接口id */
      api_id?: string;
      /** 内容 */
      content?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteInput
   * @summary 删除接口输入参数
   * @request POST:/dtsystem/deleteInput
   * @secure
   */
  export namespace DeleteInput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 入参ID */
      id?: string;
      /** 入参名称 */
      name?: string;
      /** 入参描述 */
      memo?: string;
      /** 入参版本 */
      version?: string;
      /** 默认取值 */
      defvalue?: string;
      /** 是否必填 */
      isnotnull?: string;
      /** 验证规则 */
      validate?: string;
      /** 是否只读 */
      readonly?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 组件模板 */
      template?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 接口ID */
      api_id?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteGroup
   * @summary 删除系统功能分组
   * @request POST:/dtsystem/deleteGroup
   * @secure
   */
  export namespace DeleteGroup {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 系统所属分组ID */
      id?: string;
      /** 所属组名称 */
      group_name?: string;
      /** 英文名称 */
      english_name?: string;
      /** 所属模块id */
      module_id?: string;
      /** 排序 */
      sort?: string;
      /** 图标 */
      icon?: string;
      /** 默认图标 */
      default_icon?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteFunct
   * @summary 删除系统功能页面
   * @request POST:/dtsystem/deleteFunct
   * @secure
   */
  export namespace DeleteFunct {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 功能名称 */
      name?: string;
      /** 功能描述 */
      memo?: string;
      /** 功能配置 */
      configure?: string;
      /** 所属模块 */
      module_id?: string;
      /** 组件模板 */
      template?: string;
      /** 功能流程 */
      workflow?: string;
      /** 功能权限 */
      grants?: string;
      /** 功能ID */
      id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteErrcode
   * @summary 删除错误编码配置
   * @request POST:/dtsystem/deleteErrcode
   * @secure
   */
  export namespace DeleteErrcode {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 错误ID */
      id?: string;
      /** 错误编码 */
      errcode?: string;
      /** 错误描述 */
      errdesc?: string;
      /** 错误级别(1普通2严重） */
      errlevel?: string;
      /** 错误类型(1系统2业务） */
      errtype?: string;
      /** 所属模块 */
      module_id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteEnviron
   * @summary 删除项目环境配置
   * @request POST:/dtsystem/deleteEnviron
   * @secure
   */
  export namespace DeleteEnviron {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 环境ID */
      id?: string;
      /** 环境名称 */
      name?: string;
      /** 环境描述 */
      memo?: string;
      /** 所属项目 */
      project_id?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteDialogBox
   * @summary 删除弹框组件表
   * @request POST:/dtsystem/deleteDialogBox
   * @secure
   */
  export namespace DeleteDialogBox {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 组件名称 */
      name?: string;
      /** 上传文件 */
      upload_file?: string;
      /** 所属模块 */
      module_id?: string;
      /** 组件模板 */
      template?: string;
      /** 功能流程 */
      workflow?: string;
      /** 功能权限 */
      grants?: string;
      /** 功能配置 */
      configure?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteDefect
   * @summary 删除缺陷记录
   * @request POST:/dtsystem/deleteDefect
   * @secure
   */
  export namespace DeleteDefect {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 缺陷记录ID */
      id?: string;
      /** 所属模块(1-模块2-子模块) */
      from_module?: string;
      /** 状态(新提交(默认)) */
      status?: string;
      /** 优先级(1-最高2-较高3-普通(默认)4-较低5-最低) */
      priority?: string;
      /** 严重程度(1-致命2-严重3-一般(默认)4-建议) */
      severity?: string;
      /** 指派给 */
      assign?: string;
      /** 标题 */
      title?: string;
      /** 描述 */
      describe?: string;
      /** 重现步骤 */
      step_reproduction?: string;
      /** 附件 */
      annex?: string;
      /** 解决方案(1-已修复2-转需求) */
      solution?: string;
      /** 视频 */
      vedio_path?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name DeleteApi
   * @summary 删除系统数据接口
   * @request POST:/dtsystem/deleteApi
   * @secure
   */
  export namespace DeleteApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 接口ID */
      id?: string;
      /** 接口名称 */
      name?: string;
      /** 接口描述 */
      memo?: string;
      /** 所属模块 */
      module_id?: string;
      /** 执行语句 */
      sqltext?: string;
      /** 执行类型(1框架2模块) */
      ivktype?: string;
      /** 接口类型(1数据2混合) */
      apitype?: string;
      /** 本地方法 */
      ivkfunc?: string;
      /** 创建者 */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 流程语句 */
      scptext?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags develop-controller
   * @name ApiDeveloper
   * @summary 获取接口开发配置
   * @request POST:/dtsystem/apiDeveloper
   * @secure
   */
  export namespace ApiDeveloper {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称 */
      module?: string;
      /** 接口名称 */
      apiname?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags schema-controller
   * @name AllTables
   * @summary 查询数据表格列表
   * @request POST:/dtsystem/allTables
   * @secure
   */
  export namespace AllTables {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称 */
      module?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags schema-controller
   * @name AllColumns
   * @summary 查询数据表格查询字段
   * @request POST:/dtsystem/allColumns
   * @secure
   */
  export namespace AllColumns {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表格名称 */
      table: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddValidation
   * @summary 添加表单效验规则
   * @request POST:/dtsystem/addValidation
   * @secure
   */
  export namespace AddValidation {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表单类型 */
      type?: string;
      /** 描述 */
      memo?: string;
      /** 正则 */
      content?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 排序 */
      sort?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddUser
   * @summary 新增系统用户配置
   * @request POST:/dtsystem/addUser
   * @secure
   */
  export namespace AddUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 用户姓名 */
      name: string;
      /** 所属角色 */
      role?: string;
      /** 用户授权 */
      grants?: string;
      /** 手机号码 */
      phone: string;
      /** 真实姓名 */
      rname?: string;
      /** 身份证号 */
      cardid?: string;
      /** 用户密码 */
      pass?: string;
      /** 随机秘钥 */
      sailt?: string;
      /** 当前状态 */
      status?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddTemplate
   * @summary 新增系统组件配置
   * @request POST:/dtsystem/addTemplate
   * @secure
   */
  export namespace AddTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 组件名称 */
      name?: string;
      /** 组件类型 */
      type?: string;
      /** 绑定组件 */
      bind?: string;
      /** 组件配置 */
      config?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddSms
   * @summary 添加短信记录
   * @request POST:/dtsystem/addSms
   * @secure
   */
  export namespace AddSms {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 发送状态 */
      status?: string;
      /** 短信类型 */
      type?: string;
      /** 手机号 */
      phone?: string;
      /** 短信内容 */
      sms?: string;
      /** 短信参数 */
      para?: string;
      /** 时效性(分钟) */
      time?: string;
      /** 发送时间 */
      send_time?: string;
      /** 回执时间 */
      receipt_time?: string;
      /** 回复时间 */
      reply_time?: string;
      /** 回复内容 */
      reply_content?: string;
      /** 创建用户ID */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddSmsTemplate
   * @summary 添加短信模板
   * @request POST:/dtsystem/addSmsTemplate
   * @secure
   */
  export namespace AddSmsTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      status?: string;
      /** 模板类型 */
      type?: string;
      /** 短信模板ID */
      template_id?: string;
      /** 短信模板内容 */
      template?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddRole
   * @summary 添加系统角色配置
   * @request POST:/dtsystem/addRole
   * @secure
   */
  export namespace AddRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 角色名称 */
      name?: string;
      /** 角色描述 */
      memo?: string;
      /** 角色授权 */
      grants?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddProject
   * @summary 新增系统项目配置
   * @request POST:/dtsystem/addProject
   * @secure
   */
  export namespace AddProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 项目名称 */
      name?: string;
      /** 项目描述 */
      memo?: string;
      /** 项目人员 */
      pro_user?: string;
      /** 项目经理 */
      manager?: string;
      /** 项目地址 */
      address?: string;
      /** 项目版本 */
      pro_version?: string;
      /** 公司名称 */
      company?: string;
      /** 产品设计背景 */
      pro_background?: string;
      /** 总体需求 */
      total_demand?: string;
      /** 业务功能树 */
      function_tree?: string;
      /** 业务角色描述 */
      role_describe?: string;
      /** 界面设计原则内容 */
      des_principle?: string;
      /** 界面设计要求内容 */
      ask_principle?: string;
      /** 性能要求内容 */
      performance?: string;
      /** 其他需求 */
      other_ask?: string;
      /** 附件 */
      annex?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddProcess
   * @summary 新增流程模板
   * @request POST:/dtsystem/addProcess
   * @secure
   */
  export namespace AddProcess {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 流程模板名称 */
      name?: string;
      /** 流程模板描述 */
      describe?: string;
      /** 流程模板内容 */
      content?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddProcessApi
   * @summary 添加接口流程模板表
   * @request POST:/dtsystem/addProcessApi
   * @secure
   */
  export namespace AddProcessApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 名称 */
      name?: string;
      /** 内容 */
      content?: string;
      /** 描述 */
      describe?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddOutput
   * @summary 新增接口输出参数
   * @request POST:/dtsystem/addOutput
   * @secure
   */
  export namespace AddOutput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 出参名称 */
      name?: string;
      /** 出参描述 */
      memo?: string;
      /** 出参版本 */
      version?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 组件模板 */
      template?: string;
      /** 参数排序 */
      ordernum?: string;
      /** 接口ID */
      api_id?: string;
      /** 列表宽度 */
      width?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 分组编码 */
      group_code?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddModule
   * @summary 新增系统功能模块
   * @request POST:/dtsystem/addModule
   * @secure
   */
  export namespace AddModule {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 模块名称 */
      name?: string;
      /** 模块描述 */
      memo?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddJobTemplate
   * @summary 添加系统作业模板
   * @request POST:/dtsystem/addJobTemplate
   * @secure
   */
  export namespace AddJobTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 模板名称 */
      name?: string;
      /** 所属模块 */
      module_id?: string;
      /** 接口id */
      api_id?: string;
      /** 内容 */
      content?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddInput
   * @summary 新增接口输入参数
   * @request POST:/dtsystem/addInput
   * @secure
   */
  export namespace AddInput {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 入参名称 */
      name?: string;
      /** 入参描述 */
      memo?: string;
      /** 入参版本 */
      version?: string;
      /** 默认取值 */
      defvalue?: string;
      /** 是否必填 */
      isnotnull?: string;
      /** 验证规则 */
      validate?: string;
      /** 是否只读 */
      readonly?: string;
      /** 是否隐藏 */
      ishidden?: string;
      /** 组件模板 */
      template?: string;
      /** 接口ID */
      api_id?: string;
      /** 参数类型(1数值2字符) */
      datatype?: string;
      /** 组件配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
      /** 参数排序 */
      ordernum?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddGroup
   * @summary 新增系统功能分组
   * @request POST:/dtsystem/addGroup
   * @secure
   */
  export namespace AddGroup {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 英文名称 */
      english_name?: string;
      /** 所属组名称 */
      group_name?: string;
      /** 所属模块 */
      module_id?: string;
      /** 图标 */
      icon?: string;
      /** 默认图标 */
      default_icon?: string;
      /** 分组描述 */
      group_memo?: string;
      /** 排序 */
      sort?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddFunct
   * @summary 新增系统功能页面
   * @request POST:/dtsystem/addFunct
   * @secure
   */
  export namespace AddFunct {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 功能名称 */
      name?: string;
      /** 功能配置 */
      configure?: string;
      /** 功能描述 */
      memo?: string;
      /** 所属模块 */
      module_id?: string;
      /** 组件模板 */
      template?: string;
      /** 功能流程 */
      workflow?: string;
      /** 功能权限 */
      grants?: string;
      /** 审核表 */
      auditing_table?: string;
      /** 完成进度 */
      finish_speed?: string;
      /** 客户端类型 */
      client_type?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddErrcode
   * @summary 新增错误编码配置
   * @request POST:/dtsystem/addErrcode
   * @secure
   */
  export namespace AddErrcode {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属模块 */
      module_id?: string;
      /** 错误编码 */
      errcode?: string;
      /** 错误级别 */
      errlevel?: string;
      /** 错误类型 */
      errtype?: string;
      /** 错误描述 */
      errdesc?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddEnviron
   * @summary 新增项目环境配置
   * @request POST:/dtsystem/addEnviron
   * @secure
   */
  export namespace AddEnviron {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 环境名称 */
      name?: string;
      /** 环境描述 */
      memo?: string;
      /** 所属项目 */
      project_id?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddDialogBox
   * @summary 添加弹框组件表
   * @request POST:/dtsystem/addDialogBox
   * @secure
   */
  export namespace AddDialogBox {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 组件名称 */
      name?: string;
      /** 上传文件 */
      upload_file?: string;
      /** 所属模块 */
      module_id?: string;
      /** 组件模板 */
      template?: string;
      /** 功能流程 */
      workflow?: string;
      /** 功能权限 */
      grants?: string;
      /** 功能配置 */
      configure?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddDefect
   * @summary 新增缺陷记录
   * @request POST:/dtsystem/addDefect
   * @secure
   */
  export namespace AddDefect {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属模块 */
      from_module?: string;
      /** 状态 */
      status?: string;
      /** 所属页面 */
      funct_id?: string;
      /** 优先级 */
      priority?: string;
      /** 严重程度 */
      severity?: string;
      /** 解决方案 */
      solution?: string;
      /** 指派给 */
      assign?: string;
      /** 附件 */
      annex?: string;
      /** 视频 */
      vedio_path?: string;
      /** 标题 */
      title?: string;
      /** 描述 */
      describe?: string;
      /** 重现步骤 */
      step_reproduction?: string;
      /** 创建者 */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dtsystem-1732505381452
   * @name AddApi
   * @summary 新增系统数据接口
   * @request POST:/dtsystem/addApi
   * @secure
   */
  export namespace AddApi {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 接口名称 */
      name?: string;
      /** 接口描述 */
      memo?: string;
      /** 所属模块 */
      module_id?: string;
      /** 执行语句 */
      sqltext?: string;
      /** 执行类型(1框架2模块) */
      ivktype?: string;
      /** 接口类型(1数据2混合) */
      apitype?: string;
      /** 本地方法 */
      ivkfunc?: string;
      /** 创建者 */
      create_user?: string;
      /** 流程语句 */
      scptext?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags login-controller
   * @name Logout
   * @summary 用户退出
   * @request GET:/dtsystem/logout
   * @secure
   */
  export namespace Logout {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
}

export namespace Dteqms {
  /**
   * No description
   * @tags export-controller
   * @name UserSignCount
   * @summary 安全员打卡记录导出
   * @request POST:/dteqms/user_sign_count
   * @secure
   */
  export namespace UserSignCount {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 开始时间 */
      time_beg?: string;
      /** 结束时间 */
      time_end?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags user-controller
   * @name StatUserSignStatByDeptType
   * @summary 根据单位类型统计用户签到
   * @request POST:/dteqms/statUserSignStatByDeptType
   * @secure
   */
  export namespace StatUserSignStatByDeptType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 开始日期 */
      start_date?: string;
      /** 结束日期 */
      end_date?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name SlientExamConf
   * @summary 考题配置
   * @request POST:/dteqms/slientExamConf
   * @secure
   */
  export namespace SlientExamConf {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 每日一学每题分数
       * @format int32
       */
      score1?: number;
      /**
       * 每日一学题目数量
       * @format int32
       */
      cnt1?: number;
      /**
       * 生活常识每题分数
       * @format int32
       */
      score2?: number;
      /**
       * 生活常识每题数量
       * @format int32
       */
      cnt2?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags project-controller
   * @name SaveOrUpdateProjectGrants
   * @summary 工程节点授权
   * @request POST:/dteqms/saveOrUpdateProjectGrants
   * @secure
   */
  export namespace SaveOrUpdateProjectGrants {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 工程ID */
      project_id?: string;
      /** 数据[{di:1,ri:1,ui:1,g:[]},{di:1,ri:2,ui:2,g:[]}] */
      data?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags project-controller
   * @name SaveOrUpdateNode
   * @summary 工程节点保存
   * @request POST:/dteqms/saveOrUpdateNode
   * @secure
   */
  export namespace SaveOrUpdateNode {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 父ID */
      pid: string;
      /** 工程ID */
      project_id?: string;
      /** 层级 */
      level: string;
      /** 名称 */
      name: string;
      /** 编码 */
      code: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListWarningCase
   * @summary 查询警示案列
   * @request POST:/dteqms/listWarningCase
   * @secure
   */
  export namespace ListWarningCase {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 可见范围 */
      visibility?: string;
      /** 审核状态 */
      check_state?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 工程名称 */
      project_id?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags user-controller
   * @name ListUserFuncts
   * @summary 用户有权限的功能列表
   * @request POST:/dteqms/listUserFuncts
   * @secure
   */
  export namespace ListUserFuncts {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListUserByProjectId
   * @summary 通过projectid取用户
   * @request POST:/dteqms/listUserByProjectId
   * @secure
   */
  export namespace ListUserByProjectId {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 项目id */
      project_id2?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListUserByDept
   * @summary 根据单位获取人员
   * @request POST:/dteqms/listUserByDept
   * @secure
   */
  export namespace ListUserByDept {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 单位ID */
      dept_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListTechnologyHelp
   * @summary 查询技术求助
   * @request POST:/dteqms/listTechnologyHelp
   * @secure
   */
  export namespace ListTechnologyHelp {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 可见范围 */
      visibility?: string;
      /** 是否愿意付费解决 */
      is_pay?: string;
      /** 解决状态 */
      status?: string;
      /** 审核状态 */
      check_state?: string;
      /** 工程名称 */
      project_id?: string;
      /** 是否匿名 */
      is_anony?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 求助内容 */
      content?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListTechnologyHelpRecord
   * @summary 查询技术求助记录
   * @request POST:/dteqms/listTechnologyHelpRecord
   * @secure
   */
  export namespace ListTechnologyHelpRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 技术求助记录ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 技术求助ID */
      help_id?: string;
      /** 评价内容 */
      content?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListSystemNotice
   * @summary 查询系统通知
   * @request POST:/dteqms/listSystemNotice
   * @secure
   */
  export namespace ListSystemNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 有效时间 */
      period_time?: string;
      /** id */
      id?: string;
      /** 创建用户id */
      create_user?: string;
      /** 更新用户id */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 内容 */
      content?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListSystemNoticePeriod
   * @summary 查询系统公告(有效时间内)
   * @request POST:/dteqms/listSystemNoticePeriod
   * @secure
   */
  export namespace ListSystemNoticePeriod {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags stat-controller
   * @name ListStatReformData
   * @summary 安全整改统计
   * @request POST:/dteqms/listStatReformData
   * @secure
   */
  export namespace ListStatReformData {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 开始时间 */
      time_beg?: string;
      /** 结束时间 */
      time_end?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
      /**
       * 单位类型
       * @format int32
       */
      type?: number;
      /**
       * 所属标段
       * @format int32
       */
      project_id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags stat-controller
   * @name ListStatHelpData
   * @summary 帮扶检查统计
   * @request POST:/dteqms/listStatHelpData
   * @secure
   */
  export namespace ListStatHelpData {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 检查开始日期 */
      check_date_beg?: string;
      /** 检查结束日期 */
      check_date_end?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
      /**
       * 单位类型
       * @format int32
       */
      type?: number;
      /**
       * 所属标段
       * @format int32
       */
      project_id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags count-controller
   * @name ListSignYearCnt
   * @summary 上班签到年统计
   * @request POST:/dteqms/listSignYearCnt
   * @secure
   */
  export namespace ListSignYearCnt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 年统计 */
      year?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags count-controller
   * @name ListSignStageCnt
   * @summary 上班签到阶段统计
   * @request POST:/dteqms/listSignStageCnt
   * @secure
   */
  export namespace ListSignStageCnt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 开始时间 */
      time_beg?: string;
      /** 结束时间 */
      time_end?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags count-controller
   * @name ListSignMonthCnt
   * @summary 上班签到月统计
   * @request POST:/dteqms/listSignMonthCnt
   * @secure
   */
  export namespace ListSignMonthCnt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 月统计 */
      month?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags count-controller
   * @name ListSecureSignYearCnt
   * @summary 安全员打卡年统计
   * @request POST:/dteqms/listSecureSignYearCnt
   * @secure
   */
  export namespace ListSecureSignYearCnt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 年统计 */
      year?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags count-controller
   * @name ListSecureSignStageCnt
   * @summary 安全员打卡阶段统计
   * @request POST:/dteqms/listSecureSignStageCnt
   * @secure
   */
  export namespace ListSecureSignStageCnt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 开始时间 */
      time_beg?: string;
      /** 结束时间 */
      time_end?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags count-controller
   * @name ListSecureSignMonthCnt
   * @summary 安全员打卡月统计
   * @request POST:/dteqms/listSecureSignMonthCnt
   * @secure
   */
  export namespace ListSecureSignMonthCnt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 月统计 */
      month?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name ListSafetyUserSign
   * @summary 查询安全员打卡
   * @request POST:/dteqms/listSafetyUserSign
   * @secure
   */
  export namespace ListSafetyUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 审核状态
       * @format int32
       */
      check_state?: number;
      /**
       * 单位类型
       * @format int32
       */
      dept_type?: number;
      /**
       * 所属单位
       * @format int32
       */
      dept_id?: number;
      /** 打卡人 */
      us_name?: string;
      /** 打卡开始日期 */
      create_time_beg?: string;
      /** 打卡结束日期 */
      create_time_end?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListSafetyTraining
   * @summary 查询安全培训
   * @request POST:/dteqms/listSafetyTraining
   * @secure
   */
  export namespace ListSafetyTraining {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属监理标段 */
      project_id5?: string;
      /** 所属标段 */
      project_id?: string;
      /** 培训名称 */
      train_name?: string;
      /** 进场类别 */
      entry_category?: string;
      /** 培训类型 */
      train_type?: string;
      /** 培训时间 */
      meeting_time?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 培训形式 */
      train_format?: string;
      /** 审核状态 */
      check_state?: string;
      /** 培训内容简介 */
      train_desc?: string;
      /** 现场照片 */
      scene_photos?: string;
      /** 签到表照片 */
      sign_photos?: string;
      /** 附件 */
      attachment?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags count-controller
   * @name ListSafetyTrainingYearCnt
   * @summary 安全培训年统计
   * @request POST:/dteqms/listSafetyTrainingYearCnt
   * @secure
   */
  export namespace ListSafetyTrainingYearCnt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 年统计 */
      year?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags count-controller
   * @name ListSafetyTrainingStageCnt
   * @summary 安全培训阶段统计
   * @request POST:/dteqms/listSafetyTrainingStageCnt
   * @secure
   */
  export namespace ListSafetyTrainingStageCnt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 会议开始时间 */
      time_beg?: string;
      /** 会议结束时间 */
      time_end?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags count-controller
   * @name ListSafetyTrainingMonthCnt
   * @summary 安全培训月统计
   * @request POST:/dteqms/listSafetyTrainingMonthCnt
   * @secure
   */
  export namespace ListSafetyTrainingMonthCnt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 月统计 */
      month?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name ListSafetyTrainDetail
   * @summary 查询安全培训明细
   * @request POST:/dteqms/listSafetyTrainDetail
   * @secure
   */
  export namespace ListSafetyTrainDetail {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListSafetyReform
   * @summary 查询安全整改
   * @request POST:/dteqms/listSafetyReform
   * @secure
   */
  export namespace ListSafetyReform {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标段名称 */
      project_id?: string;
      /** 单位名称 */
      dept_id?: string;
      /** 标题 */
      title?: string;
      /** 整改状态 */
      reform_status?: string;
      /** 审核状态 */
      check_state?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 整改人 */
      reform_uid?: string;
      /** 整改截止时间 */
      reform_end_time?: string;
      /** 整改完成时间 */
      reform_finish_time?: string;
      /** 问题描述 */
      question_desc?: string;
      /** 整改要求 */
      requirements?: string;
      /** 整改前照片 */
      pre_photos?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name ListSafetyReformAssign
   * @summary 问题整改指派列表
   * @request POST:/dteqms/listSafetyReformAssign
   * @secure
   */
  export namespace ListSafetyReformAssign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-11-08 16:52:53
   * @tags dteqms-1732697495363
   * @name ListReformCount
   * @summary 查询整改人消息数量
   * @request POST:/dteqms/listReformCount
   * @secure
   */
  export namespace ListReformCount {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags project-controller
   * @name ListProjectTree
   * @summary 工程树结构
   * @request POST:/dteqms/listProjectTree
   * @secure
   */
  export namespace ListProjectTree {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 工程ID */
      project_id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListProjectReport
   * @summary 查询项目通报
   * @request POST:/dteqms/listProjectReport
   * @secure
   */
  export namespace ListProjectReport {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 通报类型 */
      type?: string;
      /** 可见范围 */
      visibility?: string;
      /** 审核状态 */
      check_state?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 工程ID */
      project_id?: string;
      /** 通报描述 */
      content?: string;
      /** 附件地址 */
      attachment?: string;
      /** 发布状态 */
      publish_status?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags project-controller
   * @name ListProjectGrants
   * @summary 获取工程授权
   * @request POST:/dteqms/listProjectGrants
   * @secure
   */
  export namespace ListProjectGrants {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 工程ID */
      project_id: string;
      /** 单位ID */
      dept_id?: string;
      /** 角色ID */
      role_id?: string;
      /** 用户ID */
      uid?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags project-controller
   * @name ListProjectChild
   * @summary 用户有权限的下级节点
   * @request POST:/dteqms/listProjectChild
   * @secure
   */
  export namespace ListProjectChild {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 父节点 */
      pid: string;
      /**
       * 层级
       * @default "0"
       */
      level?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags project-controller
   * @name ListProjectChildExt
   * @summary 用户有权限的下级节点
   * @request POST:/dteqms/listProjectChildExt
   * @secure
   */
  export namespace ListProjectChildExt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 工程ID */
      project2: string;
      /** 项目ID */
      project3?: string;
      /**
       * 层级
       * @default "0"
       */
      level?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListProject6ByDept
   * @summary 根据标段获取单位
   * @request POST:/dteqms/listProject6ByDept
   * @secure
   */
  export namespace ListProject6ByDept {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标段id */
      project_id?: string;
      /** 单位类型 */
      dept_type?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListPersonalLedger
   * @summary 查询人员台账
   * @request POST:/dteqms/listPersonalLedger
   * @secure
   */
  export namespace ListPersonalLedger {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id?: string;
      /** 台账类型 */
      type?: string;
      /** 审核状态 */
      check_state?: string;
      /** 设备名称 */
      name?: string;
      /** 联系方式 */
      contact?: string;
      /** 进场时间 */
      in_time?: string;
      /** 离场时间 */
      out_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name ListPerformance
   * @summary 查询业绩列表
   * @request POST:/dteqms/listPerformance
   * @secure
   */
  export namespace ListPerformance {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * id
       * @format int32
       */
      id?: number;
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /**
       * 单位类型
       * @format int32
       */
      type?: number;
      /**
       * 单位id
       * @format int32
       */
      dept_id?: number;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListPenaltyNotice
   * @summary 查询处罚公示
   * @request POST:/dteqms/listPenaltyNotice
   * @secure
   */
  export namespace ListPenaltyNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 处罚单位 */
      dept_id?: string;
      /** 处罚类别 */
      penalty_type?: string;
      /** 可见范围 */
      visibility?: string;
      /** 案由 */
      cause_action?: string;
      /** 审核状态 */
      check_state?: string;
      /** 创建时间 */
      create_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 处罚决定内容 */
      penalty_desc?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 更新用户ID */
      update_user?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 处罚决定书编号 */
      penalty_number?: string;
      /** 更新时间 */
      update_time?: string;
      /** 执行情况 */
      execute_state?: string;
      /** 工程 */
      project_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListMeetingNotice
   * @summary 查询会议通知
   * @request POST:/dteqms/listMeetingNotice
   * @secure
   */
  export namespace ListMeetingNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 会议类型 */
      type?: string;
      /** 审核状态 */
      check_state?: string;
      /** 会议标题 */
      m_title?: string;
      /** 会议时间 */
      m_time?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 是否包含施工安全方面内容 */
      has_safety_content?: string;
      /** ID */
      id?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 工程ID */
      project_id?: string;
      /** 会议内容 */
      m_content?: string;
      /** 会议地点 */
      m_address?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListLaw
   * @summary 查询法律法规
   * @request POST:/dteqms/listLaw
   * @secure
   */
  export namespace ListLaw {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      name?: string;
      /** 编号 */
      code?: string;
      /** 公告栏目 */
      column_type?: string;
      /** 创建用户ID */
      create_user?: string;
      /** ID */
      id?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 工程ID */
      project_id?: string;
      /** 审核状态 */
      check_state?: string;
      /** 内容 */
      content?: string;
      /** 附件地址 */
      attachment?: string;
      /** 实施日期 */
      implement_date?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListLawRecord
   * @summary 查询法律法规建议
   * @request POST:/dteqms/listLawRecord
   * @secure
   */
  export namespace ListLawRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 法律法规ID */
      law_id?: string;
      /** 建议内容 */
      content?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListIndustryExplore
   * @summary 查询先行者探索
   * @request POST:/dteqms/listIndustryExplore
   * @secure
   */
  export namespace ListIndustryExplore {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 提出人 */
      proposer?: string;
      /** 审核状态 */
      check_state?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 工程ID */
      project_id?: string;
      /** 解决问题描述 */
      content?: string;
      /** 是否支持App操作 */
      is_support_app?: string;
      /** 学习成本 */
      learning_cost?: string;
      /** 轮播图片地址 */
      turning_picture?: string;
      /** 其他图片地址 */
      other_picture?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListHelpCheck
   * @summary 查询帮扶检查
   * @request POST:/dteqms/listHelpCheck
   * @secure
   */
  export namespace ListHelpCheck {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id?: string;
      /** 所属单位 */
      dept_id?: string;
      /** 整改状态 */
      reform_status?: string;
      /** 整改日期 */
      reform_finish_time?: string;
      /** 检查形式 */
      check_form?: string;
      /** 问题描述 */
      question_desc?: string;
      /** 检查日期 */
      check_date?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 标题 */
      title?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 整改人ID */
      reform_uid?: string;
      /** 整改截止时间 */
      reform_end_time?: string;
      /** 整改前照片 */
      pre_photos?: string;
      /** 整改要求 */
      requirements?: string;
      /** 审核状态 */
      check_state?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListHazardProject
   * @summary 查询危大工程
   * @request POST:/dteqms/listHazardProject
   * @secure
   */
  export namespace ListHazardProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id?: string;
      /** 所属单位 */
      dept_id?: string;
      /** 危大工程名称 */
      name?: string;
      /** 级别 */
      level?: string;
      /** 审核状态 */
      check_state?: string;
      /** 交底/验收状态 */
      status?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 施工批准记录 */
      constr_approval_record?: string;
      /** 航行通告(不停航施工） */
      navigation_bulletin?: string;
      /** 安全保证责任书 */
      safety_res_letter?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 位置1 */
      position_1?: string;
      /** 位置1 */
      position_2?: string;
      /** 位置1 */
      position_3?: string;
      /** 位置1 */
      position_4?: string;
      /** 专项方案审批记录 */
      approval_record?: string;
      /** 专项方案论证记录 */
      rationale_record?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListHazardProjectTj
   * @summary 危大-施工标段管理
   * @request POST:/dteqms/listHazardProjectTJ
   * @secure
   */
  export namespace ListHazardProjectTj {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标段名称 */
      project_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListHazardProjectDetailed
   * @summary 危大工程明细
   * @request POST:/dteqms/listHazardProjectDetailed
   * @secure
   */
  export namespace ListHazardProjectDetailed {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListHazardProjectCnt
   * @summary 危大工程统计
   * @request POST:/dteqms/listHazardProjectCnt
   * @secure
   */
  export namespace ListHazardProjectCnt {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id?: string;
      /** 开始时间 */
      time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name ListFindTypeByEntry
   * @summary 根据进场类别查询培训类型
   * @request POST:/dteqms/listFindTypeByEntry
   * @secure
   */
  export namespace ListFindTypeByEntry {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 培训类别
       * @format int32
       */
      entry_category: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags project-controller
   * @name ListFindDeptByType
   * @summary 根据单位类型获取单位列表
   * @request POST:/dteqms/listFindDeptByType
   * @secure
   */
  export namespace ListFindDeptByType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 区域ID */
      region_id?: string;
      /** 单位类型(1,2) */
      types: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name ListFindAttendees
   * @summary 会议的与会人列表
   * @request POST:/dteqms/listFindAttendees
   * @secure
   */
  export namespace ListFindAttendees {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 会议id
       * @format int32
       */
      mid: number;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListExamStore
   * @summary 查询题库
   * @request POST:/dteqms/listExamStore
   * @secure
   */
  export namespace ListExamStore {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 题目分类 */
      type?: string;
      /** 题目类型 */
      category?: string;
      /** 问题 */
      question?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 正确答案 */
      answer?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListExamStoreMe
   * @summary 查询学习笔记-错题
   * @request POST:/dteqms/listExamStoreMe
   * @secure
   */
  export namespace ListExamStoreMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 题目分类 */
      store_type?: string;
      /** 题目类型 */
      category?: string;
      /** 问题 */
      question?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 类型 */
      type?: string;
      /** 题目ID */
      es_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListExamRecord
   * @summary 查询考试记录
   * @request POST:/dteqms/listExamRecord
   * @secure
   */
  export namespace ListExamRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 单位类型 */
      dept_type?: string;
      /** 所属单位 */
      dept_id?: string;
      /** 考试人 */
      us_name?: string;
      /** 考试日期 */
      create_time?: string;
      /** 考试状态 */
      exam_type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 更新时间 */
      update_time?: string;
      /** 考试分数 */
      score?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListExamConf
   * @summary 查询题库配置
   * @request POST:/dteqms/listExamConf
   * @secure
   */
  export namespace ListExamConf {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 每题分数 */
      score?: string;
      /** 题目数量 */
      cnt?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListDeptByUser
   * @summary 根据单位类型获取人员
   * @request POST:/dteqms/listDeptByUser
   * @secure
   */
  export namespace ListDeptByUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 单位类型 */
      dept_type?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListCraftsmanship
   * @summary 行业匠心
   * @request POST:/dteqms/listCraftsmanship
   * @secure
   */
  export namespace ListCraftsmanship {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 提出人 */
      proposer?: string;
      /** 审核状态 */
      check_state?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 工程ID */
      project_id?: string;
      /** 解决问题描述 */
      content?: string;
      /** 是否支持App操作 */
      is_support_app?: string;
      /** 学习成本 */
      learning_cost?: string;
      /** 轮播图片地址 */
      turning_picture?: string;
      /** 其他图片地址 */
      other_picture?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListConveningOfMeeting
   * @summary 查询会议召集
   * @request POST:/dteqms/listConveningOfMeeting
   * @secure
   */
  export namespace ListConveningOfMeeting {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 会议标题 */
      m_title?: string;
      /** 会议时间 */
      m_time?: string;
      /** 审核状态 */
      check_state?: string;
      /** 工程名称 */
      project_id?: string;
      /** 会议类型 */
      type?: string;
      /** 会议内容 */
      m_content?: string;
      /** 会议地点 */
      m_address?: string;
      /** 是否包含施工安全方面内容 */
      has_safety_content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListCollectStoreMe
   * @summary 查询学习笔记-收藏
   * @request POST:/dteqms/listCollectStoreMe
   * @secure
   */
  export namespace ListCollectStoreMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 题目分类 */
      store_type?: string;
      /** 题目类型 */
      category?: string;
      /** 问题 */
      question?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 类型 */
      type?: string;
      /** 题目ID */
      es_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags export-controller
   * @name ListClockInCountExp
   * @summary 普通签到记录导出
   * @request POST:/dteqms/listClockInCountExp
   * @secure
   */
  export namespace ListClockInCountExp {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 开始时间 */
      time_beg?: string;
      /** 结束时间 */
      time_end?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags check-controller
   * @name ListCheckAuditingToMe
   * @summary 用户有权限需要审核的列表
   * @request POST:/dteqms/listCheckAuditingToMe
   * @secure
   */
  export namespace ListCheckAuditingToMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表名 */
      table_name?: string;
      /** 发起人用户ID */
      from_user_id?: string;
      /** 发起开始时间 */
      start_time_beg?: string;
      /** 发起结束时间 */
      start_time_end?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags check-controller
   * @name ListCheckAuditingToMeFunc
   * @summary 用户有权限审核的功能列表
   * @request POST:/dteqms/listCheckAuditingToMeFunc
   * @secure
   */
  export namespace ListCheckAuditingToMeFunc {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags check-controller
   * @name ListCheckAuditingHisMe
   * @summary 我的审核历史记录
   * @request POST:/dteqms/listCheckAuditingHisMe
   * @secure
   */
  export namespace ListCheckAuditingHisMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表名 */
      table_name: string;
      /** 发起人用户ID */
      from_user_id?: string;
      /** 发起开始时间 */
      start_time?: string;
      /** 发起结束时间 */
      end_time?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags check-controller
   * @name ListCheckAuditingFromMe
   * @summary 用户发起需要审核的列表
   * @request POST:/dteqms/listCheckAuditingFromMe
   * @secure
   */
  export namespace ListCheckAuditingFromMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表名 */
      table_name: string;
      /**
       * 审核状态(1:暂存;2:待审核;3:审核通过;4:驳回)
       * @format int32
       */
      check_state?: number;
      /** 发起开始时间 */
      start_time_beg?: string;
      /** 发起结束时间 */
      start_time_end?: string;
      /** 审核人用户ID */
      check_user_id?: string;
      /** 审核开始时间 */
      check_start_time?: string;
      /** 审核结束时间 */
      check_end_time?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags check-controller
   * @name ListCheckAuditingFromMeFunc
   * @summary 用户发起审核的功能列表
   * @request POST:/dteqms/listCheckAuditingFromMeFunc
   * @secure
   */
  export namespace ListCheckAuditingFromMeFunc {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseUser
   * @summary 查询用户
   * @request POST:/dteqms/listBaseUser
   * @secure
   */
  export namespace ListBaseUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 单位类型 */
      type?: string;
      /** 单位名称 */
      dept_id?: string;
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 密码 */
      pwd?: string;
      /** 传真电话 */
      tel?: string;
      /** E-Mail */
      email?: string;
      /** 地址 */
      address?: string;
      /** 性别 */
      gender?: string;
      /** 备注 */
      remark?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name ListBaseUserSign
   * @summary 查询上班签到
   * @request POST:/dteqms/listBaseUserSign
   * @secure
   */
  export namespace ListBaseUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 所属标段
       * @format int32
       */
      project_id?: number;
      /**
       * 单位类型
       * @format int32
       */
      dept_type?: number;
      /**
       * 所属单位
       * @format int32
       */
      dept_id?: number;
      /** 签到人 */
      us_name?: string;
      /** 打卡开始日期 */
      create_time_beg?: string;
      /** 打卡结束日期 */
      create_time_end?: string;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseUserRole
   * @summary 查询用户角色关系
   * @request POST:/dteqms/listBaseUserRole
   * @secure
   */
  export namespace ListBaseUserRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 单位名称 */
      dept_id?: string;
      /** 姓名 */
      us_name: string;
      /** 手机号 */
      us_phone: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 姓名 */
      uid?: string;
      /** 角色ID */
      role_id?: string;
      /** 工程ID */
      project_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseSectionWork
   * @summary 查询工作区
   * @request POST:/dteqms/listBaseSectionWork
   * @secure
   */
  export namespace ListBaseSectionWork {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属项目 */
      work_item_id?: string;
      /** 标段 */
      project_id?: string;
      /** 标段工法 */
      section_method_id?: string;
      /** 完成状态 */
      finish_status?: string;
      /** 名称 */
      name?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 所属工程 */
      work_project_id?: string;
      /** 工作区范围 */
      map_range?: string;
      /** 区分原有还是弹框 */
      flag?: string;
      /** 第几页 */
      pageIndex?: string;
      /** 一次多少页 */
      pageSize?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseSectionMethod
   * @summary 查询标段工法
   * @request POST:/dteqms/listBaseSectionMethod
   * @secure
   */
  export namespace ListBaseSectionMethod {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标段 */
      project_id?: string;
      /** 工法类型 */
      method_type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 更新时间 */
      update_time?: string;
      /** 创建时间 */
      create_time?: string;
      /** 施工组织设计 */
      design?: string;
      /** 详细施工方案 */
      scheme?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseSectionMethodByProjectId
   * @summary 根据标段ID查询标段工法
   * @request POST:/dteqms/listBaseSectionMethodByProjectId
   * @secure
   */
  export namespace ListBaseSectionMethodByProjectId {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标段ID */
      project_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseRole
   * @summary 查询角色
   * @request POST:/dteqms/listBaseRole
   * @secure
   */
  export namespace ListBaseRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 单位类型 */
      type?: string;
      /** 单位名称 */
      dept_id?: string;
      /** 角色名称 */
      name?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseRoleDeptType
   * @summary 查询角色与单位类型关系表
   * @request POST:/dteqms/listBaseRoleDeptType
   * @secure
   */
  export namespace ListBaseRoleDeptType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 角色模板名称 */
      name?: string;
      /** 单位类型 */
      dept_type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 更新时间 */
      update_time?: string;
      /** 授权 */
      grants?: string;
      /** 创建时间 */
      create_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseRegion
   * @summary 查询区域
   * @request POST:/dteqms/listBaseRegion
   * @secure
   */
  export namespace ListBaseRegion {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 地区名称 */
      name?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 更新时间 */
      update_time?: string;
      /** 创建时间 */
      create_time?: string;
      /** 排序 */
      sort?: string;
      /** 父ID */
      pid?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseProject
   * @summary 工程
   * @request POST:/dteqms/listBaseProject
   * @secure
   */
  export namespace ListBaseProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级(1:地区;2:工程;3:项目;4:分部:5:监理标段;6:标段;7:分项) */
      level?: string;
      /** 地图范围(标段) */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态(1:未完工;2:已完工;3:监理已验收;4:部分验收;5:全部验收;6:已竣工) */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** 专职安全管理人员数量 */
      safety_number?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseProjectByPid
   * @summary 根据pid查询子列表
   * @request POST:/dteqms/listBaseProjectByPid
   * @secure
   */
  export namespace ListBaseProjectByPid {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 父id */
      project_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseProject7
   * @summary 查询分项
   * @request POST:/dteqms/listBaseProject7
   * @secure
   */
  export namespace ListBaseProject7 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属监理标段 */
      project5?: string;
      /** 所属标段 */
      pid?: string;
      /** 分项名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 分部 */
      project4?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseProject6
   * @summary 查询标段
   * @request POST:/dteqms/listBaseProject6
   * @secure
   */
  export namespace ListBaseProject6 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 监理标段 */
      pid?: string;
      /** 标段名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 分部 */
      project4?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseProject5
   * @summary 查询监理标段
   * @request POST:/dteqms/listBaseProject5
   * @secure
   */
  export namespace ListBaseProject5 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 监理标段名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 分部 */
      pid?: string;
      /** 所属机场 */
      airport?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseProject4
   * @summary 查询分部
   * @request POST:/dteqms/listBaseProject4
   * @secure
   */
  export namespace ListBaseProject4 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 分部名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 项目 */
      pid?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseProject3
   * @summary 查询项目
   * @request POST:/dteqms/listBaseProject3
   * @secure
   */
  export namespace ListBaseProject3 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 项目名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 所属机场 */
      airport?: string;
      /** 项目状态 */
      finish_status?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 工程 */
      pid?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 备注 */
      remark?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseProject2
   * @summary 查询工程
   * @request POST:/dteqms/listBaseProject2
   * @secure
   */
  export namespace ListBaseProject2 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属地区 */
      pid?: string;
      /** 工程名称 */
      name?: string;
      /** 所属机场 */
      airport?: string;
      /** 工程状态 */
      finish_status?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 备注 */
      remark?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseNotice
   * @summary 查询系统通知
   * @request POST:/dteqms/listBaseNotice
   * @secure
   */
  export namespace ListBaseNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 会议时间 */
      m_time?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 处理状态 */
      deal_state?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户ID */
      uid?: string;
      /** 会议ID */
      mid?: string;
      /** 创建时间 */
      create_time?: string;
      /** 会议类型 */
      type?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseMethodType
   * @summary 查询工法类型
   * @request POST:/dteqms/listBaseMethodType
   * @secure
   */
  export namespace ListBaseMethodType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 工法名称 */
      name?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseFunctUserCommon
   * @summary 查询用户常用功能配置
   * @request POST:/dteqms/listBaseFunctUserCommon
   * @secure
   */
  export namespace ListBaseFunctUserCommon {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 功能 */
      funct_id?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户ID */
      user_id?: string;
      /** 加入时间 */
      create_time?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListBaseDept
   * @summary 查询单位
   * @request POST:/dteqms/listBaseDept
   * @secure
   */
  export namespace ListBaseDept {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属地区 */
      region_id?: string;
      /** 单位类型 */
      type?: string;
      /** 单位名称 */
      name?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 父ID */
      pid?: string;
      /** 描述 */
      remark?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListAdvice
   * @summary 查询意见征求
   * @request POST:/dteqms/listAdvice
   * @secure
   */
  export namespace ListAdvice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 截止时间 */
      end_time?: string;
      /** 可见范围 */
      visibility?: string;
      /** 审核状态 */
      check_state?: string;
      /** ID11 */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 上传时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 工程名称 */
      project_id?: string;
      /** 内容 */
      content?: string;
      /** 附件地址 */
      attachment?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListAdviceRecord
   * @summary 查询意见征求评价
   * @request POST:/dteqms/listAdviceRecord
   * @secure
   */
  export namespace ListAdviceRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 意见征求ID */
      advice_id?: string;
      /** 评价内容 */
      content?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name ListAddressBook
   * @summary 查询通讯录
   * @request POST:/dteqms/listAddressBook
   * @secure
   */
  export namespace ListAddressBook {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * id
       * @format int32
       */
      id?: number;
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /**
       * 单位类型
       * @format int32
       */
      type?: number;
      /**
       * 单位id
       * @format int32
       */
      dept_id?: number;
      /**
       * 页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 每页数量
       * @format int32
       * @default 15
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListAddressBookApp
   * @summary app通讯录
   * @request POST:/dteqms/listAddressBookApp
   * @secure
   */
  export namespace ListAddressBookApp {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 姓名 */
      us_name?: string;
      /** 所属单位 */
      dept_id?: string;
      /** 职务 */
      role_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name ListAddressBook2
   * @summary 通讯录查询
   * @request POST:/dteqms/listAddressBook2
   * @secure
   */
  export namespace ListAddressBook2 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /** 单位类型 */
      type?: string;
      /** 单位名称 */
      dept_id?: string;
      /**
       * 当前页码
       * @format int32
       * @default 1
       */
      pageIndex?: number;
      /**
       * 页面大小
       * @format int32
       * @default 10
       */
      pageSize?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoWarningCase
   * @summary 警示案列详情
   * @request POST:/dteqms/infoWarningCase
   * @secure
   */
  export namespace InfoWarningCase {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 标题 */
      title?: string;
      /** 内容 */
      content?: string;
      /** 附件地址 */
      attachment?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags user-controller
   * @name InfoUserSign
   * @summary 用户当天签到信息
   * @request POST:/dteqms/infoUserSign
   * @secure
   */
  export namespace InfoUserSign {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoTechnologyHelp
   * @summary 技术求助详情
   * @request POST:/dteqms/infoTechnologyHelp
   * @secure
   */
  export namespace InfoTechnologyHelp {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 标题 */
      title?: string;
      /** 求助内容 */
      content?: string;
      /** 是否愿意付费解决 */
      is_pay?: string;
      /** 是否匿名 */
      is_anony?: string;
      /** 状态 */
      status?: string;
      /** 附件 */
      attachment?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoTechnologyHelpRecord
   * @summary 技术求助记录详情
   * @request POST:/dteqms/infoTechnologyHelpRecord
   * @secure
   */
  export namespace InfoTechnologyHelpRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 技术求助记录ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 技术求助ID */
      help_id?: string;
      /** 评价内容 */
      content?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoSystemNotice
   * @summary 系统通知详情
   * @request POST:/dteqms/infoSystemNotice
   * @secure
   */
  export namespace InfoSystemNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 创建用户id */
      create_user?: string;
      /** 更新用户id */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标题 */
      title?: string;
      /** 内容 */
      content?: string;
      /** 有效时间 */
      period_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoSafetyUserSign
   * @summary 安全员打卡详情
   * @request POST:/dteqms/infoSafetyUserSign
   * @secure
   */
  export namespace InfoSafetyUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 签到类型 */
      type?: string;
      /** 签到图片 */
      img?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标段id */
      project_id?: string;
      /** 审核用户id */
      check_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoSafetyTraining
   * @summary 安全培训详情
   * @request POST:/dteqms/infoSafetyTraining
   * @secure
   */
  export namespace InfoSafetyTraining {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核建议 */
      check_advise?: string;
      /** 培训名称 */
      train_name?: string;
      /** 进场类别 */
      entry_category?: string;
      /** 培训类型 */
      train_type?: string;
      /** 培训形式 */
      train_format?: string;
      /** 会议时间 */
      meeting_time?: string;
      /** 培训内容简介 */
      train_desc?: string;
      /** 现场照片 */
      scene_photos?: string;
      /** 签到表照片 */
      sign_photos?: string;
      /** 附件 */
      attachment?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 标段id */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoSafetyReform
   * @summary 安全整改详情
   * @request POST:/dteqms/infoSafetyReform
   * @secure
   */
  export namespace InfoSafetyReform {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 标题 */
      title?: string;
      /** 整改状态 */
      reform_status?: string;
      /** 整改截止时间 */
      reform_end_time?: string;
      /** 整改完成时间 */
      reform_finish_time?: string;
      /** 问题描述 */
      question_desc?: string;
      /** 整改要求 */
      requirements?: string;
      /** 整改前照片 */
      pre_photos?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 标段ID */
      project_id?: string;
      /** 单位ID */
      dept_id?: string;
      /** 整改人ID */
      reform_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags check-controller
   * @name InfoRejectCnt
   * @summary 用户发起被驳回的数据总数
   * @request POST:/dteqms/infoRejectCnt
   * @secure
   */
  export namespace InfoRejectCnt {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoProjectReport
   * @summary 项目通报详情
   * @request POST:/dteqms/infoProjectReport
   * @secure
   */
  export namespace InfoProjectReport {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 通报类型 */
      type?: string;
      /** 通报描述 */
      content?: string;
      /** 发布状态 */
      publish_status?: string;
      /** 审核状态 */
      check_state?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
      /** 审核建议 */
      check_advise?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoPersonalLedger
   * @summary 人员台账详情
   * @request POST:/dteqms/infoPersonalLedger
   * @secure
   */
  export namespace InfoPersonalLedger {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 设备名称 */
      name?: string;
      /** 操作员 */
      operator?: string;
      /** 联系方式 */
      contact?: string;
      /** 事由 */
      reason?: string;
      /** 进场时间 */
      in_time?: string;
      /** 离场时间 */
      out_time?: string;
      /** 附件地址 */
      attachment?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoPenaltyNotice
   * @summary 处罚公示详情
   * @request POST:/dteqms/infoPenaltyNotice
   * @secure
   */
  export namespace InfoPenaltyNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 工程ID */
      project_id?: string;
      /** 案由 */
      cause_action?: string;
      /** 处罚决定书编号 */
      penalty_number?: string;
      /** 处罚决定内容 */
      penalty_desc?: string;
      /** 执行情况 */
      execute_state?: string;
      /** 处罚类别 */
      penalty_type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 处罚单位ID */
      dept_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoMeetingNotice
   * @summary 会议通知详情
   * @request POST:/dteqms/infoMeetingNotice
   * @secure
   */
  export namespace InfoMeetingNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 会议标题 */
      m_title?: string;
      /** 会议内容 */
      m_content?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 会议时间 */
      m_time?: string;
      /** 会议地点 */
      m_address?: string;
      /** 现场照片地址 */
      m_scence_picture?: string;
      /** 附件 */
      attachment?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
      /** 是否包含施工安全方面内容 */
      has_safety_content?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoLaw
   * @summary 法律法规详情
   * @request POST:/dteqms/infoLaw
   * @secure
   */
  export namespace InfoLaw {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 栏目类型 */
      column_type?: string;
      /** 编号 */
      code?: string;
      /** 名称 */
      name?: string;
      /** 内容 */
      content?: string;
      /** 附件地址 */
      attachment?: string;
      /** 实施日期 */
      implement_date?: string;
      /** 颁布日期 */
      promulgate_date?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoLawRecord
   * @summary 法律法规建议详情
   * @request POST:/dteqms/infoLawRecord
   * @secure
   */
  export namespace InfoLawRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 建议内容 */
      content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 法律法规ID */
      law_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoIndustryExplore
   * @summary 先行者探索详情
   * @request POST:/dteqms/infoIndustryExplore
   * @secure
   */
  export namespace InfoIndustryExplore {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 标题 */
      title?: string;
      /** 解决问题描述 */
      content?: string;
      /** 是否支持App操作 */
      is_support_app?: string;
      /** 学习成本 */
      learning_cost?: string;
      /** 轮播图片地址 */
      turning_picture?: string;
      /** 其他图片地址 */
      other_picture?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoHelpCheck
   * @summary 帮扶检查详情
   * @request POST:/dteqms/infoHelpCheck
   * @secure
   */
  export namespace InfoHelpCheck {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 整改截止时间 */
      reform_end_time?: string;
      /** 整改完成时间 */
      reform_finish_time?: string;
      /** 问题描述 */
      question_desc?: string;
      /** 整改要求 */
      requirements?: string;
      /** 整改前照片 */
      pre_photos?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 标段ID */
      project_id?: string;
      /** 单位ID */
      dept_id?: string;
      /** 整改人ID */
      reform_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoHazardProject
   * @summary 危大工程详情
   * @request POST:/dteqms/infoHazardProject
   * @secure
   */
  export namespace InfoHazardProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 单位名称 */
      dept_id?: string;
      /** 标段名称 */
      project_id?: string;
      /** 名称 */
      name?: string;
      /** 级别 */
      level?: string;
      /** 交底/验收状态 */
      status?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 位置1 */
      position_1?: string;
      /** 位置2 */
      position_2?: string;
      /** 位置3 */
      position_3?: string;
      /** 位置4 */
      position_4?: string;
      /** 验收记录 */
      acc_record?: string;
      /** 专项方案审批记录 */
      approval_record?: string;
      /** 专项方案论证记录 */
      rationale_record?: string;
      /** 不停航施工组织方案批准记录 */
      constr_approval_record?: string;
      /** 航行通告(不停航施工） */
      navigation_bulletin?: string;
      /** 安全保证责任书 */
      safety_res_letter?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoExamStore
   * @summary 题库详情
   * @request POST:/dteqms/infoExamStore
   * @secure
   */
  export namespace InfoExamStore {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 题目分类 */
      type?: string;
      /** 题目类型 */
      category?: string;
      /** 问题 */
      question?: string;
      /** 正确答案 */
      answer?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoExamStoreMe
   * @summary 学习笔记-错题详情
   * @request POST:/dteqms/infoExamStoreMe
   * @secure
   */
  export namespace InfoExamStoreMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 类型 */
      type?: string;
      /** 题目ID */
      es_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoExamRecord
   * @summary 考试记录详情
   * @request POST:/dteqms/infoExamRecord
   * @secure
   */
  export namespace InfoExamRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 考试类型 */
      exam_type?: string;
      /** 考试分数 */
      score?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoExamConf
   * @summary 题库配置详情
   * @request POST:/dteqms/infoExamConf
   * @secure
   */
  export namespace InfoExamConf {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 每题分数 */
      score?: string;
      /** 题目数量 */
      cnt?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoCraftsmanship
   * @summary 行业匠心
   * @request POST:/dteqms/infoCraftsmanship
   * @secure
   */
  export namespace InfoCraftsmanship {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 标题 */
      title?: string;
      /** 解决问题描述 */
      content?: string;
      /** 学习成本 */
      learning_cost?: string;
      /** 轮播图片 */
      turning_picture?: string;
      /** 其他图片地址 */
      other_picture?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
      /** 是否支持App操作 */
      is_support_app?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoConveningOfMeeting
   * @summary 会议召集详情
   * @request POST:/dteqms/infoConveningOfMeeting
   * @secure
   */
  export namespace InfoConveningOfMeeting {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 会议标题 */
      m_title?: string;
      /** 会议内容 */
      m_content?: string;
      /** 会议时间 */
      m_time?: string;
      /** 会议地点 */
      m_address?: string;
      /** 现场照片地址 */
      m_scence_picture?: string;
      /** 附件 */
      attachment?: string;
      /** 是否包含施工安全方面内容 */
      has_safety_content?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 单位类型 */
      dept_type?: string;
      /** 工程ID */
      project_id?: string;
      /** 类型 */
      type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoCollectStoreMe
   * @summary 学习笔记-收藏详情
   * @request POST:/dteqms/infoCollectStoreMe
   * @secure
   */
  export namespace InfoCollectStoreMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 类型 */
      type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 题目ID */
      es_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags check-controller
   * @name InfoCheckAuditingDetail
   * @summary 功能明细
   * @request POST:/dteqms/infoCheckAuditingDetail
   * @secure
   */
  export namespace InfoCheckAuditingDetail {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表名 */
      table_name: string;
      /** ID */
      id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseUser
   * @summary 用户详情
   * @request POST:/dteqms/infoBaseUser
   * @secure
   */
  export namespace InfoBaseUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 单位ID */
      dept_id?: string;
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /** 密码 */
      pwd?: string;
      /** 传真电话 */
      tel?: string;
      /** E-Mail */
      email?: string;
      /** 地址 */
      address?: string;
      /** 性别 */
      gender?: string;
      /** 备注 */
      remark?: string;
      /** 昵称 */
      nick_name?: string;
      /** 头像 */
      avatar?: string;
      /** 生日 */
      birthday?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseUserSign
   * @summary 上班签到详情
   * @request POST:/dteqms/infoBaseUserSign
   * @secure
   */
  export namespace InfoBaseUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 签到类型 */
      type?: string;
      /** 签到图片 */
      img?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标段id */
      project_id?: string;
      /** 审核用户id */
      check_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseUserRole
   * @summary 用户角色关系详情
   * @request POST:/dteqms/infoBaseUserRole
   * @secure
   */
  export namespace InfoBaseUserRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户ID */
      uid?: string;
      /** 角色ID */
      role_id?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseSectionWork
   * @summary 工作区详情
   * @request POST:/dteqms/infoBaseSectionWork
   * @secure
   */
  export namespace InfoBaseSectionWork {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 名称 */
      name?: string;
      /** 工作区范围 */
      map_range?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标段工法ID */
      section_method_id?: string;
      /** 标段ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseSectionMethod
   * @summary 标段工法详情
   * @request POST:/dteqms/infoBaseSectionMethod
   * @secure
   */
  export namespace InfoBaseSectionMethod {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 工法类型 */
      method_type?: string;
      /** 标段ID */
      project_id?: string;
      /** 施工组织设计 */
      design?: string;
      /** 详细施工方案 */
      scheme?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseRole
   * @summary 角色详情
   * @request POST:/dteqms/infoBaseRole
   * @secure
   */
  export namespace InfoBaseRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 描述 */
      memo?: string;
      /** 角色授权 */
      grants?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 单位ID */
      dept_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseRoleDeptType
   * @summary 角色与单位类型关系表详情
   * @request POST:/dteqms/infoBaseRoleDeptType
   * @secure
   */
  export namespace InfoBaseRoleDeptType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 单位类型(121) */
      dept_type?: string;
      /** 授权 */
      grants?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseRegion
   * @summary 区域详情
   * @request POST:/dteqms/infoBaseRegion
   * @secure
   */
  export namespace InfoBaseRegion {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 名称 */
      name?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 父ID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseProject7
   * @summary 分项详情
   * @request POST:/dteqms/infoBaseProject7
   * @secure
   */
  export namespace InfoBaseProject7 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseProject6
   * @summary 标段详情
   * @request POST:/dteqms/infoBaseProject6
   * @secure
   */
  export namespace InfoBaseProject6 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseProject5
   * @summary 监理标段详情
   * @request POST:/dteqms/infoBaseProject5
   * @secure
   */
  export namespace InfoBaseProject5 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 监理标段名称 */
      name?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseProject4
   * @summary 分部详情
   * @request POST:/dteqms/infoBaseProject4
   * @secure
   */
  export namespace InfoBaseProject4 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseProject3
   * @summary 项目详情
   * @request POST:/dteqms/infoBaseProject3
   * @secure
   */
  export namespace InfoBaseProject3 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseProject2
   * @summary 工程详情
   * @request POST:/dteqms/infoBaseProject2
   * @secure
   */
  export namespace InfoBaseProject2 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属区域 */
      pid?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 备注 */
      remark?: string;
      /** 工程状态 */
      finish_status?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 层级 */
      level?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseNotice
   * @summary 系统通知详情
   * @request POST:/dteqms/infoBaseNotice
   * @secure
   */
  export namespace InfoBaseNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 处理状态 */
      deal_state?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户ID */
      uid?: string;
      /** 会议ID */
      mid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseMethodType
   * @summary 工法类型详情
   * @request POST:/dteqms/infoBaseMethodType
   * @secure
   */
  export namespace InfoBaseMethodType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseFunctUserCommon
   * @summary 用户常用功能配置详情
   * @request POST:/dteqms/infoBaseFunctUserCommon
   * @secure
   */
  export namespace InfoBaseFunctUserCommon {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户ID */
      user_id?: string;
      /** 功能ID */
      funct_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoBaseDept
   * @summary 单位详情
   * @request POST:/dteqms/infoBaseDept
   * @secure
   */
  export namespace InfoBaseDept {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 单位名称 */
      name?: string;
      /** 所属管理局 */
      admin_room?: string;
      /** 所属地区质检站 */
      quality_station?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** 所属地区ID */
      region_id?: string;
      /** 父ID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoAdvice
   * @summary 意见征求详情
   * @request POST:/dteqms/infoAdvice
   * @secure
   */
  export namespace InfoAdvice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 标题 */
      title?: string;
      /** 内容 */
      content?: string;
      /** 附件 */
      attachment?: string;
      /** 截止时间 */
      end_time?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoAdviceRecord
   * @summary 意见征求评价详情
   * @request POST:/dteqms/infoAdviceRecord
   * @secure
   */
  export namespace InfoAdviceRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 评价内容 */
      content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 意见征求ID */
      advice_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name InfoAddressBook
   * @summary 通讯录详情
   * @request POST:/dteqms/infoAddressBook
   * @secure
   */
  export namespace InfoAddressBook {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 通讯录ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 所属工程 */
      project_id?: string;
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /** 单位ID */
      dept_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags export-controller
   * @name ImportTrainTemplate
   * @summary 上传自定义安全培训模版
   * @request POST:/dteqms/import_train_template
   * @secure
   */
  export namespace ImportTrainTemplate {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 进场类别
       * @format int32
       */
      entry_category: number;
      /**
       * 培训类型
       * @format int32
       */
      train_type: number;
      /** 模版url */
      train_template_url: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags export-controller
   * @name ImportAddressBook
   * @summary 导入通讯录
   * @request POST:/dteqms/import_addressBook
   * @secure
   */
  export namespace ImportAddressBook {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 通讯录信息json */
      addressBookset?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags export-controller
   * @name ImportQuestions
   * @summary 导入题
   * @request POST:/dteqms/importQuestions
   * @secure
   */
  export namespace ImportQuestions {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 题json */
      questionSet?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags export-controller
   * @name HazardProjectCount
   * @summary 危大工程统计导出
   * @request POST:/dteqms/hazard_project_count
   * @secure
   */
  export namespace HazardProjectCount {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 开始时间 */
      time_beg?: string;
      /** 结束时间 */
      time_end?: string;
      /**
       * 标段
       * @format int32
       */
      project_id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags export-controller
   * @name ExpTrainCount
   * @summary 安全培训统计导出
   * @request POST:/dteqms/exp_train_count
   * @secure
   */
  export namespace ExpTrainCount {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * id
       * @format int32
       */
      id: number;
      /** 开始时间 */
      time_beg?: string;
      /** 结束时间 */
      time_end?: string;
      /**
       * 所属监理标段
       * @format int32
       */
      project_id5?: number;
      /**
       * 所属标段
       * @format int32
       */
      project_id?: number;
      /** 培训名称 */
      train_name?: string;
      /**
       * 进场类别
       * @format int32
       */
      entry_category: number;
      /**
       * 培训类型
       * @format int32
       */
      train_type: number;
      /**
       * 审核状态
       * @format int32
       */
      check_state?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags export-controller
   * @name ExpSafetyReform
   * @summary 问题整改统计导出
   * @request POST:/dteqms/exp_safety_reform
   * @secure
   */
  export namespace ExpSafetyReform {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 开始时间 */
      time_beg?: string;
      /** 结束时间 */
      time_end?: string;
      /**
       * 所属标段
       * @format int32
       */
      project_id?: number;
      /**
       * 所属单位
       * @format int32
       */
      dept_id?: number;
      /** 标题 */
      title?: string;
      /**
       * 整改状态
       * @format int32
       */
      reform_status?: number;
      /**
       * 审核状态
       * @format int32
       */
      check_state?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags export-controller
   * @name ExpHelpData
   * @summary 帮扶统计导出
   * @request POST:/dteqms/exp_help_data
   * @secure
   */
  export namespace ExpHelpData {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 开始时间 */
      check_date_beg?: string;
      /** 结束时间 */
      check_date_end?: string;
      /**
       * 所属标段
       * @format int32
       */
      project_id?: number;
      /**
       * 单位类型
       * @format int32
       */
      type?: number;
      /**
       * 所属单位
       * @format int32
       */
      dept_id?: number;
      /**
       * 整改状态
       * @format int32
       */
      reform_status?: number;
      /**
       * 检查形式
       * @format int32
       */
      check_form?: number;
      /** 问题描述 */
      question_desc?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags export-controller
   * @name ExpMeetingMinutes
   * @summary 导出会议纪要
   * @request POST:/dteqms/expMeetingMinutes
   * @secure
   */
  export namespace ExpMeetingMinutes {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 会议id
       * @format int32
       */
      id: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name ExamResult
   * @summary 考试结果
   * @request POST:/dteqms/exam_result
   * @secure
   */
  export namespace ExamResult {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 考题集合 */
      questionSet: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name ExamQuestionQuery
   * @summary 考试题
   * @request POST:/dteqms/exam_question_query
   * @secure
   */
  export namespace ExamQuestionQuery {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 题目分类(1,每日一学2,生活常识)
       * @format int32
       */
      type: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditWarningCase
   * @summary 编辑警示案列
   * @request POST:/dteqms/editWarningCase
   * @secure
   */
  export namespace EditWarningCase {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 内容 */
      content: string;
      /** 附件 */
      attachment: string;
      /** 可见范围 */
      visibility: string;
      /** 所属项目 */
      visibility_project?: string;
      /** 所属地区 */
      visibility_region?: string;
      /** 审核状态 */
      check_state?: string;
      /** 数据状态 */
      data_state: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程名称 */
      project_id?: string;
      /** 审核建议 */
      check_advise?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditTechnologyHelp
   * @summary 编辑技术求助
   * @request POST:/dteqms/editTechnologyHelp
   * @secure
   */
  export namespace EditTechnologyHelp {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 求助内容 */
      content: string;
      /** 是否愿意付费解决 */
      is_pay: string;
      /** 付费详情 */
      pay_details?: string;
      /** 是否匿名 */
      is_anony: string;
      /** 求助附件 */
      attachment?: string;
      /** 解决状态 */
      status: string;
      /** 可见范围 */
      visibility: string;
      /** 所属地区 */
      visibility_region?: string;
      /** 所属项目 */
      visibility_project?: string;
      /** 审核状态 */
      check_state?: string;
      /** 数据状态 */
      data_state: string;
      /** 所属工程 */
      project_id?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditTechnologyHelpRecord
   * @summary 编辑技术求助记录
   * @request POST:/dteqms/editTechnologyHelpRecord
   * @secure
   */
  export namespace EditTechnologyHelpRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 技术求助记录ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 技术求助ID */
      help_id?: string;
      /** 是否匿名 */
      is_anony?: string;
      /** 附件 */
      attachment?: string;
      /** 评价内容 */
      content?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditTechnicalSolveState
   * @summary 编辑技术求助解决状态
   * @request POST:/dteqms/editTechnicalSolveState
   * @secure
   */
  export namespace EditTechnicalSolveState {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 解决状态 */
      status?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditSystemNotice
   * @summary 编辑系统通知
   * @request POST:/dteqms/editSystemNotice
   * @secure
   */
  export namespace EditSystemNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 内容 */
      content: string;
      /** 有效时间 */
      period_time: string;
      /** id */
      id?: string;
      /** 创建用户id */
      create_user?: string;
      /** 更新用户id */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditSafetyUserSign
   * @summary 编辑加安全员打卡
   * @request POST:/dteqms/editSafetyUserSign
   * @secure
   */
  export namespace EditSafetyUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 签到图片 */
      img?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 签到类型 */
      type?: string;
      /** 标段id */
      project_id?: string;
      /** 审核用户id */
      check_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditSafetyTraining
   * @summary 编辑安全培训
   * @request POST:/dteqms/editSafetyTraining
   * @secure
   */
  export namespace EditSafetyTraining {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 培训名称 */
      train_name: string;
      /** 所属监理标段 */
      project_id5: string;
      /** 所属标段 */
      project_id: string;
      /** 进场类别 */
      entry_category: string;
      /** 培训类型 */
      train_type: string;
      /** 备注 */
      remark?: string;
      /** 培训形式 */
      train_format?: string;
      /** 培训时间 */
      meeting_time?: string;
      /** 培训内容简介 */
      train_desc?: string;
      /** 现场照片 */
      scene_photos?: string;
      /** 签到表照片 */
      sign_photos?: string;
      /** 附件 */
      attachment?: string;
      /** 数据状态 */
      data_state: string;
      /** ID */
      id?: string;
      /** 培训单位 */
      dept_id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 审核状态 */
      check_state?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name EditSafetyReform
   * @summary 编辑安全整改
   * @request POST:/dteqms/editSafetyReform
   * @secure
   */
  export namespace EditSafetyReform {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * id
       * @format int32
       */
      id: number;
      /**
       * 数据状态
       * @format int32
       */
      data_state?: number;
      /**
       * 审核状态
       * @format int32
       */
      check_state?: number;
      /**
       * 审核用户id
       * @format int32
       */
      check_uid?: number;
      /** 审核建议 */
      check_advise?: string;
      /**
       * 所属标段
       * @format int32
       */
      project_id?: number;
      /**
       * 单位
       * @format int32
       */
      dept_id?: number;
      /** 标题 */
      title?: string;
      /** 问题描述 */
      question_desc?: string;
      /** 整改要求 */
      requirements?: string;
      /** 整改截止时间 */
      reform_end_time?: string;
      /** 整改前照片 */
      pre_photos?: string;
      /**
       * 整改人
       * @format int32
       */
      reform_uid?: number;
      /** 整改完成时间 */
      reform_finish_time?: string;
      /** 整改后照片 */
      suf_photos?: string;
      /**
       * 整改状态
       * @format int32
       */
      reform_status?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditSafetyReformAssign
   * @summary 问题整改接收
   * @request POST:/dteqms/editSafetyReformAssign
   * @secure
   */
  export namespace EditSafetyReformAssign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 接收人 */
      reform_uid: string;
      /** 整改建议 */
      rect_advise?: string;
      /** ID */
      id?: string;
      /** 整改状态 */
      reform_status?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditProjectReport
   * @summary 编辑项目通报
   * @request POST:/dteqms/editProjectReport
   * @secure
   */
  export namespace EditProjectReport {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 通报类型 */
      type: string;
      /** 通报描述 */
      content: string;
      /** 附件 */
      attachment?: string;
      /** 可见范围 */
      visibility: string;
      /** 所属地区 */
      visibility_region?: string;
      /** 所属项目 */
      visibility_project?: string;
      /** 数据状态 */
      data_state: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 工程ID */
      project_id?: string;
      /** 发布状态 */
      publish_status?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name EditProblemReform
   * @summary 编辑问题整改
   * @request POST:/dteqms/editProblemReform
   * @secure
   */
  export namespace EditProblemReform {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id: string;
      /** 整改后照片 */
      suf_photos?: string;
      /** 整改完成时间 */
      reform_finish_time?: string;
      /** 整改描述 */
      rect_desc?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditPersonalLedger
   * @summary 编辑人员台账
   * @request POST:/dteqms/editPersonalLedger
   * @secure
   */
  export namespace EditPersonalLedger {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id: string;
      /** 台账类型 */
      type: string;
      /** 附件 */
      attachment: string;
      /** 创建时间 */
      create_time?: string;
      /** 创建人 */
      create_user?: string;
      /** 数据状态 */
      data_state: string;
      /** ID */
      id?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 设备名称 */
      name?: string;
      /** 操作员 */
      operator?: string;
      /** 联系方式 */
      contact?: string;
      /** 事由 */
      reason?: string;
      /** 进场时间 */
      in_time?: string;
      /** 离场时间 */
      out_time?: string;
      /** 备注 */
      remark?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditPenaltyNotice
   * @summary 编辑处罚公示
   * @request POST:/dteqms/editPenaltyNotice
   * @secure
   */
  export namespace EditPenaltyNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 处罚类别 */
      penalty_type: string;
      /** 案由 */
      cause_action: string;
      /** 处罚单位 */
      dept_id: string;
      /** 处罚决定书编号 */
      penalty_number: string;
      /** 执行情况 */
      execute_state: string;
      /** 可见范围 */
      visibility: string;
      /** 所属地区 */
      visibility_region?: string;
      /** 所属项目 */
      visibility_project?: string;
      /** 处罚决定内容 */
      penalty_desc: string;
      /** 附件 */
      attachment?: string;
      /** 数据状态 */
      data_state: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 处罚工程 */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditOffShelfProjectReport
   * @summary 项目通报下架
   * @request POST:/dteqms/editOffShelfProjectReport
   * @secure
   */
  export namespace EditOffShelfProjectReport {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 状态 */
      check_state?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditLaw
   * @summary 编辑法律法规
   * @request POST:/dteqms/editLaw
   * @secure
   */
  export namespace EditLaw {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 栏目类型 */
      column_type: string;
      /** 标题 */
      name: string;
      /** 编号 */
      code?: string;
      /** 内容 */
      content?: string;
      /** 附件 */
      attachment: string;
      /** 实施日期 */
      implement_date?: string;
      /** 颁布日期 */
      promulgate_date?: string;
      /** 数据状态 */
      data_state: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditLawRecord
   * @summary 编辑法律法规建议
   * @request POST:/dteqms/editLawRecord
   * @secure
   */
  export namespace EditLawRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 建议内容 */
      content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 法律法规ID */
      law_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditIndustryExplore
   * @summary 编辑先行者探索
   * @request POST:/dteqms/editIndustryExplore
   * @secure
   */
  export namespace EditIndustryExplore {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title?: string;
      /** 提出人 */
      proposer: string;
      /** 提出单位 */
      propose_unit: string;
      /** 使用项目数 */
      usepro_number: string;
      /** 学习成本 */
      learning_cost: string;
      /** 解决问题描述 */
      content: string;
      /** 轮播图片 */
      turning_picture: string;
      /** 其他图片 */
      other_picture?: string;
      /** 是否支持App操作 */
      is_support_app?: string;
      /** 数据状态 */
      data_state: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditHelpCheck
   * @summary 编辑帮扶检查
   * @request POST:/dteqms/editHelpCheck
   * @secure
   */
  export namespace EditHelpCheck {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id: string;
      /** 所属单位 */
      dept_id: string;
      /** 检查日期 */
      check_date: string;
      /** 检查形式 */
      check_form: string;
      /** 备注 */
      remark?: string;
      /** 整改前照片 */
      pre_photos: string;
      /** 问题描述 */
      question_desc: string;
      /** 依据 */
      basis?: string;
      /** 整改后照片 */
      suf_photos?: string;
      /** 整改描述 */
      requirements?: string;
      /** 整改日期 */
      reform_finish_time?: string;
      /** 整改状态 */
      status?: string;
      /** 数据状态 */
      data_state: string;
      /** 标题 */
      title?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 整改状态 */
      reform_status?: string;
      /** 审核建议 */
      check_advise?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 整改截止时间 */
      reform_end_time?: string;
      /** 整改人 */
      reform_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditHazardProject
   * @summary 编辑危大工程
   * @request POST:/dteqms/editHazardProject
   * @secure
   */
  export namespace EditHazardProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id: string;
      /** 施工单位名称 */
      dept_id: string;
      /** 名称 */
      name: string;
      /** 级别 */
      level: string;
      /** 专项方案审批记录 */
      approval_record?: string;
      /** 专项方案论证记录 */
      rationale_record?: string;
      /** 不停航施工组织方案批准记录 */
      constr_approval_record?: string;
      /** 航行通告 */
      navigation_bulletin?: string;
      /** 安全保证责任书 */
      safety_res_letter?: string;
      /** 其他 */
      other?: string;
      /** 交底记录 */
      dis_record?: string;
      /** 审批记录 */
      exam_approve_record?: string;
      /** 专职安管人员 */
      appoint_person?: string;
      /** 数据状态 */
      data_state: string;
      /** 危大工程分项 */
      itemize?: string;
      /** 交底/验收状态 */
      status?: string;
      /** 交底状态 */
      delivery_status?: string;
      /** 验收状态 */
      acc_record_status?: string;
      /** 位置1 */
      position_1?: string;
      /** 位置2 */
      position_2?: string;
      /** 位置3 */
      position_3?: string;
      /** 位置4 */
      position_4?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 验收记录 */
      acc_record?: string;
      /** 审批状态 */
      approval_state?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditExamStore
   * @summary 编辑题库
   * @request POST:/dteqms/editExamStore
   * @secure
   */
  export namespace EditExamStore {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 题目分类 */
      type: string;
      /** 题目类型 */
      category: string;
      /** 问题 */
      question: string;
      /** 正确答案 */
      answer: string;
      /** 选项 */
      option: string;
      /** 出处 */
      source?: string;
      /** 章节 */
      chapter?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditExamStoreMe
   * @summary 编辑学习笔记-错题
   * @request POST:/dteqms/editExamStoreMe
   * @secure
   */
  export namespace EditExamStoreMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 类型 */
      type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 题目ID */
      es_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditExamRecord
   * @summary 编辑考试记录
   * @request POST:/dteqms/editExamRecord
   * @secure
   */
  export namespace EditExamRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 考试类型 */
      exam_type?: string;
      /** 考试分数 */
      score?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditExamConf
   * @summary 编辑题库配置
   * @request POST:/dteqms/editExamConf
   * @secure
   */
  export namespace EditExamConf {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 题目分类 */
      type?: string;
      /** 每题分数 */
      score?: string;
      /** 题目数量 */
      cnt?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditCraftsmanship
   * @summary 行业匠心
   * @request POST:/dteqms/editCraftsmanship
   * @secure
   */
  export namespace EditCraftsmanship {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 提出人 */
      proposer: string;
      /** 提出单位 */
      propose_unit: string;
      /** 使用项目数 */
      usepro_number: string;
      /** 是否支持App操作 */
      is_support_app?: string;
      /** 学习成本 */
      learning_cost: string;
      /** 解决问题描述 */
      content: string;
      /** 轮播图片 */
      turning_picture: string;
      /** 其他图片 */
      other_picture?: string;
      /** 数据状态 */
      data_state: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name EditConveneNotice
   * @summary 编辑安全会议召集
   * @request POST:/dteqms/editConveneNotice
   * @secure
   */
  export namespace EditConveneNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 数据状态
       * @format int32
       */
      data_state?: number;
      /**
       * 审核状态
       * @format int32
       */
      check_state?: number;
      /**
       * 审核用户id
       * @format int32
       */
      check_uid?: number;
      /** 审核建议 */
      check_advise?: string;
      /**
       * 工程id
       * @format int32
       */
      project_id?: number;
      /**
       * 类型
       * @format int32
       */
      type?: number;
      /** 会议标题 */
      m_title?: string;
      /** 会议内容 */
      m_content?: string;
      /** 会议时间 */
      m_time?: string;
      /** 会议地址 */
      m_address?: string;
      /** 现场照片 */
      m_scence_picture?: string;
      /** 附件 */
      attachment?: string;
      /**
       * 是否包含施工安全方面
       * @format int32
       */
      has_safety_content?: number;
      /** 与会人 */
      attendees?: string;
      /**
       * 短信并行通知
       * @format int32
       */
      mes_notice?: number;
      /** 签到表 */
      att_sheet?: string;
      /**
       * id
       * @format int32
       */
      id?: number;
      /**
       * 单位
       * @format int32
       */
      dept_id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name EditConferenceNotice
   * @summary 编辑会议通知
   * @request POST:/dteqms/editConferenceNotice
   * @secure
   */
  export namespace EditConferenceNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 数据状态
       * @format int32
       */
      data_state?: number;
      /**
       * 审核状态
       * @format int32
       */
      check_state?: number;
      /**
       * 审核用户id
       * @format int32
       */
      check_uid?: number;
      /** 审核建议 */
      check_advise?: string;
      /**
       * 工程id
       * @format int32
       */
      project_id?: number;
      /**
       * 类型
       * @format int32
       */
      type?: number;
      /** 会议标题 */
      m_title?: string;
      /** 会议内容 */
      m_content?: string;
      /** 会议时间 */
      m_time?: string;
      /** 会议地址 */
      m_address?: string;
      /** 现场照片 */
      m_scence_picture?: string;
      /** 附件 */
      attachment?: string;
      /**
       * 是否包含施工安全方面
       * @format int32
       */
      has_safety_content?: number;
      /** 与会人 */
      attendees?: string;
      /**
       * 短信并行通知
       * @format int32
       */
      mes_notice?: number;
      /** 签到表 */
      att_sheet?: string;
      /**
       * id
       * @format int32
       */
      id?: number;
      /**
       * 单位
       * @format int32
       */
      dept_id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditCollectStoreMe
   * @summary 编辑学习笔记-收藏
   * @request POST:/dteqms/editCollectStoreMe
   * @secure
   */
  export namespace EditCollectStoreMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 类型 */
      type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 题目ID */
      es_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags user-controller
   * @name EditBaseUser
   * @summary 编辑用户
   * @request POST:/dteqms/editBaseUser
   * @secure
   */
  export namespace EditBaseUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * ID
       * @format int32
       */
      id: number;
      /**
       * 单位名称
       * @format int32
       */
      dept_id?: number;
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone: string;
      /** 电话 */
      tel?: string;
      /** 邮箱 */
      email?: string;
      /** 地址 */
      address?: string;
      /**
       * 性别
       * @format int32
       */
      gender?: number;
      /** 备注 */
      remark?: string;
      /** 头像 */
      avatar?: string;
      /** 生日 */
      birthday?: string;
      /** 昵称 */
      nick_name?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseUserSign
   * @summary 编辑用户签到
   * @request POST:/dteqms/editBaseUserSign
   * @secure
   */
  export namespace EditBaseUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 签到类型 */
      type?: string;
      /** 签到图片 */
      img?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标段id */
      project_id?: string;
      /** 审核用户id */
      check_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseUserRole
   * @summary 编辑用户角色关系
   * @request POST:/dteqms/editBaseUserRole
   * @secure
   */
  export namespace EditBaseUserRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户ID */
      uid?: string;
      /** 角色ID */
      role_id?: string;
      /** 工程ID */
      project_id?: string;
      /** 授权 */
      grants?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseSectionWork
   * @summary 编辑工作区
   * @request POST:/dteqms/editBaseSectionWork
   * @secure
   */
  export namespace EditBaseSectionWork {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标段 */
      project_id?: string;
      /** 标段工法 */
      section_method_id?: string;
      /** 施工单位 */
      dept_id: string;
      /** 名称 */
      name: string;
      /** 工作区范围 */
      map_range: string;
      /** 附件 */
      attach: string;
      /** 数据状态 */
      data_state: string;
      /** 施工开始日期 */
      start_time: string;
      /** 施工完工日期 */
      end_time: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标段 */
      project_name?: string;
      /** 项目名称 */
      project_name3?: string;
      /** 施工单位 */
      dept_name?: string;
      /** 标段工法 */
      method_type_name?: string;
      /** 完成状态 */
      finish_status?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseSectionMethod
   * @summary 编辑标段工法
   * @request POST:/dteqms/editBaseSectionMethod
   * @secure
   */
  export namespace EditBaseSectionMethod {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标段 */
      project_id: string;
      /** 工法类型 */
      method_type: string;
      /** 施工组织设计 */
      design?: string;
      /** 详细施工方案 */
      scheme?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseRole
   * @summary 编辑角色
   * @request POST:/dteqms/editBaseRole
   * @secure
   */
  export namespace EditBaseRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 角色名称 */
      name: string;
      /** 描述 */
      memo?: string;
      /** 单位名称 */
      dept_id: string;
      /** 角色授权 */
      grants?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseRoleDeptType
   * @summary 编辑角色与单位类型关系表
   * @request POST:/dteqms/editBaseRoleDeptType
   * @secure
   */
  export namespace EditBaseRoleDeptType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 角色模板名称 */
      name: string;
      /** 单位类型 */
      dept_type: string;
      /** 授权 */
      grants: string;
      /** 创建时间 */
      create_time?: string;
      /** ID */
      id?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 更新时间 */
      update_time?: string;
      /** 创建用户ID */
      create_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseRegion
   * @summary 编辑区域
   * @request POST:/dteqms/editBaseRegion
   * @secure
   */
  export namespace EditBaseRegion {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 地区名称 */
      name: string;
      /** 描述 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 父ID */
      pid?: string;
      /** 排序 */
      sort?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseProject7
   * @summary 编辑分项
   * @request POST:/dteqms/editBaseProject7
   * @secure
   */
  export namespace EditBaseProject7 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 分项名称 */
      name: string;
      /** 编码 */
      code: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseProject6
   * @summary 编辑标段
   * @request POST:/dteqms/editBaseProject6
   * @secure
   */
  export namespace EditBaseProject6 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标段名称 */
      name: string;
      /** 编码 */
      code: string;
      /** 地图范围 */
      map_range?: string;
      /** 应配备安全人员数量 */
      safety_number?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
      /** 层级 */
      level?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** 定位点坐标(经度,纬度) */
      fixed_position?: string;
      /** 控制点文件 */
      ctrl_file?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseProject5
   * @summary 编辑监理标段
   * @request POST:/dteqms/editBaseProject5
   * @secure
   */
  export namespace EditBaseProject5 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 监理标段名称 */
      name: string;
      /** 编码 */
      code: string;
      /** 应配备安全人员数量 */
      safety_number?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseProject4
   * @summary 编辑分部
   * @request POST:/dteqms/editBaseProject4
   * @secure
   */
  export namespace EditBaseProject4 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 分部名称 */
      name: string;
      /** 编码 */
      code: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseProject3
   * @summary 编辑项目
   * @request POST:/dteqms/editBaseProject3
   * @secure
   */
  export namespace EditBaseProject3 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 项目名称 */
      name: string;
      /** 编码 */
      code: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 项目状态 */
      finish_status?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 备注 */
      remark?: string;
      /** 控制点文件 */
      ctrl_file?: string;
      /** 定位点坐标(经度,纬度) */
      fixed_position?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags project-controller
   * @name EditBaseProject2
   * @summary 编辑工程
   * @request POST:/dteqms/editBaseProject2
   * @secure
   */
  export namespace EditBaseProject2 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id: string;
      /** 所属地区 */
      pid: string;
      /** 工程名称 */
      name: string;
      /** 编码 */
      code: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 地图范围 */
      map_range?: string;
      /** 专职安全管理人员数量 */
      safety_number?: string;
      /** 控制点文件 */
      ctrl_file?: string;
      /** 定位点坐标(经纬度) */
      fixed_position?: string;
      /** 备注 */
      remark?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseNotice
   * @summary 编辑系统通知
   * @request POST:/dteqms/editBaseNotice
   * @secure
   */
  export namespace EditBaseNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 处理状态 */
      deal_state?: string;
      /** 是否参会 */
      is_attend?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户ID */
      uid?: string;
      /** 会议ID */
      mid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseMethodType
   * @summary 编辑工法类型
   * @request POST:/dteqms/editBaseMethodType
   * @secure
   */
  export namespace EditBaseMethodType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 工法名称 */
      name: string;
      /** 描述 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 排序 */
      sort?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseFunctUserCommon
   * @summary 编辑用户常用功能配置
   * @request POST:/dteqms/editBaseFunctUserCommon
   * @secure
   */
  export namespace EditBaseFunctUserCommon {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 功能 */
      funct_id: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户 */
      user_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditBaseDept
   * @summary 编辑单位
   * @request POST:/dteqms/editBaseDept
   * @secure
   */
  export namespace EditBaseDept {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属地区 */
      region_id: string;
      /** 单位类型 */
      type: string;
      /** 单位名称 */
      name: string;
      /** 描述 */
      remark?: string;
      /** 附件 */
      attach?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 父ID */
      pid?: string;
      /** 排序 */
      sort?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditAdvice
   * @summary 编辑意见征求
   * @request POST:/dteqms/editAdvice
   * @secure
   */
  export namespace EditAdvice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 内容 */
      content: string;
      /** 截止时间 */
      end_time?: string;
      /** 可见范围 */
      visibility: string;
      /** 所属地区 */
      visibility_region?: string;
      /** 所属项目 */
      visibility_project?: string;
      /** 附件 */
      attachment?: string;
      /** 数据状态 */
      data_state: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 所属工程 */
      project_id?: string;
      /** 审核状态 */
      check_state: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name EditAdviceRecord
   * @summary 编辑意见征求评价
   * @request POST:/dteqms/editAdviceRecord
   * @secure
   */
  export namespace EditAdviceRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 评价内容 */
      content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 意见征求ID */
      advice_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name EditAddressBook
   * @summary 编辑通讯录
   * @request POST:/dteqms/editAddressBook
   * @secure
   */
  export namespace EditAddressBook {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * ID
       * @format int32
       */
      id: number;
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /** 职务 */
      duties?: string;
      /**
       * 所属地区
       * @format int32
       */
      region_id?: number;
      /**
       * 单位
       * @format int32
       */
      dept_id?: number;
      /**
       * 项目id
       * @format int32
       */
      project_id3?: number;
      /**
       * 监理标段id
       * @format int32
       */
      project_id5?: number;
      /**
       * 标段id
       * @format int32
       */
      project_id6?: number;
      /**
       * 单位类型
       * @format int32
       */
      type?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteWarningCase
   * @summary 删除警示案列
   * @request POST:/dteqms/deleteWarningCase
   * @secure
   */
  export namespace DeleteWarningCase {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 标题 */
      title?: string;
      /** 内容 */
      content?: string;
      /** 附件地址 */
      attachment?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteTechnologyHelp
   * @summary 删除技术求助
   * @request POST:/dteqms/deleteTechnologyHelp
   * @secure
   */
  export namespace DeleteTechnologyHelp {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 标题 */
      title?: string;
      /** 求助内容 */
      content?: string;
      /** 是否愿意付费解决 */
      is_pay?: string;
      /** 是否匿名 */
      is_anony?: string;
      /** 状态 */
      status?: string;
      /** 附件地址 */
      attachment?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteTechnologyHelpRecord
   * @summary 删除技术求助记录
   * @request POST:/dteqms/deleteTechnologyHelpRecord
   * @secure
   */
  export namespace DeleteTechnologyHelpRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 技术求助记录ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 技术求助ID */
      help_id?: string;
      /** 评价内容 */
      content?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteSystemNotice
   * @summary 删除系统通知
   * @request POST:/dteqms/deleteSystemNotice
   * @secure
   */
  export namespace DeleteSystemNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id?: string;
      /** 创建用户id */
      create_user?: string;
      /** 更新用户id */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标题 */
      title?: string;
      /** 内容 */
      content?: string;
      /** 有效时间 */
      period_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteSafetyUserSign
   * @summary 删除用户签到
   * @request POST:/dteqms/deleteSafetyUserSign
   * @secure
   */
  export namespace DeleteSafetyUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 签到类型 */
      type?: string;
      /** 签到图片 */
      img?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标段id */
      project_id?: string;
      /** 审核用户id */
      check_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteSafetyTraining
   * @summary 删除安全培训
   * @request POST:/dteqms/deleteSafetyTraining
   * @secure
   */
  export namespace DeleteSafetyTraining {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 培训名称 */
      train_name?: string;
      /** 进场类别 */
      entry_category?: string;
      /** 培训类型 */
      train_type?: string;
      /** 培训形式 */
      train_format?: string;
      /** 会议时间 */
      meeting_time?: string;
      /** 培训内容简介 */
      train_desc?: string;
      /** 现场照片 */
      scene_photos?: string;
      /** 签到表照片 */
      sign_photos?: string;
      /** 附件 */
      attachment?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 标段id */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name DeleteSafetyReform
   * @summary 删除问题整改
   * @request POST:/dteqms/deleteSafetyReform
   * @secure
   */
  export namespace DeleteSafetyReform {
    export type RequestParams = {};
    export type RequestQuery = {
      /** id */
      id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteProjectReport
   * @summary 删除项目通报
   * @request POST:/dteqms/deleteProjectReport
   * @secure
   */
  export namespace DeleteProjectReport {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 通报类型 */
      type?: string;
      /** 标题 */
      title?: string;
      /** 通报描述 */
      content?: string;
      /** 附件地址 */
      attachment?: string;
      /** 发布状态 */
      publish_status?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeletePersonalLedger
   * @summary 删除人员台账
   * @request POST:/dteqms/deletePersonalLedger
   * @secure
   */
  export namespace DeletePersonalLedger {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 设备名称 */
      name?: string;
      /** 操作员 */
      operator?: string;
      /** 联系方式 */
      contact?: string;
      /** 事由 */
      reason?: string;
      /** 附件地址 */
      attachment?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeletePenaltyNotice
   * @summary 删除处罚公示
   * @request POST:/dteqms/deletePenaltyNotice
   * @secure
   */
  export namespace DeletePenaltyNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 案由 */
      cause_action?: string;
      /** 处罚决定书编号 */
      penalty_number?: string;
      /** 处罚决定内容 */
      penalty_desc?: string;
      /** 执行情况 */
      execute_state?: string;
      /** 处罚类别 */
      penalty_type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
      /** 处罚单位ID */
      dept_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteMeetingNotice
   * @summary 删除会议通知
   * @request POST:/dteqms/deleteMeetingNotice
   * @secure
   */
  export namespace DeleteMeetingNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 会议标题 */
      m_title?: string;
      /** 会议内容 */
      m_content?: string;
      /** 会议时间 */
      m_time?: string;
      /** 会议地点 */
      m_address?: string;
      /** 现场照片地址 */
      m_scence_picture?: string;
      /** 附件 */
      attachment?: string;
      /** 是否包含施工安全方面内容 */
      has_safety_content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteLaw
   * @summary 删除法律法规
   * @request POST:/dteqms/deleteLaw
   * @secure
   */
  export namespace DeleteLaw {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 栏目类型 */
      column_type?: string;
      /** 编号 */
      code?: string;
      /** 名称 */
      name?: string;
      /** 内容 */
      content?: string;
      /** 附件地址 */
      attachment?: string;
      /** 实施日期 */
      implement_date?: string;
      /** 颁布日期 */
      promulgate_date?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteLawRecord
   * @summary 删除法律法规建议
   * @request POST:/dteqms/deleteLawRecord
   * @secure
   */
  export namespace DeleteLawRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 建议内容 */
      content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 法律法规ID */
      law_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteIndustryExplore
   * @summary 删除先行者探索
   * @request POST:/dteqms/deleteIndustryExplore
   * @secure
   */
  export namespace DeleteIndustryExplore {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 标题 */
      title?: string;
      /** 解决问题描述 */
      content?: string;
      /** 是否支持App操作 */
      is_support_app?: string;
      /** 学习成本 */
      learning_cost?: string;
      /** 轮播图片地址 */
      turning_picture?: string;
      /** 其他图片地址 */
      other_picture?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteHelpCheck
   * @summary 删除帮扶检查
   * @request POST:/dteqms/deleteHelpCheck
   * @secure
   */
  export namespace DeleteHelpCheck {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 标题 */
      title?: string;
      /** 整改状态 */
      reform_status?: string;
      /** 整改截止时间 */
      reform_end_time?: string;
      /** 整改完成时间 */
      reform_finish_time?: string;
      /** 问题描述 */
      question_desc?: string;
      /** 整改要求 */
      requirements?: string;
      /** 整改前照片 */
      pre_photos?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 标段ID */
      project_id?: string;
      /** 单位ID */
      dept_id?: string;
      /** 整改人ID */
      reform_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteHazardProject
   * @summary 删除危大工程
   * @request POST:/dteqms/deleteHazardProject
   * @secure
   */
  export namespace DeleteHazardProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 名称 */
      name?: string;
      /** 级别 */
      level?: string;
      /** 状态 */
      status?: string;
      /** 位置1 */
      position_1?: string;
      /** 位置1 */
      position_2?: string;
      /** 位置1 */
      position_3?: string;
      /** 位置1 */
      position_4?: string;
      /** 专项方案审批记录 */
      approval_record?: string;
      /** 专项方案论证记录 */
      rationale_record?: string;
      /** 施工批准记录 */
      constr_approval_record?: string;
      /** 航行通告(不停航施工） */
      navigation_bulletin?: string;
      /** 安全保证责任书 */
      safety_res_letter?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 标段id */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteExamStore
   * @summary 删除题库
   * @request POST:/dteqms/deleteExamStore
   * @secure
   */
  export namespace DeleteExamStore {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 题目分类 */
      type?: string;
      /** 题目类型 */
      category?: string;
      /** 问题 */
      question?: string;
      /** 正确答案 */
      answer?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteExamStoreMe
   * @summary 删除学习笔记-错题
   * @request POST:/dteqms/deleteExamStoreMe
   * @secure
   */
  export namespace DeleteExamStoreMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 类型 */
      type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 题目ID */
      es_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteExamRecord
   * @summary 删除考试记录
   * @request POST:/dteqms/deleteExamRecord
   * @secure
   */
  export namespace DeleteExamRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 考试类型 */
      exam_type?: string;
      /** 考试分数 */
      score?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteExamConf
   * @summary 删除题库配置
   * @request POST:/dteqms/deleteExamConf
   * @secure
   */
  export namespace DeleteExamConf {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 每题分数 */
      score?: string;
      /** 题目数量 */
      cnt?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteCraftsmanship
   * @summary 行业匠心
   * @request POST:/dteqms/deleteCraftsmanship
   * @secure
   */
  export namespace DeleteCraftsmanship {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 标题 */
      title?: string;
      /** 解决问题描述 */
      content?: string;
      /** 是否支持App操作 */
      is_support_app?: string;
      /** 学习成本 */
      learning_cost?: string;
      /** 轮播图片地址 */
      turning_picture?: string;
      /** 其他图片地址 */
      other_picture?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteConveningOfMeeting
   * @summary 删除会议召集
   * @request POST:/dteqms/deleteConveningOfMeeting
   * @secure
   */
  export namespace DeleteConveningOfMeeting {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 会议标题 */
      m_title?: string;
      /** 会议内容 */
      m_content?: string;
      /** 会议时间 */
      m_time?: string;
      /** 会议地点 */
      m_address?: string;
      /** 现场照片地址 */
      m_scence_picture?: string;
      /** 附件 */
      attachment?: string;
      /** 是否包含施工安全方面内容 */
      has_safety_content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteCollectStoreMe
   * @summary 删除学习笔记-收藏
   * @request POST:/dteqms/deleteCollectStoreMe
   * @secure
   */
  export namespace DeleteCollectStoreMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 类型 */
      type?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 题目ID */
      es_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseUser
   * @summary 删除用户
   * @request POST:/dteqms/deleteBaseUser
   * @secure
   */
  export namespace DeleteBaseUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /** 密码 */
      pwd?: string;
      /** 传真电话 */
      tel?: string;
      /** E-Mail */
      email?: string;
      /** 地址 */
      address?: string;
      /** 性别 */
      gender?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 单位ID */
      dept_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseUserSign
   * @summary 删上班签到
   * @request POST:/dteqms/deleteBaseUserSign
   * @secure
   */
  export namespace DeleteBaseUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 签到类型 */
      type?: string;
      /** 签到图片 */
      img?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标段id */
      project_id?: string;
      /** 审核用户id */
      check_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseUserRole
   * @summary 删除用户角色关系
   * @request POST:/dteqms/deleteBaseUserRole
   * @secure
   */
  export namespace DeleteBaseUserRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户ID */
      uid?: string;
      /** 角色ID */
      role_id?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseSectionWork
   * @summary 删除工作区
   * @request POST:/dteqms/deleteBaseSectionWork
   * @secure
   */
  export namespace DeleteBaseSectionWork {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 名称 */
      name?: string;
      /** 工作区范围 */
      map_range?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标段工法ID */
      section_method_id?: string;
      /** 标段ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseSectionMethod
   * @summary 删除标段工法
   * @request POST:/dteqms/deleteBaseSectionMethod
   * @secure
   */
  export namespace DeleteBaseSectionMethod {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 工法类型 */
      method_type?: string;
      /** 施工组织设计 */
      design?: string;
      /** 详细施工方案 */
      scheme?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 标段ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseRole
   * @summary 删除角色
   * @request POST:/dteqms/deleteBaseRole
   * @secure
   */
  export namespace DeleteBaseRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 描述 */
      memo?: string;
      /** 角色授权 */
      grants?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 单位ID */
      dept_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseRoleDeptType
   * @summary 删除角色与单位类型关系表
   * @request POST:/dteqms/deleteBaseRoleDeptType
   * @secure
   */
  export namespace DeleteBaseRoleDeptType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 单位类型(121) */
      dept_type?: string;
      /** 授权 */
      grants?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseRegion
   * @summary 删除区域
   * @request POST:/dteqms/deleteBaseRegion
   * @secure
   */
  export namespace DeleteBaseRegion {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 父ID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseProject7
   * @summary 删除分项
   * @request POST:/dteqms/deleteBaseProject7
   * @secure
   */
  export namespace DeleteBaseProject7 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseProject6
   * @summary 删除标段
   * @request POST:/dteqms/deleteBaseProject6
   * @secure
   */
  export namespace DeleteBaseProject6 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseProject5
   * @summary 删除监理标段
   * @request POST:/dteqms/deleteBaseProject5
   * @secure
   */
  export namespace DeleteBaseProject5 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 地图范围 */
      map_range?: string;
      /** 层级 */
      level?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseProject4
   * @summary 删除分部
   * @request POST:/dteqms/deleteBaseProject4
   * @secure
   */
  export namespace DeleteBaseProject4 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseProject3
   * @summary 删除项目
   * @request POST:/dteqms/deleteBaseProject3
   * @secure
   */
  export namespace DeleteBaseProject3 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseProject2
   * @summary 删除工程
   * @request POST:/dteqms/deleteBaseProject2
   * @secure
   */
  export namespace DeleteBaseProject2 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseNotice
   * @summary 删除系统通知
   * @request POST:/dteqms/deleteBaseNotice
   * @secure
   */
  export namespace DeleteBaseNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 处理状态 */
      deal_state?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户ID */
      uid?: string;
      /** 会议ID */
      mid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseMethodType
   * @summary 删除工法类型
   * @request POST:/dteqms/deleteBaseMethodType
   * @secure
   */
  export namespace DeleteBaseMethodType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseFunctUserCommon
   * @summary 删除用户常用功能配置
   * @request POST:/dteqms/deleteBaseFunctUserCommon
   * @secure
   */
  export namespace DeleteBaseFunctUserCommon {
    export type RequestParams = {};
    export type RequestQuery = {
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户ID */
      user_id?: string;
      /** 功能ID */
      funct_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteBaseDept
   * @summary 删除单位
   * @request POST:/dteqms/deleteBaseDept
   * @secure
   */
  export namespace DeleteBaseDept {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 所属管理局 */
      admin_room?: string;
      /** 所属地区质检站 */
      quality_station?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 所属地区ID */
      region_id?: string;
      /** 父ID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteAdvice
   * @summary 删除意见征求
   * @request POST:/dteqms/deleteAdvice
   * @secure
   */
  export namespace DeleteAdvice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 标题 */
      title?: string;
      /** 内容 */
      content?: string;
      /** 附件地址 */
      attachment?: string;
      /** 截止时间 */
      end_time?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteAdviceRecord
   * @summary 删除意见征求评价
   * @request POST:/dteqms/deleteAdviceRecord
   * @secure
   */
  export namespace DeleteAdviceRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 评价内容 */
      content?: string;
      /** ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 意见征求ID */
      advice_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name DeleteAddressBook
   * @summary 删除通讯录
   * @request POST:/dteqms/deleteAddressBook
   * @secure
   */
  export namespace DeleteAddressBook {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 通讯录ID */
      id?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 所属工程 */
      project_id?: string;
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /** 单位ID */
      dept_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags user-controller
   * @name CommWeather
   * @summary 获取天气情况
   * @request POST:/dteqms/commWeather
   * @secure
   */
  export namespace CommWeather {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags user-controller
   * @name CommUserUnlock
   * @summary 用户解锁
   * @request POST:/dteqms/commUserUnlock
   * @secure
   */
  export namespace CommUserUnlock {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 用户ID */
      uid: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags user-controller
   * @name CommUserSmsCode
   * @summary 获取短信验证码
   * @request POST:/dteqms/commUserSmsCode
   * @secure
   */
  export namespace CommUserSmsCode {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 手机号 */
      phone: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags user-controller
   * @name CommUserSign
   * @summary 用户签到
   * @request POST:/dteqms/commUserSign
   * @secure
   */
  export namespace CommUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 签到类型(1:直接打卡;2:拍照打卡) */
      sign_type: string;
      /** 签到照片 */
      sign_img?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags user-controller
   * @name CommUserRepwd
   * @summary 用户修改密码
   * @request POST:/dteqms/commUserRepwd
   * @secure
   */
  export namespace CommUserRepwd {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 用户ID */
      uid?: string;
      /** 密码 */
      pwd: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags user-controller
   * @name CommUserLogin
   * @summary 用户登录
   * @request POST:/dteqms/commUserLogin
   * @secure
   */
  export namespace CommUserLogin {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 手机号 */
      phone: string;
      /** 短信验证码 */
      sms_code?: string;
      /** 密码 */
      pwd?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags user-controller
   * @name CommUserGrants
   * @summary 用户权限
   * @request POST:/dteqms/commUserGrants
   * @secure
   */
  export namespace CommUserGrants {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 工程ID */
      project_id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags user-controller
   * @name CommGetUserSign
   * @summary 获取用户签到
   * @request POST:/dteqms/commGetUserSign
   * @secure
   */
  export namespace CommGetUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 开始日期 */
      start_date: string;
      /** 结束日期 */
      end_date: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags user-controller
   * @name CommEditUser
   * @summary 修改个人资料
   * @request POST:/dteqms/commEditUser
   * @secure
   */
  export namespace CommEditUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 头像 */
      avatar?: string;
      /** 昵称 */
      nick_name?: string;
      /** 性别 */
      gender?: string;
      /** 生日 */
      birthday?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags check-controller
   * @name CheckAuditingDual
   * @summary 功能审核
   * @request POST:/dteqms/checkAuditingDual
   * @secure
   */
  export namespace CheckAuditingDual {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 表名 */
      table_name: string;
      /** ID */
      id: string;
      /** 审核状态(1:暂存;2:待审核;3:审核通过;4:驳回) */
      check_state: string;
      /** 审核建议 */
      check_advise?: string;
      /** 交底状态 */
      status?: string;
      /** 审批状态 */
      approval_state?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name BatchNoticeByType
   * @summary 根会议状态更改为已读
   * @request POST:/dteqms/batchNoticeByType
   * @secure
   */
  export namespace BatchNoticeByType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 类型(1:会议通知;2:安全会议;) */
      type?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddWarningCase
   * @summary 添加警示案列
   * @request POST:/dteqms/addWarningCase
   * @secure
   */
  export namespace AddWarningCase {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 内容 */
      content: string;
      /** 附件 */
      attachment: string;
      /** 可见范围 */
      visibility: string;
      /** 所属地区 */
      visibility_region?: string;
      /** 所属项目 */
      visibility_project?: string;
      /** 数据状态 */
      data_state: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 工程名称 */
      project_id?: string;
      /** 审核建议 */
      check_advise?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddTechnologyHelp
   * @summary 添加技术求助
   * @request POST:/dteqms/addTechnologyHelp
   * @secure
   */
  export namespace AddTechnologyHelp {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 求助内容 */
      content: string;
      /** 是否愿意付费解决 */
      is_pay: string;
      /** 付费详情 */
      pay_details?: string;
      /** 是否匿名 */
      is_anony: string;
      /** 求助附件 */
      attachment?: string;
      /** 可见范围 */
      visibility: string;
      /** 所属地区 */
      visibility_region?: string;
      /** 所属项目 */
      visibility_project?: string;
      /** 数据状态 */
      data_state: string;
      /** 所属工程 */
      project_id?: string;
      /** 解决状态 */
      status?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddTechnologyHelpRecord
   * @summary 添加技术求助记录
   * @request POST:/dteqms/addTechnologyHelpRecord
   * @secure
   */
  export namespace AddTechnologyHelpRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 评价内容 */
      content: string;
      /** 是否匿名 */
      is_anony?: string;
      /** 附件 */
      attachment?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 技术求助ID */
      help_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddSystemNotice
   * @summary 添加系统通知
   * @request POST:/dteqms/addSystemNotice
   * @secure
   */
  export namespace AddSystemNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 内容 */
      content: string;
      /** 有效时间 */
      period_time: string;
      /** 创建用户id */
      create_user?: string;
      /** 更新用户id */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddSafetyUserSign
   * @summary 添加安全员打卡
   * @request POST:/dteqms/addSafetyUserSign
   * @secure
   */
  export namespace AddSafetyUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 签到图片 */
      img?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 签到类型 */
      type?: string;
      /** 标段id */
      project_id?: string;
      /** 审核用户id */
      check_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddSafetyTraining
   * @summary 添加安全培训
   * @request POST:/dteqms/addSafetyTraining
   * @secure
   */
  export namespace AddSafetyTraining {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 培训名称 */
      train_name: string;
      /** 所属监理标段 */
      project_id5: string;
      /** 所属标段 */
      project_id: string;
      /** 进场类别 */
      entry_category: string;
      /** 培训类型 */
      train_type: string;
      /** 备注 */
      remark?: string;
      /** 培训形式 */
      train_format?: string;
      /** 培训时间 */
      meeting_time?: string;
      /** 培训内容简介 */
      train_desc?: string;
      /** 现场照片 */
      scene_photos?: string;
      /** 签到表照片 */
      sign_photos?: string;
      /** 附件 */
      attachment?: string;
      /** 数据状态 */
      data_state: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 培训单位 */
      dept_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddSafetyReform
   * @summary 添加安全整改
   * @request POST:/dteqms/addSafetyReform
   * @secure
   */
  export namespace AddSafetyReform {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id: string;
      /** 所属单位 */
      dept_id: string;
      /** 标题 */
      title: string;
      /** 问题描述 */
      question_desc: string;
      /** 整改要求 */
      requirements: string;
      /** 整改截止时间 */
      reform_end_time: string;
      /** 整改前照片 */
      pre_photos: string;
      /** 数据状态 */
      data_state: string;
      /** 整改人 */
      reform_uid?: string;
      /** 整改完成时间 */
      reform_finish_time?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 审核状态 */
      check_state?: string;
      /** 整改状态 */
      reform_status?: string;
      /** 整改后照片 */
      suf_photos?: string;
      /** 单位类型 */
      dept_type?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddProjectReport
   * @summary 添加项目通报
   * @request POST:/dteqms/addProjectReport
   * @secure
   */
  export namespace AddProjectReport {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 通报类型 */
      type: string;
      /** 附件 */
      attachment?: string;
      /** 通报描述 */
      content: string;
      /** 可见范围 */
      visibility: string;
      /** 所属项目 */
      visibility_project?: string;
      /** 所属地区 */
      visibility_region?: string;
      /** 数据状态 */
      data_state: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 工程ID */
      project_id?: string;
      /** 通报状态 */
      check_state?: string;
      /** 发布状态 */
      publish_status?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddPersonalLedger
   * @summary 添加人员台账
   * @request POST:/dteqms/addPersonalLedger
   * @secure
   */
  export namespace AddPersonalLedger {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id: string;
      /** 台账类型 */
      type: string;
      /** 附件 */
      attachment: string;
      /** 数据状态 */
      data_state: string;
      /** 设备名称 */
      name?: string;
      /** 操作员 */
      operator?: string;
      /** 联系方式 */
      contact?: string;
      /** 事由 */
      reason?: string;
      /** 备注 */
      remark?: string;
      /** 审核状态 */
      check_state?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddPenaltyNotice
   * @summary 添加处罚公示
   * @request POST:/dteqms/addPenaltyNotice
   * @secure
   */
  export namespace AddPenaltyNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 处罚类别 */
      penalty_type: string;
      /** 案由 */
      cause_action: string;
      /** 处罚决定书编号 */
      penalty_number: string;
      /** 被处罚单位 */
      dept_id: string;
      /** 执行情况 */
      execute_state: string;
      /** 可见范围 */
      visibility: string;
      /** 所属地区 */
      visibility_region?: string;
      /** 所属项目 */
      visibility_project?: string;
      /** 处罚决定内容 */
      penalty_desc: string;
      /** 附件 */
      attachment?: string;
      /** 数据状态 */
      data_state: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 处罚工程 */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddLaw
   * @summary 添加法律法规
   * @request POST:/dteqms/addLaw
   * @secure
   */
  export namespace AddLaw {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 公告栏目 */
      column_type: string;
      /** 标题 */
      name: string;
      /** 编号 */
      code?: string;
      /** 内容 */
      content?: string;
      /** 附件 */
      attachment: string;
      /** 实施日期 */
      implement_date?: string;
      /** 颁布日期 */
      promulgate_date?: string;
      /** 数据状态 */
      data_state: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddLawRecord
   * @summary 添加法律法规建议
   * @request POST:/dteqms/addLawRecord
   * @secure
   */
  export namespace AddLawRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 建议内容 */
      content: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 法律法规ID */
      law_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddIndustryExplore
   * @summary 添加先行者探索
   * @request POST:/dteqms/addIndustryExplore
   * @secure
   */
  export namespace AddIndustryExplore {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 提出人 */
      proposer: string;
      /** 提出单位 */
      propose_unit: string;
      /** 使用项目数 */
      usepro_number: string;
      /** 学习成本 */
      learning_cost: string;
      /** 解决问题描述 */
      content: string;
      /** 轮播图片 */
      turning_picture: string;
      /** 其他图片 */
      other_picture?: string;
      /** 是否支持App操作 */
      is_support_app?: string;
      /** 数据状态 */
      data_state: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddHelpCheck
   * @summary 添加帮扶检查
   * @request POST:/dteqms/addHelpCheck
   * @secure
   */
  export namespace AddHelpCheck {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id: string;
      /** 所属单位 */
      dept_id: string;
      /** 检查日期 */
      check_date: string;
      /** 检查形式 */
      check_form: string;
      /** 备注 */
      remark?: string;
      /** 整改前照片 */
      pre_photos: string;
      /** 问题描述 */
      question_desc: string;
      /** 依据 */
      basis?: string;
      /** 数据状态 */
      data_state: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 标题 */
      title?: string;
      /** 整改状态 */
      reform_status?: string;
      /** 整改人ID */
      reform_uid?: string;
      /** 整改完成时间 */
      reform_finish_time?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 整改后照片 */
      suf_photos?: string;
      /** 整改要求 */
      requirements?: string;
      /** 整改截止时间 */
      reform_end_time?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddHazardProject
   * @summary 添加危大工程
   * @request POST:/dteqms/addHazardProject
   * @secure
   */
  export namespace AddHazardProject {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属标段 */
      project_id: string;
      /** 施工单位名称 */
      dept_id: string;
      /** 危大工程名称 */
      name: string;
      /** 级别 */
      level: string;
      /** 专项方案审批记录 */
      approval_record?: string;
      /** 专项方案论证记录 */
      rationale_record?: string;
      /** 不停航施工组织方案批准记录 */
      constr_approval_record?: string;
      /** 航行通告 */
      navigation_bulletin?: string;
      /** 安全保证责任书 */
      safety_res_letter?: string;
      /** 其他 */
      other?: string;
      /** 专职安管人员 */
      appoint_person?: string;
      /** 数据状态 */
      data_state: string;
      /** 危大工程分项 */
      itemize?: string;
      /** 交底/验收状态 */
      status?: string;
      /** 位置1 */
      position_1?: string;
      /** 位置2 */
      position_2?: string;
      /** 位置3 */
      position_3?: string;
      /** 位置4 */
      position_4?: string;
      /** 审核状态 */
      check_state?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 审批状态 */
      approval_state?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddExamStore
   * @summary 添加题库
   * @request POST:/dteqms/addExamStore
   * @secure
   */
  export namespace AddExamStore {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 题目分类 */
      type: string;
      /** 题目类型 */
      category: string;
      /** 问题 */
      question: string;
      /** 正确答案 */
      answer: string;
      /** 选项 */
      option: string;
      /** 出处 */
      source?: string;
      /** 章节 */
      chapter?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddExamStoreMe
   * @summary 添加学习笔记-错题
   * @request POST:/dteqms/addExamStoreMe
   * @secure
   */
  export namespace AddExamStoreMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 类型 */
      type?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 题目ID */
      es_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddExamRecord
   * @summary 添加考试记录
   * @request POST:/dteqms/addExamRecord
   * @secure
   */
  export namespace AddExamRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 考试类型 */
      exam_type?: string;
      /** 考试分数 */
      score?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddExamConf
   * @summary 添加题库配置
   * @request POST:/dteqms/addExamConf
   * @secure
   */
  export namespace AddExamConf {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 题目分类 */
      type?: string;
      /** 每题分数 */
      score?: string;
      /** 题目数量 */
      cnt?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddCraftsmanship
   * @summary 行业匠心
   * @request POST:/dteqms/addCraftsmanship
   * @secure
   */
  export namespace AddCraftsmanship {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 提出人 */
      proposer: string;
      /** 提出单位 */
      propose_unit: string;
      /** 使用项目数 */
      usepro_number: string;
      /** 是否支持App操作 */
      is_support_app?: string;
      /** 学习成本 */
      learning_cost: string;
      /** 解决问题描述 */
      content: string;
      /** 轮播图片 */
      turning_picture: string;
      /** 其他图片 */
      other_picture?: string;
      /** 数据状态 */
      data_state: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 类型 */
      type?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name AddConveneNotice
   * @summary 添加安全会议召集
   * @request POST:/dteqms/addConveneNotice
   * @secure
   */
  export namespace AddConveneNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 数据状态
       * @format int32
       */
      data_state?: number;
      /**
       * 审核状态
       * @format int32
       */
      check_state?: number;
      /**
       * 审核用户id
       * @format int32
       */
      check_uid?: number;
      /** 审核建议 */
      check_advise?: string;
      /**
       * 工程id
       * @format int32
       */
      project_id?: number;
      /**
       * 类型
       * @format int32
       */
      type?: number;
      /** 会议标题 */
      m_title?: string;
      /** 会议内容 */
      m_content?: string;
      /** 会议时间 */
      m_time?: string;
      /** 会议地址 */
      m_address?: string;
      /** 现场照片 */
      m_scence_picture?: string;
      /** 附件 */
      attachment?: string;
      /**
       * 是否包含施工安全方面
       * @format int32
       */
      has_safety_content?: number;
      /** 与会人 */
      attendees?: string;
      /**
       * 短信并行通知
       * @format int32
       */
      mes_notice?: number;
      /** 签到表 */
      att_sheet?: string;
      /**
       * 单位
       * @format int32
       */
      dept_id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name AddConferenceNotice
   * @summary 添加会议通知
   * @request POST:/dteqms/addConferenceNotice
   * @secure
   */
  export namespace AddConferenceNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 数据状态
       * @format int32
       */
      data_state?: number;
      /**
       * 审核状态
       * @format int32
       */
      check_state?: number;
      /**
       * 审核用户id
       * @format int32
       */
      check_uid?: number;
      /** 审核建议 */
      check_advise?: string;
      /**
       * 工程id
       * @format int32
       */
      project_id?: number;
      /**
       * 类型
       * @format int32
       */
      type?: number;
      /** 会议标题 */
      m_title?: string;
      /** 会议内容 */
      m_content?: string;
      /** 会议时间 */
      m_time?: string;
      /** 会议地址 */
      m_address?: string;
      /** 现场照片 */
      m_scence_picture?: string;
      /** 附件 */
      attachment?: string;
      /**
       * 是否包含施工安全方面
       * @format int32
       */
      has_safety_content?: number;
      /** 与会人 */
      attendees?: string;
      /**
       * 短信并行通知
       * @format int32
       */
      mes_notice?: number;
      /** 签到表 */
      att_sheet?: string;
      /**
       * 单位id
       * @format int32
       */
      dept_id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddCollectStoreMe
   * @summary 添加学习笔记-收藏
   * @request POST:/dteqms/addCollectStoreMe
   * @secure
   */
  export namespace AddCollectStoreMe {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 类型 */
      type?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 题目ID */
      es_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags user-controller
   * @name AddBaseUser
   * @summary 添加用户
   * @request POST:/dteqms/addBaseUser
   * @secure
   */
  export namespace AddBaseUser {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * 单位名称
       * @format int32
       */
      dept_id?: number;
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone: string;
      /** 电话 */
      tel?: string;
      /** 邮箱 */
      email?: string;
      /** 地址 */
      address?: string;
      /**
       * 性别
       * @format int32
       */
      gender?: number;
      /** 备注 */
      remark?: string;
      /** 头像 */
      avatar?: string;
      /** 生日 */
      birthday?: string;
      /** 昵称 */
      nick_name?: string;
      /** 密码 */
      pwd: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseUserSign
   * @summary 添加上班签到
   * @request POST:/dteqms/addBaseUserSign
   * @secure
   */
  export namespace AddBaseUserSign {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 签到图片 */
      img?: string;
      /** 审核状态 */
      check_state?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 签到类型 */
      type?: string;
      /** 标段id */
      project_id?: string;
      /** 审核用户id */
      check_uid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseUserRole
   * @summary 添加用户角色关系
   * @request POST:/dteqms/addBaseUserRole
   * @secure
   */
  export namespace AddBaseUserRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 用户ID */
      uid?: string;
      /** 角色ID */
      role_id?: string;
      /** 工程ID */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseSectionWork
   * @summary 添加工作区
   * @request POST:/dteqms/addBaseSectionWork
   * @secure
   */
  export namespace AddBaseSectionWork {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标段 */
      project_id: string;
      /** 施工单位 */
      dept_id: string;
      /** 标段工法 */
      section_method_id: string;
      /** 名称 */
      name: string;
      /** 工作区范围 */
      map_range: string;
      /** 附件 */
      attach: string;
      /** 数据状态 */
      data_state: string;
      /** 施工开始日期 */
      start_time: string;
      /** 施工完工日期 */
      end_time: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 完成状态 */
      finish_status?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseSectionMethod
   * @summary 添加标段工法
   * @request POST:/dteqms/addBaseSectionMethod
   * @secure
   */
  export namespace AddBaseSectionMethod {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标段 */
      project_id: string;
      /** 工法类型 */
      method_type: string;
      /** 施工组织设计 */
      design?: string;
      /** 详细施工方案 */
      scheme?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseRole
   * @summary 添加角色
   * @request POST:/dteqms/addBaseRole
   * @secure
   */
  export namespace AddBaseRole {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 角色名称 */
      name: string;
      /** 描述 */
      memo: string;
      /** 单位名称 */
      dept_id: string;
      /** 角色授权 */
      grants: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 排序 */
      sort?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name AddBaseRoleDeptType
   * @summary 添加用户角色关系
   * @request POST:/dteqms/addBaseRoleDeptType
   * @secure
   */
  export namespace AddBaseRoleDeptType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 名称 */
      name?: string;
      /**
       * 单位类型
       * @format int32
       */
      dept_type?: number;
      /** 权限 */
      grants?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseRegion
   * @summary 添加区域
   * @request POST:/dteqms/addBaseRegion
   * @secure
   */
  export namespace AddBaseRegion {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 地区名称 */
      name: string;
      /** 描述 */
      remark?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 父ID */
      pid?: string;
      /** 排序 */
      sort?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseProject7
   * @summary 添加分项
   * @request POST:/dteqms/addBaseProject7
   * @secure
   */
  export namespace AddBaseProject7 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseProject6
   * @summary 添加标段
   * @request POST:/dteqms/addBaseProject6
   * @secure
   */
  export namespace AddBaseProject6 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** 控制点文件 */
      ctrl_file?: string;
      /** 定位点坐标(经度,纬度) */
      fixed_position?: string;
      /** 专职安全管理人员数量 */
      safety_number?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
      /** 层级 */
      level?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseProject5
   * @summary 添加监理标段
   * @request POST:/dteqms/addBaseProject5
   * @secure
   */
  export namespace AddBaseProject5 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 排序 */
      sort?: string;
      /** 名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 结束时间 */
      end_time?: string;
      /** 开工时间 */
      start_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** 专职安全管理人员数量 */
      safety_number?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseProject4
   * @summary 添加分部
   * @request POST:/dteqms/addBaseProject4
   * @secure
   */
  export namespace AddBaseProject4 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 分部名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseProject3
   * @summary 添加项目
   * @request POST:/dteqms/addBaseProject3
   * @secure
   */
  export namespace AddBaseProject3 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 项目名称 */
      name?: string;
      /** 编码 */
      code?: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 定位点坐标(经度,纬度) */
      fixed_position?: string;
      /** 控制点文件 */
      ctrl_file?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 排序 */
      sort?: string;
      /** PID */
      pid?: string;
      /** 层级 */
      level?: string;
      /** 地图范围 */
      map_range?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 备注 */
      remark?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags project-controller
   * @name AddBaseProject2
   * @summary 添加工程
   * @request POST:/dteqms/addBaseProject2
   * @secure
   */
  export namespace AddBaseProject2 {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属地区 */
      pid: string;
      /** 工程名称 */
      name: string;
      /** 编码 */
      code: string;
      /** 所属机场 */
      airport?: string;
      /** 开工时间 */
      start_time?: string;
      /** 结束时间 */
      end_time?: string;
      /** 图片 */
      img?: string;
      /** 描述 */
      description?: string;
      /** 附件 */
      attach?: string;
      /** 工程状态 */
      finish_status?: string;
      /** 地图范围 */
      map_range?: string;
      /** 专职安全管理人员数量 */
      safety_number?: string;
      /** 控制点文件 */
      ctrl_file?: string;
      /** 定位点坐标(经纬度) */
      fixed_position?: string;
      /** 备注 */
      remark?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseNotice
   * @summary 添加系统通知
   * @request POST:/dteqms/addBaseNotice
   * @secure
   */
  export namespace AddBaseNotice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 用户ID */
      uid?: string;
      /** 会议ID */
      mid?: string;
      /** 处理状态 */
      deal_state?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseMethodType
   * @summary 添加工法类型
   * @request POST:/dteqms/addBaseMethodType
   * @secure
   */
  export namespace AddBaseMethodType {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 工法名称 */
      name: string;
      /** 描述 */
      remark?: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 排序 */
      sort?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddBaseFunctUserCommon
   * @summary 添加用户常用功能配置
   * @request POST:/dteqms/addBaseFunctUserCommon
   * @secure
   */
  export namespace AddBaseFunctUserCommon {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 功能 */
      funct_id: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 创建时间 */
      create_time?: string;
      /** 更新时间 */
      update_time?: string;
      /** 用户 */
      user_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags dept-controller
   * @name AddBaseDept
   * @summary 添加单位
   * @request POST:/dteqms/addBaseDept
   * @secure
   */
  export namespace AddBaseDept {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 所属地区 */
      region_id: string;
      /** 单位类型 */
      type: string;
      /** 单位名称 */
      name: string;
      /** 附件 */
      attach?: string;
      /** 描述 */
      remark?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * @description 最后修改时间:2024-10-31 10:59:15
   * @tags dteqms-1732697495363
   * @name AddAdvice
   * @summary 添加意见征求
   * @request POST:/dteqms/addAdvice
   * @secure
   */
  export namespace AddAdvice {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 标题 */
      title: string;
      /** 内容 */
      content: string;
      /** 截止时间 */
      end_time?: string;
      /** 可见范围 */
      visibility: string;
      /** 所属地区 */
      visibility_region?: string;
      /** 所属项目 */
      visibility_project?: string;
      /** 附件 */
      attachment?: string;
      /** 数据状态 */
      data_state: string;
      /** 创建用户ID */
      create_user?: string;
      /** 更新用户ID */
      update_user?: string;
      /** 审核状态 */
      check_state: string;
      /** 审核用户id */
      check_uid?: string;
      /** 审核建议 */
      check_advise?: string;
      /** 所属工程 */
      project_id?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name AddAdviceRecord
   * @summary 添加意见征求评价
   * @request POST:/dteqms/addAdviceRecord
   * @secure
   */
  export namespace AddAdviceRecord {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 建议内容 */
      content: string;
      /**
       * 意见征求ID
       * @format int32
       */
      advice_id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
  /**
   * No description
   * @tags eqms-controller
   * @name AddAddressBook
   * @summary 添加通讯录
   * @request POST:/dteqms/addAddressBook
   * @secure
   */
  export namespace AddAddressBook {
    export type RequestParams = {};
    export type RequestQuery = {
      /** 姓名 */
      name?: string;
      /** 手机号 */
      phone?: string;
      /** 职务 */
      duties?: string;
      /**
       * 所属地区
       * @format int32
       */
      region_id?: number;
      /**
       * 单位
       * @format int32
       */
      dept_id?: number;
      /**
       * 项目id
       * @format int32
       */
      project_id3?: number;
      /**
       * 监理标段id
       * @format int32
       */
      project_id5?: number;
      /**
       * 标段id
       * @format int32
       */
      project_id6?: number;
      /**
       * 单位类型
       * @format int32
       */
      type?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JSONObject;
  }
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://146.56.235.235:8881" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { "Content-Type": type }
          : { "Content-Type": "application/x-www-form-urlencoded" }),
      },
      params: type && type !== ContentType.FormData ? query : {},
      responseType: responseFormat,
      data: type && type === ContentType.FormData ? body : query,
      url: path,
    });
  };
}

/**
 * @title 壮大瑞业接口调试
 * @baseUrl http://146.56.235.235:8881
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  dtsystem = {
    /**
     * No description
     *
     * @tags c
     * @name UploadMinio
     * @summary 上传视频/图片
     * @request POST:/dtsystem/upload_minio
     * @secure
     */
    uploadMinio: (
      data: {
        /**
         * 文件
         * @format binary
         */
        filePart: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/upload_minio`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags a
     * @name ReloadConfigure
     * @summary 刷新接口及以功能
     * @request POST:/dtsystem/reloadConfigure
     * @secure
     */
    reloadConfigure: (params: RequestParams = {}) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/reloadConfigure`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags c
     * @name LinkUpload
     * @summary 根据链接上传视频/图片
     * @request POST:/dtsystem/link_upload
     * @secure
     */
    linkUpload: (
      query: {
        /** 文件路径 */
        fileUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/link_upload`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags a
     * @name FuncConfigure
     * @summary 获取功能页面配置
     * @request POST:/dtsystem/funcConfigure
     * @secure
     */
    funcConfigure: (
      query: {
        /** 模块名称 */
        module: string;
        /** 功能名称 */
        funcname: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/funcConfigure`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags d
     * @name DynamicInvoke
     * @summary 动态调用模块接口
     * @request POST:/dtsystem/dynamicInvoke
     * @secure
     */
    dynamicInvoke: (
      query: {
        /** 模块名称 */
        module____: string;
        /** 接口名称 */
        apiname____: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/dynamicInvoke`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags a
     * @name ApiConfigure
     * @summary 获取接口显示配置
     * @request POST:/dtsystem/apiConfigure
     * @secure
     */
    apiConfigure: (
      query: {
        /** 模块名称 */
        module: string;
        /** 接口名称 */
        apiname: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/apiConfigure`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags c
     * @name Minio
     * @summary 动态调用minio
     * @request GET:/dtsystem/minio/**
     * @secure
     */
    minio: (params: RequestParams = {}) =>
      this.request<DataBuffer[], any>({
        path: `/dtsystem/minio/**`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b
     * @name UserLogout
     * @summary 用户退出登录
     * @request POST:/dtsystem/userLogout
     * @secure
     */
    userLogout: (
      query?: {
        /** 动态名称 */
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/userLogout`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b
     * @name UserLogin
     * @summary 用户登录鉴权
     * @request POST:/dtsystem/userLogin
     * @secure
     */
    userLogin: (
      query?: {
        /** 动态名称 */
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/userLogin`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name SortOutput
     * @summary 排序输出参数
     * @request POST:/dtsystem/sortOutput
     * @secure
     */
    sortOutput: (
      query?: {
        /** 出参ID */
        id?: string;
        /** 出参名称 */
        name?: string;
        /** 上移下移 */
        upper?: string;
        /** 出参描述 */
        memo?: string;
        /** 出参版本 */
        version?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 组件模板 */
        template?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 接口ID */
        api_id?: string;
        /** 列表宽度 */
        width?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/sortOutput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name SortOutputM
     * @summary 排序输出参数(移动端)
     * @request POST:/dtsystem/sortOutputM
     * @secure
     */
    sortOutputM: (
      query?: {
        /** 出参ID */
        id?: string;
        /** 上移下移 */
        upper?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/sortOutputM`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name SortInput
     * @summary 排序输入参数
     * @request POST:/dtsystem/sortInput
     * @secure
     */
    sortInput: (
      query?: {
        /** 上移下移 */
        upper?: string;
        /** 入参名称 */
        name?: string;
        /** 入参ID */
        id?: string;
        /** 入参描述 */
        memo?: string;
        /** 入参版本 */
        version?: string;
        /** 默认取值 */
        defvalue?: string;
        /** 是否必填 */
        isnotnull?: string;
        /** 验证规则 */
        validate?: string;
        /** 是否只读 */
        readonly?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 组件模板 */
        template?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 接口ID */
        api_id?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/sortInput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name SortInputM
     * @summary 排序输入参数(移动端)
     * @request POST:/dtsystem/sortInputM
     * @secure
     */
    sortInputM: (
      query?: {
        /** ID */
        id?: string;
        /** 上移下移 */
        upper?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/sortInputM`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name SortGroup
     * @summary 排序系统功能分组
     * @request POST:/dtsystem/sortGroup
     * @secure
     */
    sortGroup: (
      query?: {
        /** 系统所属分组ID */
        id?: string;
        /** 上移下移 */
        upper?: string;
        /** 名称 */
        "group_name "?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/sortGroup`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name SortFunct
     * @summary 排序功能页面
     * @request POST:/dtsystem/sortFunct
     * @secure
     */
    sortFunct: (
      query?: {
        /** 功能ID */
        id?: string;
        /** 上下移动 */
        upper?: string;
        /** 名称 */
        name?: string;
        /** 描述 */
        memo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/sortFunct`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sys-config-controller
     * @name ReloadAllConfigure
     * @summary 刷新接口及以功能
     * @request POST:/dtsystem/reloadAllConfigure
     * @secure
     */
    reloadAllConfigure: (params: RequestParams = {}) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/reloadAllConfigure`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name QuoteFunct
     * @summary 查询引用页面
     * @request POST:/dtsystem/quoteFunct
     * @secure
     */
    quoteFunct: (
      query?: {
        /** 模块id */
        module_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/quoteFunct`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags schema-controller
     * @name PreviewSql
     * @summary 根据数据表格预览SQL
     * @request POST:/dtsystem/previewSQL
     * @secure
     */
    previewSql: (
      query: {
        /** 表格名称 */
        table: string;
        /**
         * 生成类型(1插入2删除3修改4查询5详情)
         * @format int32
         */
        apitype: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/previewSQL`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags login-controller
     * @name Login
     * @summary 登录
     * @request POST:/dtsystem/login
     * @secure
     */
    login: (
      query: {
        /** 登录名 */
        name: string;
        /** 密码 */
        pass?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/login`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListValidation
     * @summary 查询表单效验规则
     * @request POST:/dtsystem/listValidation
     * @secure
     */
    listValidation: (
      query?: {
        /** 表单类型 */
        type?: string;
        /** 描述 */
        memo?: string;
        /** 错误提示信息 */
        msg?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 正则 */
        content?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listValidation`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListUser
     * @summary 查询系统用户配置
     * @request POST:/dtsystem/listUser
     * @secure
     */
    listUser: (
      query?: {
        /** 用户姓名 */
        name?: string;
        /** 所属角色 */
        role?: string;
        /** 真实姓名 */
        rname?: string;
        /** 身份证号 */
        cardid?: string;
        /** 手机号 */
        phone?: string;
        /** 用户ID */
        id?: string;
        /** 用户密码 */
        pass?: string;
        /** 用户授权 */
        grants?: string;
        /** 随机秘钥 */
        sailt?: string;
        /** 当前状态 */
        status?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListUserById
     * @summary 根据id拿名称
     * @request POST:/dtsystem/listUserById
     * @secure
     */
    listUserById: (
      query?: {
        /** 真实姓名 */
        rname?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listUserById`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListTemplate
     * @summary 查询系统组件配置
     * @request POST:/dtsystem/listTemplate
     * @secure
     */
    listTemplate: (
      query?: {
        /** 组件名称 */
        name?: string;
        /** 组件类型 */
        type?: string;
        /** 组件ID */
        id?: string;
        /** 绑定组件 */
        bind?: string;
        /** 组件配置 */
        config?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListStudys
     * @summary studyList
     * @request POST:/dtsystem/listStudys
     * @secure
     */
    listStudys: (
      query?: {
        /** id */
        id?: string;
        /** 名称 */
        name?: string;
        /** 年龄 */
        age?: string;
        /** 性别 */
        sex?: string;
        /** 手机号 */
        phone?: string;
        /** 星座 */
        startsign?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listStudys`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sys-config-controller
     * @name ListSql
     * @summary 根据模块和接口名查sql
     * @request POST:/dtsystem/listSql
     * @secure
     */
    listSql: (params: RequestParams = {}) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/listSql`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListSms
     * @summary 查询短信记录
     * @request POST:/dtsystem/listSms
     * @secure
     */
    listSms: (
      query?: {
        /** ID */
        id?: string;
        /** 发送状态 */
        status?: string;
        /** 短信类型 */
        type?: string;
        /** 手机号 */
        phone?: string;
        /** 短信内容 */
        sms?: string;
        /** 发送时间 */
        send_time?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 短信参数(json) */
        para?: string;
        /** 时效性(分钟) */
        time?: string;
        /** 回执时间 */
        receipt_time?: string;
        /** 回复时间 */
        reply_time?: string;
        /** 回复内容 */
        reply_content?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listSms`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListSmsTemplate
     * @summary 查询短信模板
     * @request POST:/dtsystem/listSmsTemplate
     * @secure
     */
    listSmsTemplate: (
      query?: {
        /** 审核状态 */
        status?: string;
        /** 模板类型 */
        type?: string;
        /** 短信模板ID */
        template_id?: string;
        /** 短信模板内容 */
        template?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listSmsTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListRole
     * @summary 查询系统角色配置
     * @request POST:/dtsystem/listRole
     * @secure
     */
    listRole: (
      query?: {
        /** 角色名称 */
        name?: string;
        /** 角色描述 */
        memo?: string;
        /** 角色授权 */
        grants?: string;
        /** 角色ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListQuoteFunct
     * @summary 返回页面数据
     * @request POST:/dtsystem/listQuoteFunct
     * @secure
     */
    listQuoteFunct: (
      query?: {
        /** 模块id */
        module_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listQuoteFunct`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListProject
     * @summary 查询系统项目配置
     * @request POST:/dtsystem/listProject
     * @secure
     */
    listProject: (
      query?: {
        /** 项目名称 */
        name?: string;
        /** 项目描述 */
        memo?: string;
        /** 公司名称 */
        company?: string;
        /** 项目ID */
        id?: string;
        /** 项目人员 */
        pro_user?: string;
        /** 项目经理 */
        manager?: string;
        /** 项目地址 */
        address?: string;
        /** 项目版本 */
        pro_version?: string;
        /** 产品设计背景 */
        pro_background?: string;
        /** 总体需求 */
        total_demand?: string;
        /** 业务功能树 */
        function_tree?: string;
        /** 业务角色描述 */
        role_describe?: string;
        /** 界面设计原则内容 */
        des_principle?: string;
        /** 界面设计要求内容 */
        ask_principle?: string;
        /** 性能要求内容 */
        performance?: string;
        /** 其他需求 */
        other_ask?: string;
        /** 附件 */
        annex?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListProcess
     * @summary 查询流程模板
     * @request POST:/dtsystem/listProcess
     * @secure
     */
    listProcess: (
      query?: {
        /** 流程模板名称 */
        name?: string;
        /** 流程模板描述 */
        describe?: string;
        /** 流程模板ID */
        id?: string;
        /** 流程模板内容 */
        content?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listProcess`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListProcessApi
     * @summary 查询接口流程模板表
     * @request POST:/dtsystem/listProcessApi
     * @secure
     */
    listProcessApi: (
      query?: {
        /** 名称 */
        name?: string;
        /** 描述 */
        describe?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 内容 */
        content?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listProcessApi`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListOutput
     * @summary 查询接口输出参数
     * @request POST:/dtsystem/listOutput
     * @secure
     */
    listOutput: (
      query?: {
        /** 出参ID */
        id?: string;
        /** 出参名称 */
        name?: string;
        /** 出参描述 */
        memo?: string;
        /** 出参版本 */
        version?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 组件模板 */
        template?: string;
        /** 接口ID */
        api_id?: string;
        /** 列表宽度 */
        width?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 客户端类型 */
        client_type?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listOutput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListModule
     * @summary 查询系统功能模块
     * @request POST:/dtsystem/listModule
     * @secure
     */
    listModule: (
      query?: {
        /** 模块名称 */
        name?: string;
        /** 模块描述 */
        memo?: string;
        /** 模块ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listModule`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListManyFunct
     * @summary 根据系统查询多个功能页面
     * @request POST:/dtsystem/listManyFunct
     * @secure
     */
    listManyFunct: (
      query?: {
        /** 接口名称 */
        name?: string;
        /** 接口描述 */
        memo?: string;
        /** 所属模块 */
        module_id?: string;
        /** 执行语句 */
        sqltext?: string;
        /** 执行类型(1框架2模块) */
        ivktype?: string;
        /** 接口类型(1数据2混合) */
        apitype?: string;
        /** 本地方法 */
        ivkfunc?: string;
        /** 创建者 */
        create_user?: string;
        /** 流程语句 */
        scptext?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listManyFunct`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListJobTemplate
     * @summary 查询系统作业模板
     * @request POST:/dtsystem/listJobTemplate
     * @secure
     */
    listJobTemplate: (
      query?: {
        /** id */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 模板名称 */
        name?: string;
        /** 所属模块 */
        module_id?: string;
        /** 接口id */
        api_id?: string;
        /** 内容 */
        content?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listJobTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListInput
     * @summary 查询接口输入参数
     * @request POST:/dtsystem/listInput
     * @secure
     */
    listInput: (
      query?: {
        /** 入参ID */
        id?: string;
        /** 入参名称 */
        name?: string;
        /** 入参描述 */
        memo?: string;
        /** 入参版本 */
        version?: string;
        /** 默认取值 */
        defvalue?: string;
        /** 是否必填 */
        isnotnull?: string;
        /** 验证规则 */
        validate?: string;
        /** 是否只读 */
        readonly?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 组件模板 */
        template?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 接口ID */
        api_id?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 客户端类型 */
        client_type?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listInput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListGroup
     * @summary 查询系统功能分组
     * @request POST:/dtsystem/listGroup
     * @secure
     */
    listGroup: (
      query?: {
        /** 组名称 */
        group_name?: string;
        /** 所属模块 */
        module_id?: string;
        /** 系统所属分组ID */
        id?: string;
        /** 英文名称 */
        english_name?: string;
        /** 排序 */
        sort?: string;
        /** 图标 */
        icon?: string;
        /** 默认图标 */
        default_icon?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listGroup`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListFunct
     * @summary 查询系统功能页面
     * @request POST:/dtsystem/listFunct
     * @secure
     */
    listFunct: (
      query?: {
        /** 功能名称 */
        name?: string;
        /** 功能描述 */
        memo?: string;
        /** 所属模块 */
        module_id?: string;
        /** 所属分组 */
        group_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listFunct`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListErrcode
     * @summary 查询错误编码配置
     * @request POST:/dtsystem/listErrcode
     * @secure
     */
    listErrcode: (
      query?: {
        /** 错误描述 */
        errdesc?: string;
        /** 错误级别 */
        errlevel?: string;
        /** 错误类型 */
        errtype?: string;
        /** 所属模块 */
        module_id?: string;
        /** 错误ID */
        id?: string;
        /** 错误编码 */
        errcode?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listErrcode`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListEnviron
     * @summary 查询项目环境配置
     * @request POST:/dtsystem/listEnviron
     * @secure
     */
    listEnviron: (
      query?: {
        /** 环境名称 */
        name?: string;
        /** 环境描述 */
        memo?: string;
        /** 所属项目 */
        project_id?: string;
        /** 环境ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listEnviron`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListDialogBox
     * @summary 查询弹框组件表
     * @request POST:/dtsystem/listDialogBox
     * @secure
     */
    listDialogBox: (
      query?: {
        /** 标题 */
        title?: string;
        /** 组件名称 */
        name?: string;
        /** 所属模块 */
        module_id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 上传文件 */
        upload_file?: string;
        /** 组件模板 */
        template?: string;
        /** 功能流程 */
        workflow?: string;
        /** 功能权限 */
        grants?: string;
        /** 功能配置 */
        configure?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listDialogBox`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListDefect
     * @summary 查询缺陷记录
     * @request POST:/dtsystem/listDefect
     * @secure
     */
    listDefect: (
      query?: {
        /** 所属模块 */
        from_module?: string;
        /** 所属页面 */
        funct_id?: string;
        /** 状态 */
        status?: string;
        /** 优先级 */
        priority?: string;
        /** 严重程度 */
        severity?: string;
        /** 指派给 */
        assign?: string;
        /** 解决方案 */
        solution?: string;
        /** 缺陷记录ID */
        id?: string;
        /** 标题 */
        title?: string;
        /** 描述 */
        describe?: string;
        /** 重现步骤 */
        step_reproduction?: string;
        /** 附件 */
        annex?: string;
        /** 视频 */
        vedio_path?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listDefect`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListDefectBaseDict
     * @summary 查询缺陷记录基础字典
     * @request POST:/dtsystem/listDefectBaseDict
     * @secure
     */
    listDefectBaseDict: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 模块ID */
        module?: string;
        /** 分组ID */
        group_id?: string;
        /** 分组名称 */
        group_name?: string;
        /** 枚举ID */
        item_id?: string;
        /** 枚举名称 */
        item_name?: string;
        /** 排序 */
        sort?: string;
        /** 备注 */
        remark?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listDefectBaseDict`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListBaseDict
     * @summary 查询基础字典
     * @request POST:/dtsystem/listBaseDict
     * @secure
     */
    listBaseDict: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 模块ID */
        module?: string;
        /** 分组ID */
        group_id?: string;
        /** 分组名称 */
        group_name?: string;
        /** 枚举ID */
        item_id?: string;
        /** 枚举名称 */
        item_name?: string;
        /** 排序 */
        sort?: string;
        /** 备注 */
        remark?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listBaseDict`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name ListApi
     * @summary 查询系统数据接口
     * @request POST:/dtsystem/listApi
     * @secure
     */
    listApi: (
      query?: {
        /** 接口名称 */
        name?: string;
        /** 接口描述 */
        memo?: string;
        /** 所属模块 */
        module_id?: string;
        /** 执行类型 */
        ivktype?: string;
        /** dffd */
        dfsfd?: string;
        /** 接口ID */
        id?: string;
        /** 执行语句 */
        sqltext?: string;
        /** 接口类型 */
        apitype?: string;
        /** 本地方法 */
        ivkfunc?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 流程语句 */
        scptext?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/listApi`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoValidation
     * @summary 表单效验规则详情
     * @request POST:/dtsystem/infoValidation
     * @secure
     */
    infoValidation: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 表单类型ID(dtsystem_template) */
        type?: string;
        /** 正则表达式内容 */
        content?: string;
        /** 描述 */
        memo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoValidation`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoUser
     * @summary 详情系统用户配置
     * @request POST:/dtsystem/infoUser
     * @secure
     */
    infoUser: (
      query?: {
        /** 用户ID */
        id?: string;
        /** 用户姓名 */
        name?: string;
        /** 用户密码 */
        pass?: string;
        /** 所属角色 */
        role?: string;
        /** 用户授权 */
        grants?: string;
        /** 手机号码 */
        phone?: string;
        /** 随机秘钥 */
        sailt?: string;
        /** 真实姓名 */
        rname?: string;
        /** 身份证号 */
        cardid?: string;
        /** 当前状态 */
        status?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoTemplate
     * @summary 详情系统组件配置
     * @request POST:/dtsystem/infoTemplate
     * @secure
     */
    infoTemplate: (
      query?: {
        /** 组件ID */
        id?: string;
        /** 组件名称 */
        name?: string;
        /** 组件类型 */
        type?: string;
        /** 绑定组件 */
        bind?: string;
        /** 组件配置 */
        config?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoStudy
     * @summary studyRowline
     * @request POST:/dtsystem/infoStudy
     * @secure
     */
    infoStudy: (
      query?: {
        /** id */
        id?: string;
        /** 名称 */
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoStudy`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoSms
     * @summary 短信记录详情
     * @request POST:/dtsystem/infoSms
     * @secure
     */
    infoSms: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 发送状态(0:未发送;1:失败;2:已发送;3:已回执;4:已回复) */
        status?: string;
        /** 短信类型(1:验证码;2:通知) */
        type?: string;
        /** 手机号 */
        phone?: string;
        /** 短信内容 */
        sms?: string;
        /** 短信参数(json) */
        para?: string;
        /** 时效性(分钟) */
        time?: string;
        /** 发送时间 */
        send_time?: string;
        /** 回执时间 */
        receipt_time?: string;
        /** 回复时间 */
        reply_time?: string;
        /** 回复内容 */
        reply_content?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoSms`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoSmsTemplate
     * @summary 短信模板详情
     * @request POST:/dtsystem/infoSmsTemplate
     * @secure
     */
    infoSmsTemplate: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核状态(0:未审核;1:未通过;2:已通过) */
        status?: string;
        /** 模板类型(1:验证码;2:通知) */
        type?: string;
        /** 短信模板ID */
        template_id?: string;
        /** 短信模板内容 */
        template?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoSmsTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoRole
     * @summary 详情系统角色配置
     * @request POST:/dtsystem/infoRole
     * @secure
     */
    infoRole: (
      query?: {
        /** 角色名称 */
        name?: string;
        /** 角色描述 */
        memo?: string;
        /** 角色授权 */
        grants?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 角色ID */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoProject
     * @summary 详情系统项目配置
     * @request POST:/dtsystem/infoProject
     * @secure
     */
    infoProject: (
      query?: {
        /** 项目ID */
        id?: string;
        /** 项目名称 */
        name?: string;
        /** 项目描述 */
        memo?: string;
        /** 项目人员 */
        pro_user?: string;
        /** 项目经理 */
        manager?: string;
        /** 项目地址 */
        address?: string;
        /** 项目版本 */
        pro_version?: string;
        /** 公司名称 */
        company?: string;
        /** 总体需求 */
        total_demand?: string;
        /** 产品设计背景 */
        pro_background?: string;
        /** 业务功能树 */
        function_tree?: string;
        /** 业务角色描述 */
        role_describe?: string;
        /** 界面设计原则内容 */
        des_principle?: string;
        /** 界面设计要求内容 */
        ask_principle?: string;
        /** 性能要求内容 */
        performance?: string;
        /** 其他需求 */
        other_ask?: string;
        /** 附件 */
        annex?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoProcess
     * @summary 详情流程模板
     * @request POST:/dtsystem/infoProcess
     * @secure
     */
    infoProcess: (
      query?: {
        /** 流程模板ID */
        id?: string;
        /** 流程模板名称 */
        name?: string;
        /** 流程模板描述 */
        describe?: string;
        /** 流程模板内容 */
        content?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoProcess`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoProcessApi
     * @summary 接口流程模板表详情
     * @request POST:/dtsystem/infoProcessApi
     * @secure
     */
    infoProcessApi: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 名称 */
        name?: string;
        /** 内容 */
        content?: string;
        /** 描述 */
        describe?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoProcessApi`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoOutput
     * @summary 详情接口输出参数
     * @request POST:/dtsystem/infoOutput
     * @secure
     */
    infoOutput: (
      query?: {
        /** 出参ID */
        id?: string;
        /** 出参名称 */
        name?: string;
        /** 出参描述 */
        memo?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 出参版本 */
        version?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 组件模板 */
        template?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 接口ID */
        api_id?: string;
        /** 列表宽度 */
        width?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoOutput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoModule
     * @summary 详情系统功能模块
     * @request POST:/dtsystem/infoModule
     * @secure
     */
    infoModule: (
      query?: {
        /** 模块ID */
        id?: string;
        /** 模块名称 */
        name?: string;
        /** 模块描述 */
        memo?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoModule`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoJobTemplate
     * @summary 系统作业模板详情
     * @request POST:/dtsystem/infoJobTemplate
     * @secure
     */
    infoJobTemplate: (
      query?: {
        /** id */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 模板名称 */
        name?: string;
        /** 所属模块 */
        module_id?: string;
        /** 接口id */
        api_id?: string;
        /** 内容 */
        content?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoJobTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoInput
     * @summary 详情接口输入参数
     * @request POST:/dtsystem/infoInput
     * @secure
     */
    infoInput: (
      query?: {
        /** 入参ID */
        id?: string;
        /** 入参名称 */
        name?: string;
        /** 入参描述 */
        memo?: string;
        /** 入参版本 */
        version?: string;
        /** 默认取值 */
        defvalue?: string;
        /** 验证规则 */
        validate?: string;
        /** 是否必填 */
        isnotnull?: string;
        /** 是否只读 */
        readonly?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 组件模板 */
        template?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 接口ID */
        api_id?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoInput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoGroup
     * @summary 详情系统功能分组
     * @request POST:/dtsystem/infoGroup
     * @secure
     */
    infoGroup: (
      query?: {
        /** 系统所属分组ID */
        id?: string;
        /** 所属组名称 */
        group_name?: string;
        /** 英文名称 */
        english_name?: string;
        /** 所属模块id */
        module_id?: string;
        /** 排序 */
        sort?: string;
        /** 图标 */
        icon?: string;
        /** 默认图标 */
        default_icon?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoGroup`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoFunct
     * @summary 详情系统功能页面
     * @request POST:/dtsystem/infoFunct
     * @secure
     */
    infoFunct: (
      query?: {
        /** 功能名称 */
        name?: string;
        /** 功能描述 */
        memo?: string;
        /** 功能配置 */
        configure?: string;
        /** 所属模块 */
        module_id?: string;
        /** 组件模板 */
        template?: string;
        /** 功能流程 */
        workflow?: string;
        /** 功能权限 */
        grants?: string;
        /** 功能ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoFunct`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoErrcode
     * @summary 详情错误编码配置
     * @request POST:/dtsystem/infoErrcode
     * @secure
     */
    infoErrcode: (
      query?: {
        /** 错误ID */
        id?: string;
        /** 错误编码 */
        errcode?: string;
        /** 错误描述 */
        errdesc?: string;
        /** 错误级别(1普通2严重） */
        errlevel?: string;
        /** 错误类型(1系统2业务） */
        errtype?: string;
        /** 所属模块 */
        module_id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoErrcode`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoEnviron
     * @summary 详情项目环境配置
     * @request POST:/dtsystem/infoEnviron
     * @secure
     */
    infoEnviron: (
      query?: {
        /** 环境ID */
        id?: string;
        /** 环境名称 */
        name?: string;
        /** 环境描述 */
        memo?: string;
        /** 所属项目 */
        project_id?: string;
        /** 创建者 */
        create_user?: string;
        /** 更新时间 */
        update_time?: string;
        /** 创建时间 */
        create_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoEnviron`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoDialogBox
     * @summary 弹框组件表详情
     * @request POST:/dtsystem/infoDialogBox
     * @secure
     */
    infoDialogBox: (
      query?: {
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 组件名称 */
        name?: string;
        /** 上传文件 */
        upload_file?: string;
        /** 所属模块 */
        module_id?: string;
        /** 组件模板 */
        template?: string;
        /** 功能流程 */
        workflow?: string;
        /** 功能权限 */
        grants?: string;
        /** 功能配置 */
        configure?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoDialogBox`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoDefect
     * @summary 详情缺陷记录
     * @request POST:/dtsystem/infoDefect
     * @secure
     */
    infoDefect: (
      query?: {
        /** 所属模块(1-模块2-子模块) */
        from_module?: string;
        /** 状态(新提交(默认)) */
        status?: string;
        /** 优先级(1-最高2-较高3-普通(默认)4-较低5-最低) */
        priority?: string;
        /** 严重程度(1-致命2-严重3-一般(默认)4-建议) */
        severity?: string;
        /** 指派给 */
        assign?: string;
        /** 标题 */
        title?: string;
        /** 描述 */
        describe?: string;
        /** 附件 */
        annex?: string;
        /** 解决方案(1-已修复2-转需求) */
        solution?: string;
        /** 视频 */
        vedio_path?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 缺陷记录ID */
        id?: string;
        /** 重现步骤 */
        step_reproduction?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoDefect`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name InfoApi
     * @summary 详情系统数据接口
     * @request POST:/dtsystem/infoApi
     * @secure
     */
    infoApi: (
      query?: {
        /** 接口ID */
        id?: string;
        /** 接口名称 */
        name?: string;
        /** 接口描述 */
        memo?: string;
        /** 所属模块 */
        module_id?: string;
        /** 执行语句 */
        sqltext?: string;
        /** 执行类型(1框架2模块) */
        ivktype?: string;
        /** 接口类型(1数据2混合) */
        apitype?: string;
        /** 本地方法 */
        ivkfunc?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 流程语句 */
        scptext?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/infoApi`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags other-controller
     * @name GetFunctDate
     * @summary 查询项目下的页面
     * @request POST:/dtsystem/get_funct_date
     * @secure
     */
    getFunctDate: (
      query: {
        /**
         * 项目id
         * @format int32
         */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/get_funct_date`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags other-controller
     * @name GenerateManyApi
     * @summary 批量生成接口
     * @request POST:/dtsystem/generate_many_api
     * @secure
     */
    generateManyApi: (
      query: {
        /** 模块 */
        module: string;
        /** 表名称 */
        table?: string;
        /** 生成类型(1插入2删除3修改4查询5详情6其他) */
        apitype: string;
        /** 接口名称 */
        apiname?: string;
        /** 接口描述 */
        apimemo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/generate_many_api`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags schema-controller
     * @name GenerateApi
     * @summary 根据数据表格生成接口
     * @request POST:/dtsystem/generateAPI
     * @secure
     */
    generateApi: (
      query: {
        /** 表格名称 */
        table: string;
        /**
         * 生成类型(1插入2删除3修改4查询5详情)
         * @format int32
         */
        apitype: number;
        /** 接口名称 */
        apiname?: string;
        /** 接口描述 */
        apimemo: string;
        /** 模块名称 */
        module?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/generateAPI`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags product-controller
     * @name ExportSimplifyDoc
     * @summary 导出需求概要描述
     * @request POST:/dtsystem/exportSimplifyDoc
     * @secure
     */
    exportSimplifyDoc: (
      query?: {
        /** 模块名称列表 */
        modules?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/exportSimplifyDoc`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags product-controller
     * @name ExportRequirementDoc
     * @summary 导出详细需求文档
     * @request POST:/dtsystem/exportRequirementDoc
     * @secure
     */
    exportRequirementDoc: (
      query?: {
        /** 模块名称列表 */
        modules?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/exportRequirementDoc`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags product-controller
     * @name ExportProductRelease
     * @summary 导出项目部署文件
     * @request POST:/dtsystem/exportProductRelease
     * @secure
     */
    exportProductRelease: (
      query?: {
        /** 模块名称列表 */
        modules?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/exportProductRelease`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags product-controller
     * @name ExportInterfaceDoc
     * @summary 导出接口设计文档
     * @request POST:/dtsystem/exportInterfaceDoc
     * @secure
     */
    exportInterfaceDoc: (
      query?: {
        /** 模块名称列表 */
        modules?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/exportInterfaceDoc`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags product-controller
     * @name ExportDatabaseDoc
     * @summary 导出数据结构设计
     * @request POST:/dtsystem/exportDatabaseDoc
     * @secure
     */
    exportDatabaseDoc: (
      query?: {
        /** 模块名称列表 */
        modules?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/exportDatabaseDoc`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags other-controller
     * @name ExpRequirements
     * @summary 需求文档导出
     * @request POST:/dtsystem/exp_requirements
     * @secure
     */
    expRequirements: (
      query: {
        /**
         * 项目id
         * @format int32
         */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/exp_requirements`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags schema-controller
     * @name ExecuteSql
     * @summary 根据参数动态执行SQL
     * @request POST:/dtsystem/executeSQL
     * @secure
     */
    executeSql: (
      query: {
        /** 执行语句 */
        sql: string;
        /** 动态参数 */
        param?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/executeSQL`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditValidation
     * @summary 编辑表单效验规则
     * @request POST:/dtsystem/editValidation
     * @secure
     */
    editValidation: (
      query?: {
        /** 表单类型 */
        type?: string;
        /** 描述 */
        memo?: string;
        /** 正则 */
        content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editValidation`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags login-controller
     * @name EditUser
     * @summary 编辑系统用户配置
     * @request POST:/dtsystem/editUser
     * @secure
     */
    editUser: (
      query: {
        /**
         * 用户id
         * @format int32
         */
        id: number;
        /** 用户名 */
        name?: string;
        /** 密码 */
        pass?: string;
        /**
         * 所属角色
         * @format int32
         */
        role?: number;
        /** 用户授权 */
        grants?: string;
        /** 手机号码 */
        phone?: string;
        /** 真实姓名 */
        rname?: string;
        /** 身份证号码 */
        cardid?: string;
        /**
         * 用户状态
         * @format int32
         */
        status?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/editUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditTemplate
     * @summary 编辑系统组件配置
     * @request POST:/dtsystem/editTemplate
     * @secure
     */
    editTemplate: (
      query?: {
        /** 组件名称 */
        name?: string;
        /** 组件类型 */
        type?: string;
        /** 绑定组件 */
        bind?: string;
        /** 组件配置 */
        config?: string;
        /** 组件ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditStudy
     * @summary studyUpdate
     * @request POST:/dtsystem/editStudy
     * @secure
     */
    editStudy: (
      query?: {
        /** id */
        id?: string;
        /** 名称 */
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editStudy`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditSms
     * @summary 编辑短信记录
     * @request POST:/dtsystem/editSms
     * @secure
     */
    editSms: (
      query?: {
        /** 发送状态 */
        status?: string;
        /** 短信类型 */
        type?: string;
        /** 手机号 */
        phone?: string;
        /** 短信内容 */
        sms?: string;
        /** 短信参数 */
        para?: string;
        /** 时效性(分钟) */
        time?: string;
        /** 发送时间 */
        send_time?: string;
        /** 回执时间 */
        receipt_time?: string;
        /** 回复时间 */
        reply_time?: string;
        /** 回复内容 */
        reply_content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editSms`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditSmsTemplate
     * @summary 编辑短信模板
     * @request POST:/dtsystem/editSmsTemplate
     * @secure
     */
    editSmsTemplate: (
      query?: {
        /** 审核状态 */
        status?: string;
        /** 模板类型 */
        type?: string;
        /** 短信模板ID */
        template_id?: string;
        /** 短信模板内容 */
        template?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editSmsTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditRole
     * @summary 编辑系统角色配置
     * @request POST:/dtsystem/editRole
     * @secure
     */
    editRole: (
      query?: {
        /** 角色名称 */
        name?: string;
        /** 角色授权 */
        grants?: string;
        /** 角色描述 */
        memo?: string;
        /** 角色ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditProject
     * @summary 编辑系统项目配置
     * @request POST:/dtsystem/editProject
     * @secure
     */
    editProject: (
      query?: {
        /** 项目ID */
        id?: string;
        /** 项目名称 */
        name?: string;
        /** 项目描述 */
        memo?: string;
        /** 项目人员 */
        pro_user?: string;
        /** 项目经理 */
        manager?: string;
        /** 项目地址 */
        address?: string;
        /** 项目版本 */
        pro_version?: string;
        /** 公司名称 */
        company?: string;
        /** 产品设计背景 */
        pro_background?: string;
        /** 总体需求 */
        total_demand?: string;
        /** 业务功能树 */
        function_tree?: string;
        /** 业务角色描述 */
        role_describe?: string;
        /** 界面设计原则内容 */
        des_principle?: string;
        /** 界面设计要求内容 */
        ask_principle?: string;
        /** 性能要求内容 */
        performance?: string;
        /** 其他需求 */
        other_ask?: string;
        /** 附件 */
        annex?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditProcess
     * @summary 编辑流程模板
     * @request POST:/dtsystem/editProcess
     * @secure
     */
    editProcess: (
      query?: {
        /** 流程模板名称 */
        name?: string;
        /** 流程模板描述 */
        describe?: string;
        /** 流程模板内容 */
        content?: string;
        /** 流程模板ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editProcess`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditProcessApi
     * @summary 编辑接口流程模板表
     * @request POST:/dtsystem/editProcessApi
     * @secure
     */
    editProcessApi: (
      query?: {
        /** 名称 */
        name?: string;
        /** 内容 */
        content?: string;
        /** 描述 */
        describe?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editProcessApi`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditOutput
     * @summary 编辑接口输出参数
     * @request POST:/dtsystem/editOutput
     * @secure
     */
    editOutput: (
      query?: {
        /** 出参ID */
        id?: string;
        /** 出参名称 */
        name?: string;
        /** 出参描述 */
        memo?: string;
        /** 出参版本 */
        version?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 组件模板 */
        template?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 接口ID */
        api_id?: string;
        /** 列表宽度 */
        width?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 分组编码 */
        group_code?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editOutput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditModule
     * @summary 编辑系统功能模块
     * @request POST:/dtsystem/editModule
     * @secure
     */
    editModule: (
      query?: {
        /** 模块名称 */
        name?: string;
        /** 模块描述 */
        memo?: string;
        /** 创建人 */
        create_name?: string;
        /** 创建时间 */
        create_time?: string;
        /** 模块ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editModule`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditJobTemplate
     * @summary 编辑系统作业模板
     * @request POST:/dtsystem/editJobTemplate
     * @secure
     */
    editJobTemplate: (
      query?: {
        /** id */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 模板名称 */
        name?: string;
        /** 所属模块 */
        module_id?: string;
        /** 接口id */
        api_id?: string;
        /** 内容 */
        content?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editJobTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditInput
     * @summary 编辑接口输入参数
     * @request POST:/dtsystem/editInput
     * @secure
     */
    editInput: (
      query?: {
        /** 入参ID */
        id?: string;
        /** 入参名称 */
        name?: string;
        /** 入参描述 */
        memo?: string;
        /** 入参版本 */
        version?: string;
        /** 默认取值 */
        defvalue?: string;
        /** 是否必填 */
        isnotnull?: string;
        /** 验证规则 */
        validate?: string;
        /** 是否只读 */
        readonly?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 组件模板 */
        template?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 接口ID */
        api_id?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editInput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditGroup
     * @summary 编辑系统功能分组
     * @request POST:/dtsystem/editGroup
     * @secure
     */
    editGroup: (
      query?: {
        /** 所属组名称 */
        group_name?: string;
        /** 英文名称 */
        english_name?: string;
        /** 所属模块 */
        module_id?: string;
        /** 图标 */
        icon?: string;
        /** 默认图标 */
        default_icon?: string;
        /** 分组描述 */
        group_memo?: string;
        /** 系统所属分组ID */
        id?: string;
        /** 排序 */
        sort?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editGroup`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditFunct
     * @summary 编辑系统功能页面
     * @request POST:/dtsystem/editFunct
     * @secure
     */
    editFunct: (
      query?: {
        /** 功能ID */
        id?: string;
        /** 功能名称 */
        name?: string;
        /** 功能描述 */
        memo?: string;
        /** 功能配置 */
        configure?: string;
        /** 所属模块 */
        module_id?: string;
        /** 组件模板 */
        template?: string;
        /** 功能流程 */
        workflow?: string;
        /** 功能权限 */
        grants?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 详细需求 */
        det_info?: string;
        /** 所属组 */
        group_id?: string;
        /** 排序 */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editFunct`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditErrcode
     * @summary 编辑错误编码配置
     * @request POST:/dtsystem/editErrcode
     * @secure
     */
    editErrcode: (
      query?: {
        /** 错误编码 */
        errcode?: string;
        /** 错误描述 */
        errdesc?: string;
        /** 错误级别 */
        errlevel?: string;
        /** 错误类型 */
        errtype?: string;
        /** 所属模块 */
        module_id?: string;
        /** 错误ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editErrcode`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditEnviron
     * @summary 编辑项目环境配置
     * @request POST:/dtsystem/editEnviron
     * @secure
     */
    editEnviron: (
      query?: {
        /** 环境名称 */
        name?: string;
        /** 环境描述 */
        memo?: string;
        /** 所属项目 */
        project_id?: string;
        /** 环境ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editEnviron`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditDialogBox
     * @summary 编辑弹框组件表
     * @request POST:/dtsystem/editDialogBox
     * @secure
     */
    editDialogBox: (
      query?: {
        /** 标题 */
        title?: string;
        /** 组件名称 */
        name?: string;
        /** 上传文件 */
        upload_file?: string;
        /** 所属模块 */
        module_id?: string;
        /** 组件模板 */
        template?: string;
        /** 功能流程 */
        workflow?: string;
        /** 功能权限 */
        grants?: string;
        /** 功能配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editDialogBox`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditDefect
     * @summary 编辑缺陷记录
     * @request POST:/dtsystem/editDefect
     * @secure
     */
    editDefect: (
      query: {
        /** 所属模块 */
        from_module?: string;
        /** 所属页面 */
        funct_id?: string;
        /** 状态 */
        status?: string;
        /** 优先级 */
        priority?: string;
        /** 严重程度 */
        severity?: string;
        /** 指派给 */
        assign: string;
        /** 解决方案 */
        solution?: string;
        /** 附件 */
        annex?: string;
        /** 重现步骤 */
        step_reproduction?: string;
        /** 视频 */
        vedio_path?: string;
        /** 标题 */
        title?: string;
        /** 描述 */
        describe?: string;
        /** 缺陷ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editDefect`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name EditApi
     * @summary 编辑系统数据接口
     * @request POST:/dtsystem/editApi
     * @secure
     */
    editApi: (
      query?: {
        /** 接口ID */
        id?: string;
        /** 接口名称 */
        name?: string;
        /** 接口描述 */
        memo?: string;
        /** 所属模块 */
        module_id?: string;
        /** 执行语句 */
        sqltext?: string;
        /** 执行类型 */
        ivktype?: string;
        /** 接口类型 */
        apitype?: string;
        /** 本地方法 */
        ivkfunc?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 流程语句 */
        scptext?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/editApi`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteValidation
     * @summary 删除表单效验规则
     * @request POST:/dtsystem/deleteValidation
     * @secure
     */
    deleteValidation: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 表单类型ID(dtsystem_template) */
        type?: string;
        /** 正则表达式内容 */
        content?: string;
        /** 描述 */
        memo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteValidation`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteUser
     * @summary 删除系统用户配置
     * @request POST:/dtsystem/deleteUser
     * @secure
     */
    deleteUser: (
      query?: {
        /** 用户ID */
        id?: string;
        /** 用户姓名 */
        name?: string;
        /** 用户密码 */
        pass?: string;
        /** 所属角色 */
        role?: string;
        /** 用户授权 */
        grants?: string;
        /** 手机号码 */
        phone?: string;
        /** 随机秘钥 */
        sailt?: string;
        /** 真实姓名 */
        rname?: string;
        /** 身份证号 */
        cardid?: string;
        /** 当前状态 */
        status?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteTemplate
     * @summary 删除系统组件配置
     * @request POST:/dtsystem/deleteTemplate
     * @secure
     */
    deleteTemplate: (
      query?: {
        /** 组件ID */
        id?: string;
        /** 组件名称 */
        name?: string;
        /** 组件类型（1基础2地理3图表3组合） */
        type?: string;
        /** 绑定组件 */
        bind?: string;
        /** 组件配置 */
        config?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteStudy
     * @summary studyDelete
     * @request POST:/dtsystem/deleteStudy
     * @secure
     */
    deleteStudy: (
      query?: {
        /** id */
        id?: string;
        /** 名称 */
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteStudy`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteSms
     * @summary 删除短信记录
     * @request POST:/dtsystem/deleteSms
     * @secure
     */
    deleteSms: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 发送状态(0:未发送;1:失败;2:已发送;3:已回执;4:已回复) */
        status?: string;
        /** 短信类型(1:验证码;2:通知) */
        type?: string;
        /** 手机号 */
        phone?: string;
        /** 短信内容 */
        sms?: string;
        /** 短信参数(json) */
        para?: string;
        /** 时效性(分钟) */
        time?: string;
        /** 发送时间 */
        send_time?: string;
        /** 回执时间 */
        receipt_time?: string;
        /** 回复时间 */
        reply_time?: string;
        /** 回复内容 */
        reply_content?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteSms`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteSmsTemplate
     * @summary 删除短信模板
     * @request POST:/dtsystem/deleteSmsTemplate
     * @secure
     */
    deleteSmsTemplate: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核状态(0:未审核;1:未通过;2:已通过) */
        status?: string;
        /** 模板类型(1:验证码;2:通知) */
        type?: string;
        /** 短信模板ID */
        template_id?: string;
        /** 短信模板内容 */
        template?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteSmsTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteRole
     * @summary 删除系统角色配置
     * @request POST:/dtsystem/deleteRole
     * @secure
     */
    deleteRole: (
      query?: {
        /** 角色ID */
        id?: string;
        /** 角色名称 */
        name?: string;
        /** 角色描述 */
        memo?: string;
        /** 角色授权 */
        grants?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteProject
     * @summary 新增系统项目配置
     * @request POST:/dtsystem/deleteProject
     * @secure
     */
    deleteProject: (
      query?: {
        /** 项目ID */
        id?: string;
        /** 项目名称 */
        name?: string;
        /** 项目描述 */
        memo?: string;
        /** 项目人员 */
        pro_user?: string;
        /** 项目经理 */
        manager?: string;
        /** 项目地址 */
        address?: string;
        /** 项目版本 */
        pro_version?: string;
        /** 公司名称 */
        company?: string;
        /** 产品设计背景 */
        pro_background?: string;
        /** 总体需求 */
        total_demand?: string;
        /** 业务功能树 */
        function_tree?: string;
        /** 业务角色描述 */
        role_describe?: string;
        /** 界面设计原则内容 */
        des_principle?: string;
        /** 界面设计要求内容 */
        ask_principle?: string;
        /** 性能要求内容 */
        performance?: string;
        /** 其他需求 */
        other_ask?: string;
        /** 附件 */
        annex?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteProcess
     * @summary 删除流程模板
     * @request POST:/dtsystem/deleteProcess
     * @secure
     */
    deleteProcess: (
      query?: {
        /** 流程模板ID */
        id?: string;
        /** 流程模板名称 */
        name?: string;
        /** 流程模板描述 */
        describe?: string;
        /** 流程模板内容 */
        content?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteProcess`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteProcessApi
     * @summary 删除接口流程模板表
     * @request POST:/dtsystem/deleteProcessApi
     * @secure
     */
    deleteProcessApi: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 名称 */
        name?: string;
        /** 内容 */
        content?: string;
        /** 描述 */
        describe?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteProcessApi`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteOutput
     * @summary 删除接口输出参数
     * @request POST:/dtsystem/deleteOutput
     * @secure
     */
    deleteOutput: (
      query?: {
        /** 出参ID */
        id?: string;
        /** 出参名称 */
        name?: string;
        /** 出参描述 */
        memo?: string;
        /** 出参版本 */
        version?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 组件模板 */
        template?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 接口ID */
        api_id?: string;
        /** 列表宽度 */
        width?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteOutput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteModule
     * @summary 删除系统功能模块
     * @request POST:/dtsystem/deleteModule
     * @secure
     */
    deleteModule: (
      query?: {
        /** 模块名称 */
        name?: string;
        /** 模块描述 */
        memo?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 模块ID */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteModule`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteJobTemplate
     * @summary 删除系统作业模板
     * @request POST:/dtsystem/deleteJobTemplate
     * @secure
     */
    deleteJobTemplate: (
      query?: {
        /** id */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 模板名称 */
        name?: string;
        /** 所属模块 */
        module_id?: string;
        /** 接口id */
        api_id?: string;
        /** 内容 */
        content?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteJobTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteInput
     * @summary 删除接口输入参数
     * @request POST:/dtsystem/deleteInput
     * @secure
     */
    deleteInput: (
      query?: {
        /** 入参ID */
        id?: string;
        /** 入参名称 */
        name?: string;
        /** 入参描述 */
        memo?: string;
        /** 入参版本 */
        version?: string;
        /** 默认取值 */
        defvalue?: string;
        /** 是否必填 */
        isnotnull?: string;
        /** 验证规则 */
        validate?: string;
        /** 是否只读 */
        readonly?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 组件模板 */
        template?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 接口ID */
        api_id?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteInput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteGroup
     * @summary 删除系统功能分组
     * @request POST:/dtsystem/deleteGroup
     * @secure
     */
    deleteGroup: (
      query?: {
        /** 系统所属分组ID */
        id?: string;
        /** 所属组名称 */
        group_name?: string;
        /** 英文名称 */
        english_name?: string;
        /** 所属模块id */
        module_id?: string;
        /** 排序 */
        sort?: string;
        /** 图标 */
        icon?: string;
        /** 默认图标 */
        default_icon?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteGroup`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteFunct
     * @summary 删除系统功能页面
     * @request POST:/dtsystem/deleteFunct
     * @secure
     */
    deleteFunct: (
      query?: {
        /** 功能名称 */
        name?: string;
        /** 功能描述 */
        memo?: string;
        /** 功能配置 */
        configure?: string;
        /** 所属模块 */
        module_id?: string;
        /** 组件模板 */
        template?: string;
        /** 功能流程 */
        workflow?: string;
        /** 功能权限 */
        grants?: string;
        /** 功能ID */
        id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteFunct`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteErrcode
     * @summary 删除错误编码配置
     * @request POST:/dtsystem/deleteErrcode
     * @secure
     */
    deleteErrcode: (
      query?: {
        /** 错误ID */
        id?: string;
        /** 错误编码 */
        errcode?: string;
        /** 错误描述 */
        errdesc?: string;
        /** 错误级别(1普通2严重） */
        errlevel?: string;
        /** 错误类型(1系统2业务） */
        errtype?: string;
        /** 所属模块 */
        module_id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteErrcode`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteEnviron
     * @summary 删除项目环境配置
     * @request POST:/dtsystem/deleteEnviron
     * @secure
     */
    deleteEnviron: (
      query?: {
        /** 环境ID */
        id?: string;
        /** 环境名称 */
        name?: string;
        /** 环境描述 */
        memo?: string;
        /** 所属项目 */
        project_id?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteEnviron`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteDialogBox
     * @summary 删除弹框组件表
     * @request POST:/dtsystem/deleteDialogBox
     * @secure
     */
    deleteDialogBox: (
      query?: {
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 组件名称 */
        name?: string;
        /** 上传文件 */
        upload_file?: string;
        /** 所属模块 */
        module_id?: string;
        /** 组件模板 */
        template?: string;
        /** 功能流程 */
        workflow?: string;
        /** 功能权限 */
        grants?: string;
        /** 功能配置 */
        configure?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteDialogBox`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteDefect
     * @summary 删除缺陷记录
     * @request POST:/dtsystem/deleteDefect
     * @secure
     */
    deleteDefect: (
      query?: {
        /** 缺陷记录ID */
        id?: string;
        /** 所属模块(1-模块2-子模块) */
        from_module?: string;
        /** 状态(新提交(默认)) */
        status?: string;
        /** 优先级(1-最高2-较高3-普通(默认)4-较低5-最低) */
        priority?: string;
        /** 严重程度(1-致命2-严重3-一般(默认)4-建议) */
        severity?: string;
        /** 指派给 */
        assign?: string;
        /** 标题 */
        title?: string;
        /** 描述 */
        describe?: string;
        /** 重现步骤 */
        step_reproduction?: string;
        /** 附件 */
        annex?: string;
        /** 解决方案(1-已修复2-转需求) */
        solution?: string;
        /** 视频 */
        vedio_path?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteDefect`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name DeleteApi
     * @summary 删除系统数据接口
     * @request POST:/dtsystem/deleteApi
     * @secure
     */
    deleteApi: (
      query?: {
        /** 接口ID */
        id?: string;
        /** 接口名称 */
        name?: string;
        /** 接口描述 */
        memo?: string;
        /** 所属模块 */
        module_id?: string;
        /** 执行语句 */
        sqltext?: string;
        /** 执行类型(1框架2模块) */
        ivktype?: string;
        /** 接口类型(1数据2混合) */
        apitype?: string;
        /** 本地方法 */
        ivkfunc?: string;
        /** 创建者 */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 流程语句 */
        scptext?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/deleteApi`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags develop-controller
     * @name ApiDeveloper
     * @summary 获取接口开发配置
     * @request POST:/dtsystem/apiDeveloper
     * @secure
     */
    apiDeveloper: (
      query?: {
        /** 模块名称 */
        module?: string;
        /** 接口名称 */
        apiname?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/apiDeveloper`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags schema-controller
     * @name AllTables
     * @summary 查询数据表格列表
     * @request POST:/dtsystem/allTables
     * @secure
     */
    allTables: (
      query?: {
        /** 模块名称 */
        module?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/allTables`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags schema-controller
     * @name AllColumns
     * @summary 查询数据表格查询字段
     * @request POST:/dtsystem/allColumns
     * @secure
     */
    allColumns: (
      query: {
        /** 表格名称 */
        table: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/allColumns`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddValidation
     * @summary 添加表单效验规则
     * @request POST:/dtsystem/addValidation
     * @secure
     */
    addValidation: (
      query?: {
        /** 表单类型 */
        type?: string;
        /** 描述 */
        memo?: string;
        /** 正则 */
        content?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 排序 */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addValidation`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddUser
     * @summary 新增系统用户配置
     * @request POST:/dtsystem/addUser
     * @secure
     */
    addUser: (
      query: {
        /** 用户姓名 */
        name: string;
        /** 所属角色 */
        role?: string;
        /** 用户授权 */
        grants?: string;
        /** 手机号码 */
        phone: string;
        /** 真实姓名 */
        rname?: string;
        /** 身份证号 */
        cardid?: string;
        /** 用户密码 */
        pass?: string;
        /** 随机秘钥 */
        sailt?: string;
        /** 当前状态 */
        status?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddTemplate
     * @summary 新增系统组件配置
     * @request POST:/dtsystem/addTemplate
     * @secure
     */
    addTemplate: (
      query?: {
        /** 组件名称 */
        name?: string;
        /** 组件类型 */
        type?: string;
        /** 绑定组件 */
        bind?: string;
        /** 组件配置 */
        config?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddSms
     * @summary 添加短信记录
     * @request POST:/dtsystem/addSms
     * @secure
     */
    addSms: (
      query?: {
        /** 发送状态 */
        status?: string;
        /** 短信类型 */
        type?: string;
        /** 手机号 */
        phone?: string;
        /** 短信内容 */
        sms?: string;
        /** 短信参数 */
        para?: string;
        /** 时效性(分钟) */
        time?: string;
        /** 发送时间 */
        send_time?: string;
        /** 回执时间 */
        receipt_time?: string;
        /** 回复时间 */
        reply_time?: string;
        /** 回复内容 */
        reply_content?: string;
        /** 创建用户ID */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addSms`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddSmsTemplate
     * @summary 添加短信模板
     * @request POST:/dtsystem/addSmsTemplate
     * @secure
     */
    addSmsTemplate: (
      query?: {
        /** 审核状态 */
        status?: string;
        /** 模板类型 */
        type?: string;
        /** 短信模板ID */
        template_id?: string;
        /** 短信模板内容 */
        template?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addSmsTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddRole
     * @summary 添加系统角色配置
     * @request POST:/dtsystem/addRole
     * @secure
     */
    addRole: (
      query?: {
        /** 角色名称 */
        name?: string;
        /** 角色描述 */
        memo?: string;
        /** 角色授权 */
        grants?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddProject
     * @summary 新增系统项目配置
     * @request POST:/dtsystem/addProject
     * @secure
     */
    addProject: (
      query?: {
        /** 项目名称 */
        name?: string;
        /** 项目描述 */
        memo?: string;
        /** 项目人员 */
        pro_user?: string;
        /** 项目经理 */
        manager?: string;
        /** 项目地址 */
        address?: string;
        /** 项目版本 */
        pro_version?: string;
        /** 公司名称 */
        company?: string;
        /** 产品设计背景 */
        pro_background?: string;
        /** 总体需求 */
        total_demand?: string;
        /** 业务功能树 */
        function_tree?: string;
        /** 业务角色描述 */
        role_describe?: string;
        /** 界面设计原则内容 */
        des_principle?: string;
        /** 界面设计要求内容 */
        ask_principle?: string;
        /** 性能要求内容 */
        performance?: string;
        /** 其他需求 */
        other_ask?: string;
        /** 附件 */
        annex?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddProcess
     * @summary 新增流程模板
     * @request POST:/dtsystem/addProcess
     * @secure
     */
    addProcess: (
      query?: {
        /** 流程模板名称 */
        name?: string;
        /** 流程模板描述 */
        describe?: string;
        /** 流程模板内容 */
        content?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addProcess`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddProcessApi
     * @summary 添加接口流程模板表
     * @request POST:/dtsystem/addProcessApi
     * @secure
     */
    addProcessApi: (
      query?: {
        /** 名称 */
        name?: string;
        /** 内容 */
        content?: string;
        /** 描述 */
        describe?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addProcessApi`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddOutput
     * @summary 新增接口输出参数
     * @request POST:/dtsystem/addOutput
     * @secure
     */
    addOutput: (
      query?: {
        /** 出参名称 */
        name?: string;
        /** 出参描述 */
        memo?: string;
        /** 出参版本 */
        version?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 组件模板 */
        template?: string;
        /** 参数排序 */
        ordernum?: string;
        /** 接口ID */
        api_id?: string;
        /** 列表宽度 */
        width?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 分组编码 */
        group_code?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addOutput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddModule
     * @summary 新增系统功能模块
     * @request POST:/dtsystem/addModule
     * @secure
     */
    addModule: (
      query?: {
        /** 模块名称 */
        name?: string;
        /** 模块描述 */
        memo?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addModule`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddJobTemplate
     * @summary 添加系统作业模板
     * @request POST:/dtsystem/addJobTemplate
     * @secure
     */
    addJobTemplate: (
      query?: {
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 模板名称 */
        name?: string;
        /** 所属模块 */
        module_id?: string;
        /** 接口id */
        api_id?: string;
        /** 内容 */
        content?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addJobTemplate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddInput
     * @summary 新增接口输入参数
     * @request POST:/dtsystem/addInput
     * @secure
     */
    addInput: (
      query?: {
        /** 入参名称 */
        name?: string;
        /** 入参描述 */
        memo?: string;
        /** 入参版本 */
        version?: string;
        /** 默认取值 */
        defvalue?: string;
        /** 是否必填 */
        isnotnull?: string;
        /** 验证规则 */
        validate?: string;
        /** 是否只读 */
        readonly?: string;
        /** 是否隐藏 */
        ishidden?: string;
        /** 组件模板 */
        template?: string;
        /** 接口ID */
        api_id?: string;
        /** 参数类型(1数值2字符) */
        datatype?: string;
        /** 组件配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
        /** 参数排序 */
        ordernum?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addInput`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddGroup
     * @summary 新增系统功能分组
     * @request POST:/dtsystem/addGroup
     * @secure
     */
    addGroup: (
      query?: {
        /** 英文名称 */
        english_name?: string;
        /** 所属组名称 */
        group_name?: string;
        /** 所属模块 */
        module_id?: string;
        /** 图标 */
        icon?: string;
        /** 默认图标 */
        default_icon?: string;
        /** 分组描述 */
        group_memo?: string;
        /** 排序 */
        sort?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addGroup`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddFunct
     * @summary 新增系统功能页面
     * @request POST:/dtsystem/addFunct
     * @secure
     */
    addFunct: (
      query?: {
        /** 功能名称 */
        name?: string;
        /** 功能配置 */
        configure?: string;
        /** 功能描述 */
        memo?: string;
        /** 所属模块 */
        module_id?: string;
        /** 组件模板 */
        template?: string;
        /** 功能流程 */
        workflow?: string;
        /** 功能权限 */
        grants?: string;
        /** 审核表 */
        auditing_table?: string;
        /** 完成进度 */
        finish_speed?: string;
        /** 客户端类型 */
        client_type?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addFunct`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddErrcode
     * @summary 新增错误编码配置
     * @request POST:/dtsystem/addErrcode
     * @secure
     */
    addErrcode: (
      query?: {
        /** 所属模块 */
        module_id?: string;
        /** 错误编码 */
        errcode?: string;
        /** 错误级别 */
        errlevel?: string;
        /** 错误类型 */
        errtype?: string;
        /** 错误描述 */
        errdesc?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addErrcode`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddEnviron
     * @summary 新增项目环境配置
     * @request POST:/dtsystem/addEnviron
     * @secure
     */
    addEnviron: (
      query?: {
        /** 环境名称 */
        name?: string;
        /** 环境描述 */
        memo?: string;
        /** 所属项目 */
        project_id?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addEnviron`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddDialogBox
     * @summary 添加弹框组件表
     * @request POST:/dtsystem/addDialogBox
     * @secure
     */
    addDialogBox: (
      query?: {
        /** 标题 */
        title?: string;
        /** 组件名称 */
        name?: string;
        /** 上传文件 */
        upload_file?: string;
        /** 所属模块 */
        module_id?: string;
        /** 组件模板 */
        template?: string;
        /** 功能流程 */
        workflow?: string;
        /** 功能权限 */
        grants?: string;
        /** 功能配置 */
        configure?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addDialogBox`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddDefect
     * @summary 新增缺陷记录
     * @request POST:/dtsystem/addDefect
     * @secure
     */
    addDefect: (
      query?: {
        /** 所属模块 */
        from_module?: string;
        /** 状态 */
        status?: string;
        /** 所属页面 */
        funct_id?: string;
        /** 优先级 */
        priority?: string;
        /** 严重程度 */
        severity?: string;
        /** 解决方案 */
        solution?: string;
        /** 指派给 */
        assign?: string;
        /** 附件 */
        annex?: string;
        /** 视频 */
        vedio_path?: string;
        /** 标题 */
        title?: string;
        /** 描述 */
        describe?: string;
        /** 重现步骤 */
        step_reproduction?: string;
        /** 创建者 */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addDefect`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dtsystem-1732505381452
     * @name AddApi
     * @summary 新增系统数据接口
     * @request POST:/dtsystem/addApi
     * @secure
     */
    addApi: (
      query?: {
        /** 接口名称 */
        name?: string;
        /** 接口描述 */
        memo?: string;
        /** 所属模块 */
        module_id?: string;
        /** 执行语句 */
        sqltext?: string;
        /** 执行类型(1框架2模块) */
        ivktype?: string;
        /** 接口类型(1数据2混合) */
        apitype?: string;
        /** 本地方法 */
        ivkfunc?: string;
        /** 创建者 */
        create_user?: string;
        /** 流程语句 */
        scptext?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dtsystem/addApi`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags login-controller
     * @name Logout
     * @summary 用户退出
     * @request GET:/dtsystem/logout
     * @secure
     */
    logout: (params: RequestParams = {}) =>
      this.request<JSONObject, any>({
        path: `/dtsystem/logout`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  dteqms = {
    /**
     * No description
     *
     * @tags export-controller
     * @name UserSignCount
     * @summary 安全员打卡记录导出
     * @request POST:/dteqms/user_sign_count
     * @secure
     */
    userSignCount: (
      query?: {
        /** 开始时间 */
        time_beg?: string;
        /** 结束时间 */
        time_end?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/user_sign_count`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name StatUserSignStatByDeptType
     * @summary 根据单位类型统计用户签到
     * @request POST:/dteqms/statUserSignStatByDeptType
     * @secure
     */
    statUserSignStatByDeptType: (
      query?: {
        /** 开始日期 */
        start_date?: string;
        /** 结束日期 */
        end_date?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/statUserSignStatByDeptType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name SlientExamConf
     * @summary 考题配置
     * @request POST:/dteqms/slientExamConf
     * @secure
     */
    slientExamConf: (
      query?: {
        /**
         * 每日一学每题分数
         * @format int32
         */
        score1?: number;
        /**
         * 每日一学题目数量
         * @format int32
         */
        cnt1?: number;
        /**
         * 生活常识每题分数
         * @format int32
         */
        score2?: number;
        /**
         * 生活常识每题数量
         * @format int32
         */
        cnt2?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/slientExamConf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name SaveOrUpdateProjectGrants
     * @summary 工程节点授权
     * @request POST:/dteqms/saveOrUpdateProjectGrants
     * @secure
     */
    saveOrUpdateProjectGrants: (
      query?: {
        /** 工程ID */
        project_id?: string;
        /** 数据[{di:1,ri:1,ui:1,g:[]},{di:1,ri:2,ui:2,g:[]}] */
        data?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/saveOrUpdateProjectGrants`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name SaveOrUpdateNode
     * @summary 工程节点保存
     * @request POST:/dteqms/saveOrUpdateNode
     * @secure
     */
    saveOrUpdateNode: (
      query: {
        /** 父ID */
        pid: string;
        /** 工程ID */
        project_id?: string;
        /** 层级 */
        level: string;
        /** 名称 */
        name: string;
        /** 编码 */
        code: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/saveOrUpdateNode`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListWarningCase
     * @summary 查询警示案列
     * @request POST:/dteqms/listWarningCase
     * @secure
     */
    listWarningCase: (
      query?: {
        /** 标题 */
        title?: string;
        /** 可见范围 */
        visibility?: string;
        /** 审核状态 */
        check_state?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 工程名称 */
        project_id?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listWarningCase`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name ListUserFuncts
     * @summary 用户有权限的功能列表
     * @request POST:/dteqms/listUserFuncts
     * @secure
     */
    listUserFuncts: (params: RequestParams = {}) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listUserFuncts`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListUserByProjectId
     * @summary 通过projectid取用户
     * @request POST:/dteqms/listUserByProjectId
     * @secure
     */
    listUserByProjectId: (
      query?: {
        /** 项目id */
        project_id2?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listUserByProjectId`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListUserByDept
     * @summary 根据单位获取人员
     * @request POST:/dteqms/listUserByDept
     * @secure
     */
    listUserByDept: (
      query?: {
        /** 单位ID */
        dept_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listUserByDept`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListTechnologyHelp
     * @summary 查询技术求助
     * @request POST:/dteqms/listTechnologyHelp
     * @secure
     */
    listTechnologyHelp: (
      query?: {
        /** 标题 */
        title?: string;
        /** 可见范围 */
        visibility?: string;
        /** 是否愿意付费解决 */
        is_pay?: string;
        /** 解决状态 */
        status?: string;
        /** 审核状态 */
        check_state?: string;
        /** 工程名称 */
        project_id?: string;
        /** 是否匿名 */
        is_anony?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 求助内容 */
        content?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listTechnologyHelp`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListTechnologyHelpRecord
     * @summary 查询技术求助记录
     * @request POST:/dteqms/listTechnologyHelpRecord
     * @secure
     */
    listTechnologyHelpRecord: (
      query?: {
        /** 技术求助记录ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 技术求助ID */
        help_id?: string;
        /** 评价内容 */
        content?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listTechnologyHelpRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListSystemNotice
     * @summary 查询系统通知
     * @request POST:/dteqms/listSystemNotice
     * @secure
     */
    listSystemNotice: (
      query?: {
        /** 标题 */
        title?: string;
        /** 有效时间 */
        period_time?: string;
        /** id */
        id?: string;
        /** 创建用户id */
        create_user?: string;
        /** 更新用户id */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 内容 */
        content?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listSystemNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListSystemNoticePeriod
     * @summary 查询系统公告(有效时间内)
     * @request POST:/dteqms/listSystemNoticePeriod
     * @secure
     */
    listSystemNoticePeriod: (
      query?: {
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listSystemNoticePeriod`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stat-controller
     * @name ListStatReformData
     * @summary 安全整改统计
     * @request POST:/dteqms/listStatReformData
     * @secure
     */
    listStatReformData: (
      query?: {
        /** 开始时间 */
        time_beg?: string;
        /** 结束时间 */
        time_end?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
        /**
         * 单位类型
         * @format int32
         */
        type?: number;
        /**
         * 所属标段
         * @format int32
         */
        project_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listStatReformData`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stat-controller
     * @name ListStatHelpData
     * @summary 帮扶检查统计
     * @request POST:/dteqms/listStatHelpData
     * @secure
     */
    listStatHelpData: (
      query?: {
        /** 检查开始日期 */
        check_date_beg?: string;
        /** 检查结束日期 */
        check_date_end?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
        /**
         * 单位类型
         * @format int32
         */
        type?: number;
        /**
         * 所属标段
         * @format int32
         */
        project_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listStatHelpData`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags count-controller
     * @name ListSignYearCnt
     * @summary 上班签到年统计
     * @request POST:/dteqms/listSignYearCnt
     * @secure
     */
    listSignYearCnt: (
      query?: {
        /** 年统计 */
        year?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSignYearCnt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags count-controller
     * @name ListSignStageCnt
     * @summary 上班签到阶段统计
     * @request POST:/dteqms/listSignStageCnt
     * @secure
     */
    listSignStageCnt: (
      query?: {
        /** 开始时间 */
        time_beg?: string;
        /** 结束时间 */
        time_end?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSignStageCnt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags count-controller
     * @name ListSignMonthCnt
     * @summary 上班签到月统计
     * @request POST:/dteqms/listSignMonthCnt
     * @secure
     */
    listSignMonthCnt: (
      query?: {
        /** 月统计 */
        month?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSignMonthCnt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags count-controller
     * @name ListSecureSignYearCnt
     * @summary 安全员打卡年统计
     * @request POST:/dteqms/listSecureSignYearCnt
     * @secure
     */
    listSecureSignYearCnt: (
      query?: {
        /** 年统计 */
        year?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSecureSignYearCnt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags count-controller
     * @name ListSecureSignStageCnt
     * @summary 安全员打卡阶段统计
     * @request POST:/dteqms/listSecureSignStageCnt
     * @secure
     */
    listSecureSignStageCnt: (
      query?: {
        /** 开始时间 */
        time_beg?: string;
        /** 结束时间 */
        time_end?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSecureSignStageCnt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags count-controller
     * @name ListSecureSignMonthCnt
     * @summary 安全员打卡月统计
     * @request POST:/dteqms/listSecureSignMonthCnt
     * @secure
     */
    listSecureSignMonthCnt: (
      query?: {
        /** 月统计 */
        month?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSecureSignMonthCnt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name ListSafetyUserSign
     * @summary 查询安全员打卡
     * @request POST:/dteqms/listSafetyUserSign
     * @secure
     */
    listSafetyUserSign: (
      query?: {
        /**
         * 审核状态
         * @format int32
         */
        check_state?: number;
        /**
         * 单位类型
         * @format int32
         */
        dept_type?: number;
        /**
         * 所属单位
         * @format int32
         */
        dept_id?: number;
        /** 打卡人 */
        us_name?: string;
        /** 打卡开始日期 */
        create_time_beg?: string;
        /** 打卡结束日期 */
        create_time_end?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSafetyUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListSafetyTraining
     * @summary 查询安全培训
     * @request POST:/dteqms/listSafetyTraining
     * @secure
     */
    listSafetyTraining: (
      query?: {
        /** 所属监理标段 */
        project_id5?: string;
        /** 所属标段 */
        project_id?: string;
        /** 培训名称 */
        train_name?: string;
        /** 进场类别 */
        entry_category?: string;
        /** 培训类型 */
        train_type?: string;
        /** 培训时间 */
        meeting_time?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 培训形式 */
        train_format?: string;
        /** 审核状态 */
        check_state?: string;
        /** 培训内容简介 */
        train_desc?: string;
        /** 现场照片 */
        scene_photos?: string;
        /** 签到表照片 */
        sign_photos?: string;
        /** 附件 */
        attachment?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listSafetyTraining`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags count-controller
     * @name ListSafetyTrainingYearCnt
     * @summary 安全培训年统计
     * @request POST:/dteqms/listSafetyTrainingYearCnt
     * @secure
     */
    listSafetyTrainingYearCnt: (
      query?: {
        /** 年统计 */
        year?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSafetyTrainingYearCnt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags count-controller
     * @name ListSafetyTrainingStageCnt
     * @summary 安全培训阶段统计
     * @request POST:/dteqms/listSafetyTrainingStageCnt
     * @secure
     */
    listSafetyTrainingStageCnt: (
      query?: {
        /** 会议开始时间 */
        time_beg?: string;
        /** 会议结束时间 */
        time_end?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSafetyTrainingStageCnt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags count-controller
     * @name ListSafetyTrainingMonthCnt
     * @summary 安全培训月统计
     * @request POST:/dteqms/listSafetyTrainingMonthCnt
     * @secure
     */
    listSafetyTrainingMonthCnt: (
      query?: {
        /** 月统计 */
        month?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSafetyTrainingMonthCnt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name ListSafetyTrainDetail
     * @summary 查询安全培训明细
     * @request POST:/dteqms/listSafetyTrainDetail
     * @secure
     */
    listSafetyTrainDetail: (
      query?: {
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSafetyTrainDetail`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListSafetyReform
     * @summary 查询安全整改
     * @request POST:/dteqms/listSafetyReform
     * @secure
     */
    listSafetyReform: (
      query?: {
        /** 标段名称 */
        project_id?: string;
        /** 单位名称 */
        dept_id?: string;
        /** 标题 */
        title?: string;
        /** 整改状态 */
        reform_status?: string;
        /** 审核状态 */
        check_state?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 整改人 */
        reform_uid?: string;
        /** 整改截止时间 */
        reform_end_time?: string;
        /** 整改完成时间 */
        reform_finish_time?: string;
        /** 问题描述 */
        question_desc?: string;
        /** 整改要求 */
        requirements?: string;
        /** 整改前照片 */
        pre_photos?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listSafetyReform`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name ListSafetyReformAssign
     * @summary 问题整改指派列表
     * @request POST:/dteqms/listSafetyReformAssign
     * @secure
     */
    listSafetyReformAssign: (
      query?: {
        /** 标题 */
        title?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listSafetyReformAssign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-11-08 16:52:53
     *
     * @tags dteqms-1732697495363
     * @name ListReformCount
     * @summary 查询整改人消息数量
     * @request POST:/dteqms/listReformCount
     * @secure
     */
    listReformCount: (
      query?: {
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listReformCount`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name ListProjectTree
     * @summary 工程树结构
     * @request POST:/dteqms/listProjectTree
     * @secure
     */
    listProjectTree: (
      query: {
        /** 工程ID */
        project_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listProjectTree`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListProjectReport
     * @summary 查询项目通报
     * @request POST:/dteqms/listProjectReport
     * @secure
     */
    listProjectReport: (
      query?: {
        /** 标题 */
        title?: string;
        /** 通报类型 */
        type?: string;
        /** 可见范围 */
        visibility?: string;
        /** 审核状态 */
        check_state?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 工程ID */
        project_id?: string;
        /** 通报描述 */
        content?: string;
        /** 附件地址 */
        attachment?: string;
        /** 发布状态 */
        publish_status?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listProjectReport`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name ListProjectGrants
     * @summary 获取工程授权
     * @request POST:/dteqms/listProjectGrants
     * @secure
     */
    listProjectGrants: (
      query: {
        /** 工程ID */
        project_id: string;
        /** 单位ID */
        dept_id?: string;
        /** 角色ID */
        role_id?: string;
        /** 用户ID */
        uid?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listProjectGrants`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name ListProjectChild
     * @summary 用户有权限的下级节点
     * @request POST:/dteqms/listProjectChild
     * @secure
     */
    listProjectChild: (
      query: {
        /** 父节点 */
        pid: string;
        /**
         * 层级
         * @default "0"
         */
        level?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listProjectChild`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name ListProjectChildExt
     * @summary 用户有权限的下级节点
     * @request POST:/dteqms/listProjectChildExt
     * @secure
     */
    listProjectChildExt: (
      query: {
        /** 工程ID */
        project2: string;
        /** 项目ID */
        project3?: string;
        /**
         * 层级
         * @default "0"
         */
        level?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listProjectChildExt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListProject6ByDept
     * @summary 根据标段获取单位
     * @request POST:/dteqms/listProject6ByDept
     * @secure
     */
    listProject6ByDept: (
      query?: {
        /** 标段id */
        project_id?: string;
        /** 单位类型 */
        dept_type?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listProject6ByDept`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListPersonalLedger
     * @summary 查询人员台账
     * @request POST:/dteqms/listPersonalLedger
     * @secure
     */
    listPersonalLedger: (
      query?: {
        /** 所属标段 */
        project_id?: string;
        /** 台账类型 */
        type?: string;
        /** 审核状态 */
        check_state?: string;
        /** 设备名称 */
        name?: string;
        /** 联系方式 */
        contact?: string;
        /** 进场时间 */
        in_time?: string;
        /** 离场时间 */
        out_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listPersonalLedger`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name ListPerformance
     * @summary 查询业绩列表
     * @request POST:/dteqms/listPerformance
     * @secure
     */
    listPerformance: (
      query?: {
        /**
         * id
         * @format int32
         */
        id?: number;
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone?: string;
        /**
         * 单位类型
         * @format int32
         */
        type?: number;
        /**
         * 单位id
         * @format int32
         */
        dept_id?: number;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listPerformance`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListPenaltyNotice
     * @summary 查询处罚公示
     * @request POST:/dteqms/listPenaltyNotice
     * @secure
     */
    listPenaltyNotice: (
      query?: {
        /** 处罚单位 */
        dept_id?: string;
        /** 处罚类别 */
        penalty_type?: string;
        /** 可见范围 */
        visibility?: string;
        /** 案由 */
        cause_action?: string;
        /** 审核状态 */
        check_state?: string;
        /** 创建时间 */
        create_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 处罚决定内容 */
        penalty_desc?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 更新用户ID */
        update_user?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 处罚决定书编号 */
        penalty_number?: string;
        /** 更新时间 */
        update_time?: string;
        /** 执行情况 */
        execute_state?: string;
        /** 工程 */
        project_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listPenaltyNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListMeetingNotice
     * @summary 查询会议通知
     * @request POST:/dteqms/listMeetingNotice
     * @secure
     */
    listMeetingNotice: (
      query?: {
        /** 会议类型 */
        type?: string;
        /** 审核状态 */
        check_state?: string;
        /** 会议标题 */
        m_title?: string;
        /** 会议时间 */
        m_time?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 是否包含施工安全方面内容 */
        has_safety_content?: string;
        /** ID */
        id?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 工程ID */
        project_id?: string;
        /** 会议内容 */
        m_content?: string;
        /** 会议地点 */
        m_address?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listMeetingNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListLaw
     * @summary 查询法律法规
     * @request POST:/dteqms/listLaw
     * @secure
     */
    listLaw: (
      query?: {
        /** 标题 */
        name?: string;
        /** 编号 */
        code?: string;
        /** 公告栏目 */
        column_type?: string;
        /** 创建用户ID */
        create_user?: string;
        /** ID */
        id?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 工程ID */
        project_id?: string;
        /** 审核状态 */
        check_state?: string;
        /** 内容 */
        content?: string;
        /** 附件地址 */
        attachment?: string;
        /** 实施日期 */
        implement_date?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listLaw`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListLawRecord
     * @summary 查询法律法规建议
     * @request POST:/dteqms/listLawRecord
     * @secure
     */
    listLawRecord: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 法律法规ID */
        law_id?: string;
        /** 建议内容 */
        content?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listLawRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListIndustryExplore
     * @summary 查询先行者探索
     * @request POST:/dteqms/listIndustryExplore
     * @secure
     */
    listIndustryExplore: (
      query?: {
        /** 标题 */
        title?: string;
        /** 提出人 */
        proposer?: string;
        /** 审核状态 */
        check_state?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 工程ID */
        project_id?: string;
        /** 解决问题描述 */
        content?: string;
        /** 是否支持App操作 */
        is_support_app?: string;
        /** 学习成本 */
        learning_cost?: string;
        /** 轮播图片地址 */
        turning_picture?: string;
        /** 其他图片地址 */
        other_picture?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listIndustryExplore`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListHelpCheck
     * @summary 查询帮扶检查
     * @request POST:/dteqms/listHelpCheck
     * @secure
     */
    listHelpCheck: (
      query?: {
        /** 所属标段 */
        project_id?: string;
        /** 所属单位 */
        dept_id?: string;
        /** 整改状态 */
        reform_status?: string;
        /** 整改日期 */
        reform_finish_time?: string;
        /** 检查形式 */
        check_form?: string;
        /** 问题描述 */
        question_desc?: string;
        /** 检查日期 */
        check_date?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 标题 */
        title?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 整改人ID */
        reform_uid?: string;
        /** 整改截止时间 */
        reform_end_time?: string;
        /** 整改前照片 */
        pre_photos?: string;
        /** 整改要求 */
        requirements?: string;
        /** 审核状态 */
        check_state?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listHelpCheck`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListHazardProject
     * @summary 查询危大工程
     * @request POST:/dteqms/listHazardProject
     * @secure
     */
    listHazardProject: (
      query?: {
        /** 所属标段 */
        project_id?: string;
        /** 所属单位 */
        dept_id?: string;
        /** 危大工程名称 */
        name?: string;
        /** 级别 */
        level?: string;
        /** 审核状态 */
        check_state?: string;
        /** 交底/验收状态 */
        status?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 施工批准记录 */
        constr_approval_record?: string;
        /** 航行通告(不停航施工） */
        navigation_bulletin?: string;
        /** 安全保证责任书 */
        safety_res_letter?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 位置1 */
        position_1?: string;
        /** 位置1 */
        position_2?: string;
        /** 位置1 */
        position_3?: string;
        /** 位置1 */
        position_4?: string;
        /** 专项方案审批记录 */
        approval_record?: string;
        /** 专项方案论证记录 */
        rationale_record?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listHazardProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListHazardProjectTj
     * @summary 危大-施工标段管理
     * @request POST:/dteqms/listHazardProjectTJ
     * @secure
     */
    listHazardProjectTj: (
      query?: {
        /** 标段名称 */
        project_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listHazardProjectTJ`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListHazardProjectDetailed
     * @summary 危大工程明细
     * @request POST:/dteqms/listHazardProjectDetailed
     * @secure
     */
    listHazardProjectDetailed: (
      query?: {
        /** 所属标段 */
        project_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listHazardProjectDetailed`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListHazardProjectCnt
     * @summary 危大工程统计
     * @request POST:/dteqms/listHazardProjectCnt
     * @secure
     */
    listHazardProjectCnt: (
      query?: {
        /** 所属标段 */
        project_id?: string;
        /** 开始时间 */
        time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listHazardProjectCnt`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name ListFindTypeByEntry
     * @summary 根据进场类别查询培训类型
     * @request POST:/dteqms/listFindTypeByEntry
     * @secure
     */
    listFindTypeByEntry: (
      query: {
        /**
         * 培训类别
         * @format int32
         */
        entry_category: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listFindTypeByEntry`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name ListFindDeptByType
     * @summary 根据单位类型获取单位列表
     * @request POST:/dteqms/listFindDeptByType
     * @secure
     */
    listFindDeptByType: (
      query: {
        /** 区域ID */
        region_id?: string;
        /** 单位类型(1,2) */
        types: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listFindDeptByType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name ListFindAttendees
     * @summary 会议的与会人列表
     * @request POST:/dteqms/listFindAttendees
     * @secure
     */
    listFindAttendees: (
      query: {
        /**
         * 会议id
         * @format int32
         */
        mid: number;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listFindAttendees`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListExamStore
     * @summary 查询题库
     * @request POST:/dteqms/listExamStore
     * @secure
     */
    listExamStore: (
      query?: {
        /** 题目分类 */
        type?: string;
        /** 题目类型 */
        category?: string;
        /** 问题 */
        question?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 正确答案 */
        answer?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listExamStore`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListExamStoreMe
     * @summary 查询学习笔记-错题
     * @request POST:/dteqms/listExamStoreMe
     * @secure
     */
    listExamStoreMe: (
      query?: {
        /** 题目分类 */
        store_type?: string;
        /** 题目类型 */
        category?: string;
        /** 问题 */
        question?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 类型 */
        type?: string;
        /** 题目ID */
        es_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listExamStoreMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListExamRecord
     * @summary 查询考试记录
     * @request POST:/dteqms/listExamRecord
     * @secure
     */
    listExamRecord: (
      query?: {
        /** 单位类型 */
        dept_type?: string;
        /** 所属单位 */
        dept_id?: string;
        /** 考试人 */
        us_name?: string;
        /** 考试日期 */
        create_time?: string;
        /** 考试状态 */
        exam_type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 更新时间 */
        update_time?: string;
        /** 考试分数 */
        score?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listExamRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListExamConf
     * @summary 查询题库配置
     * @request POST:/dteqms/listExamConf
     * @secure
     */
    listExamConf: (
      query?: {
        /** 每题分数 */
        score?: string;
        /** 题目数量 */
        cnt?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listExamConf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListDeptByUser
     * @summary 根据单位类型获取人员
     * @request POST:/dteqms/listDeptByUser
     * @secure
     */
    listDeptByUser: (
      query?: {
        /** 单位类型 */
        dept_type?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listDeptByUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListCraftsmanship
     * @summary 行业匠心
     * @request POST:/dteqms/listCraftsmanship
     * @secure
     */
    listCraftsmanship: (
      query?: {
        /** 标题 */
        title?: string;
        /** 提出人 */
        proposer?: string;
        /** 审核状态 */
        check_state?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 工程ID */
        project_id?: string;
        /** 解决问题描述 */
        content?: string;
        /** 是否支持App操作 */
        is_support_app?: string;
        /** 学习成本 */
        learning_cost?: string;
        /** 轮播图片地址 */
        turning_picture?: string;
        /** 其他图片地址 */
        other_picture?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listCraftsmanship`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListConveningOfMeeting
     * @summary 查询会议召集
     * @request POST:/dteqms/listConveningOfMeeting
     * @secure
     */
    listConveningOfMeeting: (
      query?: {
        /** 会议标题 */
        m_title?: string;
        /** 会议时间 */
        m_time?: string;
        /** 审核状态 */
        check_state?: string;
        /** 工程名称 */
        project_id?: string;
        /** 会议类型 */
        type?: string;
        /** 会议内容 */
        m_content?: string;
        /** 会议地点 */
        m_address?: string;
        /** 是否包含施工安全方面内容 */
        has_safety_content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listConveningOfMeeting`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListCollectStoreMe
     * @summary 查询学习笔记-收藏
     * @request POST:/dteqms/listCollectStoreMe
     * @secure
     */
    listCollectStoreMe: (
      query?: {
        /** 题目分类 */
        store_type?: string;
        /** 题目类型 */
        category?: string;
        /** 问题 */
        question?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 类型 */
        type?: string;
        /** 题目ID */
        es_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listCollectStoreMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags export-controller
     * @name ListClockInCountExp
     * @summary 普通签到记录导出
     * @request POST:/dteqms/listClockInCountExp
     * @secure
     */
    listClockInCountExp: (
      query?: {
        /** 开始时间 */
        time_beg?: string;
        /** 结束时间 */
        time_end?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listClockInCountExp`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags check-controller
     * @name ListCheckAuditingToMe
     * @summary 用户有权限需要审核的列表
     * @request POST:/dteqms/listCheckAuditingToMe
     * @secure
     */
    listCheckAuditingToMe: (
      query?: {
        /** 表名 */
        table_name?: string;
        /** 发起人用户ID */
        from_user_id?: string;
        /** 发起开始时间 */
        start_time_beg?: string;
        /** 发起结束时间 */
        start_time_end?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listCheckAuditingToMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags check-controller
     * @name ListCheckAuditingToMeFunc
     * @summary 用户有权限审核的功能列表
     * @request POST:/dteqms/listCheckAuditingToMeFunc
     * @secure
     */
    listCheckAuditingToMeFunc: (params: RequestParams = {}) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listCheckAuditingToMeFunc`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags check-controller
     * @name ListCheckAuditingHisMe
     * @summary 我的审核历史记录
     * @request POST:/dteqms/listCheckAuditingHisMe
     * @secure
     */
    listCheckAuditingHisMe: (
      query: {
        /** 表名 */
        table_name: string;
        /** 发起人用户ID */
        from_user_id?: string;
        /** 发起开始时间 */
        start_time?: string;
        /** 发起结束时间 */
        end_time?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listCheckAuditingHisMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags check-controller
     * @name ListCheckAuditingFromMe
     * @summary 用户发起需要审核的列表
     * @request POST:/dteqms/listCheckAuditingFromMe
     * @secure
     */
    listCheckAuditingFromMe: (
      query: {
        /** 表名 */
        table_name: string;
        /**
         * 审核状态(1:暂存;2:待审核;3:审核通过;4:驳回)
         * @format int32
         */
        check_state?: number;
        /** 发起开始时间 */
        start_time_beg?: string;
        /** 发起结束时间 */
        start_time_end?: string;
        /** 审核人用户ID */
        check_user_id?: string;
        /** 审核开始时间 */
        check_start_time?: string;
        /** 审核结束时间 */
        check_end_time?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listCheckAuditingFromMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags check-controller
     * @name ListCheckAuditingFromMeFunc
     * @summary 用户发起审核的功能列表
     * @request POST:/dteqms/listCheckAuditingFromMeFunc
     * @secure
     */
    listCheckAuditingFromMeFunc: (params: RequestParams = {}) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listCheckAuditingFromMeFunc`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseUser
     * @summary 查询用户
     * @request POST:/dteqms/listBaseUser
     * @secure
     */
    listBaseUser: (
      query?: {
        /** 单位类型 */
        type?: string;
        /** 单位名称 */
        dept_id?: string;
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 密码 */
        pwd?: string;
        /** 传真电话 */
        tel?: string;
        /** E-Mail */
        email?: string;
        /** 地址 */
        address?: string;
        /** 性别 */
        gender?: string;
        /** 备注 */
        remark?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name ListBaseUserSign
     * @summary 查询上班签到
     * @request POST:/dteqms/listBaseUserSign
     * @secure
     */
    listBaseUserSign: (
      query?: {
        /**
         * 所属标段
         * @format int32
         */
        project_id?: number;
        /**
         * 单位类型
         * @format int32
         */
        dept_type?: number;
        /**
         * 所属单位
         * @format int32
         */
        dept_id?: number;
        /** 签到人 */
        us_name?: string;
        /** 打卡开始日期 */
        create_time_beg?: string;
        /** 打卡结束日期 */
        create_time_end?: string;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listBaseUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseUserRole
     * @summary 查询用户角色关系
     * @request POST:/dteqms/listBaseUserRole
     * @secure
     */
    listBaseUserRole: (
      query: {
        /** 单位名称 */
        dept_id?: string;
        /** 姓名 */
        us_name: string;
        /** 手机号 */
        us_phone: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 姓名 */
        uid?: string;
        /** 角色ID */
        role_id?: string;
        /** 工程ID */
        project_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseUserRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseSectionWork
     * @summary 查询工作区
     * @request POST:/dteqms/listBaseSectionWork
     * @secure
     */
    listBaseSectionWork: (
      query?: {
        /** 所属项目 */
        work_item_id?: string;
        /** 标段 */
        project_id?: string;
        /** 标段工法 */
        section_method_id?: string;
        /** 完成状态 */
        finish_status?: string;
        /** 名称 */
        name?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 所属工程 */
        work_project_id?: string;
        /** 工作区范围 */
        map_range?: string;
        /** 区分原有还是弹框 */
        flag?: string;
        /** 第几页 */
        pageIndex?: string;
        /** 一次多少页 */
        pageSize?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseSectionWork`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseSectionMethod
     * @summary 查询标段工法
     * @request POST:/dteqms/listBaseSectionMethod
     * @secure
     */
    listBaseSectionMethod: (
      query?: {
        /** 标段 */
        project_id?: string;
        /** 工法类型 */
        method_type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 更新时间 */
        update_time?: string;
        /** 创建时间 */
        create_time?: string;
        /** 施工组织设计 */
        design?: string;
        /** 详细施工方案 */
        scheme?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseSectionMethod`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseSectionMethodByProjectId
     * @summary 根据标段ID查询标段工法
     * @request POST:/dteqms/listBaseSectionMethodByProjectId
     * @secure
     */
    listBaseSectionMethodByProjectId: (
      query?: {
        /** 标段ID */
        project_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseSectionMethodByProjectId`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseRole
     * @summary 查询角色
     * @request POST:/dteqms/listBaseRole
     * @secure
     */
    listBaseRole: (
      query?: {
        /** 单位类型 */
        type?: string;
        /** 单位名称 */
        dept_id?: string;
        /** 角色名称 */
        name?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseRoleDeptType
     * @summary 查询角色与单位类型关系表
     * @request POST:/dteqms/listBaseRoleDeptType
     * @secure
     */
    listBaseRoleDeptType: (
      query?: {
        /** 角色模板名称 */
        name?: string;
        /** 单位类型 */
        dept_type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 更新时间 */
        update_time?: string;
        /** 授权 */
        grants?: string;
        /** 创建时间 */
        create_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseRoleDeptType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseRegion
     * @summary 查询区域
     * @request POST:/dteqms/listBaseRegion
     * @secure
     */
    listBaseRegion: (
      query?: {
        /** 地区名称 */
        name?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 更新时间 */
        update_time?: string;
        /** 创建时间 */
        create_time?: string;
        /** 排序 */
        sort?: string;
        /** 父ID */
        pid?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseRegion`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseProject
     * @summary 工程
     * @request POST:/dteqms/listBaseProject
     * @secure
     */
    listBaseProject: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级(1:地区;2:工程;3:项目;4:分部:5:监理标段;6:标段;7:分项) */
        level?: string;
        /** 地图范围(标段) */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态(1:未完工;2:已完工;3:监理已验收;4:部分验收;5:全部验收;6:已竣工) */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** 专职安全管理人员数量 */
        safety_number?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseProjectByPid
     * @summary 根据pid查询子列表
     * @request POST:/dteqms/listBaseProjectByPid
     * @secure
     */
    listBaseProjectByPid: (
      query?: {
        /** 父id */
        project_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseProjectByPid`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseProject7
     * @summary 查询分项
     * @request POST:/dteqms/listBaseProject7
     * @secure
     */
    listBaseProject7: (
      query?: {
        /** 所属监理标段 */
        project5?: string;
        /** 所属标段 */
        pid?: string;
        /** 分项名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 分部 */
        project4?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseProject7`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseProject6
     * @summary 查询标段
     * @request POST:/dteqms/listBaseProject6
     * @secure
     */
    listBaseProject6: (
      query?: {
        /** 监理标段 */
        pid?: string;
        /** 标段名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 分部 */
        project4?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseProject6`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseProject5
     * @summary 查询监理标段
     * @request POST:/dteqms/listBaseProject5
     * @secure
     */
    listBaseProject5: (
      query?: {
        /** 监理标段名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 分部 */
        pid?: string;
        /** 所属机场 */
        airport?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseProject5`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseProject4
     * @summary 查询分部
     * @request POST:/dteqms/listBaseProject4
     * @secure
     */
    listBaseProject4: (
      query?: {
        /** 分部名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 项目 */
        pid?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseProject4`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseProject3
     * @summary 查询项目
     * @request POST:/dteqms/listBaseProject3
     * @secure
     */
    listBaseProject3: (
      query?: {
        /** 项目名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 所属机场 */
        airport?: string;
        /** 项目状态 */
        finish_status?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 工程 */
        pid?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 备注 */
        remark?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseProject3`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseProject2
     * @summary 查询工程
     * @request POST:/dteqms/listBaseProject2
     * @secure
     */
    listBaseProject2: (
      query?: {
        /** 所属地区 */
        pid?: string;
        /** 工程名称 */
        name?: string;
        /** 所属机场 */
        airport?: string;
        /** 工程状态 */
        finish_status?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 备注 */
        remark?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseProject2`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseNotice
     * @summary 查询系统通知
     * @request POST:/dteqms/listBaseNotice
     * @secure
     */
    listBaseNotice: (
      query?: {
        /** 会议时间 */
        m_time?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 处理状态 */
        deal_state?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户ID */
        uid?: string;
        /** 会议ID */
        mid?: string;
        /** 创建时间 */
        create_time?: string;
        /** 会议类型 */
        type?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseMethodType
     * @summary 查询工法类型
     * @request POST:/dteqms/listBaseMethodType
     * @secure
     */
    listBaseMethodType: (
      query?: {
        /** 工法名称 */
        name?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseMethodType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseFunctUserCommon
     * @summary 查询用户常用功能配置
     * @request POST:/dteqms/listBaseFunctUserCommon
     * @secure
     */
    listBaseFunctUserCommon: (
      query?: {
        /** 功能 */
        funct_id?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户ID */
        user_id?: string;
        /** 加入时间 */
        create_time?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseFunctUserCommon`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListBaseDept
     * @summary 查询单位
     * @request POST:/dteqms/listBaseDept
     * @secure
     */
    listBaseDept: (
      query?: {
        /** 所属地区 */
        region_id?: string;
        /** 单位类型 */
        type?: string;
        /** 单位名称 */
        name?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 父ID */
        pid?: string;
        /** 描述 */
        remark?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listBaseDept`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListAdvice
     * @summary 查询意见征求
     * @request POST:/dteqms/listAdvice
     * @secure
     */
    listAdvice: (
      query?: {
        /** 标题 */
        title?: string;
        /** 截止时间 */
        end_time?: string;
        /** 可见范围 */
        visibility?: string;
        /** 审核状态 */
        check_state?: string;
        /** ID11 */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 上传时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 工程名称 */
        project_id?: string;
        /** 内容 */
        content?: string;
        /** 附件地址 */
        attachment?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listAdvice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListAdviceRecord
     * @summary 查询意见征求评价
     * @request POST:/dteqms/listAdviceRecord
     * @secure
     */
    listAdviceRecord: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 意见征求ID */
        advice_id?: string;
        /** 评价内容 */
        content?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listAdviceRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name ListAddressBook
     * @summary 查询通讯录
     * @request POST:/dteqms/listAddressBook
     * @secure
     */
    listAddressBook: (
      query?: {
        /**
         * id
         * @format int32
         */
        id?: number;
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone?: string;
        /**
         * 单位类型
         * @format int32
         */
        type?: number;
        /**
         * 单位id
         * @format int32
         */
        dept_id?: number;
        /**
         * 页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 每页数量
         * @format int32
         * @default 15
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/listAddressBook`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListAddressBookApp
     * @summary app通讯录
     * @request POST:/dteqms/listAddressBookApp
     * @secure
     */
    listAddressBookApp: (
      query?: {
        /** 姓名 */
        us_name?: string;
        /** 所属单位 */
        dept_id?: string;
        /** 职务 */
        role_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listAddressBookApp`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name ListAddressBook2
     * @summary 通讯录查询
     * @request POST:/dteqms/listAddressBook2
     * @secure
     */
    listAddressBook2: (
      query?: {
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone?: string;
        /** 单位类型 */
        type?: string;
        /** 单位名称 */
        dept_id?: string;
        /**
         * 当前页码
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * 页面大小
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/listAddressBook2`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoWarningCase
     * @summary 警示案列详情
     * @request POST:/dteqms/infoWarningCase
     * @secure
     */
    infoWarningCase: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 标题 */
        title?: string;
        /** 内容 */
        content?: string;
        /** 附件地址 */
        attachment?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoWarningCase`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name InfoUserSign
     * @summary 用户当天签到信息
     * @request POST:/dteqms/infoUserSign
     * @secure
     */
    infoUserSign: (params: RequestParams = {}) =>
      this.request<JSONObject, any>({
        path: `/dteqms/infoUserSign`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoTechnologyHelp
     * @summary 技术求助详情
     * @request POST:/dteqms/infoTechnologyHelp
     * @secure
     */
    infoTechnologyHelp: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 标题 */
        title?: string;
        /** 求助内容 */
        content?: string;
        /** 是否愿意付费解决 */
        is_pay?: string;
        /** 是否匿名 */
        is_anony?: string;
        /** 状态 */
        status?: string;
        /** 附件 */
        attachment?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoTechnologyHelp`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoTechnologyHelpRecord
     * @summary 技术求助记录详情
     * @request POST:/dteqms/infoTechnologyHelpRecord
     * @secure
     */
    infoTechnologyHelpRecord: (
      query?: {
        /** 技术求助记录ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 技术求助ID */
        help_id?: string;
        /** 评价内容 */
        content?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoTechnologyHelpRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoSystemNotice
     * @summary 系统通知详情
     * @request POST:/dteqms/infoSystemNotice
     * @secure
     */
    infoSystemNotice: (
      query?: {
        /** id */
        id?: string;
        /** 创建用户id */
        create_user?: string;
        /** 更新用户id */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标题 */
        title?: string;
        /** 内容 */
        content?: string;
        /** 有效时间 */
        period_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoSystemNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoSafetyUserSign
     * @summary 安全员打卡详情
     * @request POST:/dteqms/infoSafetyUserSign
     * @secure
     */
    infoSafetyUserSign: (
      query?: {
        /** 签到类型 */
        type?: string;
        /** 签到图片 */
        img?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标段id */
        project_id?: string;
        /** 审核用户id */
        check_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoSafetyUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoSafetyTraining
     * @summary 安全培训详情
     * @request POST:/dteqms/infoSafetyTraining
     * @secure
     */
    infoSafetyTraining: (
      query?: {
        /** 审核建议 */
        check_advise?: string;
        /** 培训名称 */
        train_name?: string;
        /** 进场类别 */
        entry_category?: string;
        /** 培训类型 */
        train_type?: string;
        /** 培训形式 */
        train_format?: string;
        /** 会议时间 */
        meeting_time?: string;
        /** 培训内容简介 */
        train_desc?: string;
        /** 现场照片 */
        scene_photos?: string;
        /** 签到表照片 */
        sign_photos?: string;
        /** 附件 */
        attachment?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 标段id */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoSafetyTraining`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoSafetyReform
     * @summary 安全整改详情
     * @request POST:/dteqms/infoSafetyReform
     * @secure
     */
    infoSafetyReform: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 标题 */
        title?: string;
        /** 整改状态 */
        reform_status?: string;
        /** 整改截止时间 */
        reform_end_time?: string;
        /** 整改完成时间 */
        reform_finish_time?: string;
        /** 问题描述 */
        question_desc?: string;
        /** 整改要求 */
        requirements?: string;
        /** 整改前照片 */
        pre_photos?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 标段ID */
        project_id?: string;
        /** 单位ID */
        dept_id?: string;
        /** 整改人ID */
        reform_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoSafetyReform`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags check-controller
     * @name InfoRejectCnt
     * @summary 用户发起被驳回的数据总数
     * @request POST:/dteqms/infoRejectCnt
     * @secure
     */
    infoRejectCnt: (params: RequestParams = {}) =>
      this.request<JSONObject, any>({
        path: `/dteqms/infoRejectCnt`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoProjectReport
     * @summary 项目通报详情
     * @request POST:/dteqms/infoProjectReport
     * @secure
     */
    infoProjectReport: (
      query?: {
        /** 标题 */
        title?: string;
        /** 通报类型 */
        type?: string;
        /** 通报描述 */
        content?: string;
        /** 发布状态 */
        publish_status?: string;
        /** 审核状态 */
        check_state?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
        /** 审核建议 */
        check_advise?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoProjectReport`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoPersonalLedger
     * @summary 人员台账详情
     * @request POST:/dteqms/infoPersonalLedger
     * @secure
     */
    infoPersonalLedger: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 设备名称 */
        name?: string;
        /** 操作员 */
        operator?: string;
        /** 联系方式 */
        contact?: string;
        /** 事由 */
        reason?: string;
        /** 进场时间 */
        in_time?: string;
        /** 离场时间 */
        out_time?: string;
        /** 附件地址 */
        attachment?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoPersonalLedger`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoPenaltyNotice
     * @summary 处罚公示详情
     * @request POST:/dteqms/infoPenaltyNotice
     * @secure
     */
    infoPenaltyNotice: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 工程ID */
        project_id?: string;
        /** 案由 */
        cause_action?: string;
        /** 处罚决定书编号 */
        penalty_number?: string;
        /** 处罚决定内容 */
        penalty_desc?: string;
        /** 执行情况 */
        execute_state?: string;
        /** 处罚类别 */
        penalty_type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 处罚单位ID */
        dept_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoPenaltyNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoMeetingNotice
     * @summary 会议通知详情
     * @request POST:/dteqms/infoMeetingNotice
     * @secure
     */
    infoMeetingNotice: (
      query?: {
        /** 会议标题 */
        m_title?: string;
        /** 会议内容 */
        m_content?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 会议时间 */
        m_time?: string;
        /** 会议地点 */
        m_address?: string;
        /** 现场照片地址 */
        m_scence_picture?: string;
        /** 附件 */
        attachment?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
        /** 是否包含施工安全方面内容 */
        has_safety_content?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoMeetingNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoLaw
     * @summary 法律法规详情
     * @request POST:/dteqms/infoLaw
     * @secure
     */
    infoLaw: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 栏目类型 */
        column_type?: string;
        /** 编号 */
        code?: string;
        /** 名称 */
        name?: string;
        /** 内容 */
        content?: string;
        /** 附件地址 */
        attachment?: string;
        /** 实施日期 */
        implement_date?: string;
        /** 颁布日期 */
        promulgate_date?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoLaw`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoLawRecord
     * @summary 法律法规建议详情
     * @request POST:/dteqms/infoLawRecord
     * @secure
     */
    infoLawRecord: (
      query?: {
        /** 建议内容 */
        content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 法律法规ID */
        law_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoLawRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoIndustryExplore
     * @summary 先行者探索详情
     * @request POST:/dteqms/infoIndustryExplore
     * @secure
     */
    infoIndustryExplore: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 标题 */
        title?: string;
        /** 解决问题描述 */
        content?: string;
        /** 是否支持App操作 */
        is_support_app?: string;
        /** 学习成本 */
        learning_cost?: string;
        /** 轮播图片地址 */
        turning_picture?: string;
        /** 其他图片地址 */
        other_picture?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoIndustryExplore`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoHelpCheck
     * @summary 帮扶检查详情
     * @request POST:/dteqms/infoHelpCheck
     * @secure
     */
    infoHelpCheck: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 整改截止时间 */
        reform_end_time?: string;
        /** 整改完成时间 */
        reform_finish_time?: string;
        /** 问题描述 */
        question_desc?: string;
        /** 整改要求 */
        requirements?: string;
        /** 整改前照片 */
        pre_photos?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 标段ID */
        project_id?: string;
        /** 单位ID */
        dept_id?: string;
        /** 整改人ID */
        reform_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoHelpCheck`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoHazardProject
     * @summary 危大工程详情
     * @request POST:/dteqms/infoHazardProject
     * @secure
     */
    infoHazardProject: (
      query?: {
        /** 单位名称 */
        dept_id?: string;
        /** 标段名称 */
        project_id?: string;
        /** 名称 */
        name?: string;
        /** 级别 */
        level?: string;
        /** 交底/验收状态 */
        status?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 位置1 */
        position_1?: string;
        /** 位置2 */
        position_2?: string;
        /** 位置3 */
        position_3?: string;
        /** 位置4 */
        position_4?: string;
        /** 验收记录 */
        acc_record?: string;
        /** 专项方案审批记录 */
        approval_record?: string;
        /** 专项方案论证记录 */
        rationale_record?: string;
        /** 不停航施工组织方案批准记录 */
        constr_approval_record?: string;
        /** 航行通告(不停航施工） */
        navigation_bulletin?: string;
        /** 安全保证责任书 */
        safety_res_letter?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoHazardProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoExamStore
     * @summary 题库详情
     * @request POST:/dteqms/infoExamStore
     * @secure
     */
    infoExamStore: (
      query?: {
        /** 题目分类 */
        type?: string;
        /** 题目类型 */
        category?: string;
        /** 问题 */
        question?: string;
        /** 正确答案 */
        answer?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoExamStore`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoExamStoreMe
     * @summary 学习笔记-错题详情
     * @request POST:/dteqms/infoExamStoreMe
     * @secure
     */
    infoExamStoreMe: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 类型 */
        type?: string;
        /** 题目ID */
        es_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoExamStoreMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoExamRecord
     * @summary 考试记录详情
     * @request POST:/dteqms/infoExamRecord
     * @secure
     */
    infoExamRecord: (
      query?: {
        /** 考试类型 */
        exam_type?: string;
        /** 考试分数 */
        score?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoExamRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoExamConf
     * @summary 题库配置详情
     * @request POST:/dteqms/infoExamConf
     * @secure
     */
    infoExamConf: (
      query?: {
        /** 每题分数 */
        score?: string;
        /** 题目数量 */
        cnt?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoExamConf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoCraftsmanship
     * @summary 行业匠心
     * @request POST:/dteqms/infoCraftsmanship
     * @secure
     */
    infoCraftsmanship: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 标题 */
        title?: string;
        /** 解决问题描述 */
        content?: string;
        /** 学习成本 */
        learning_cost?: string;
        /** 轮播图片 */
        turning_picture?: string;
        /** 其他图片地址 */
        other_picture?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
        /** 是否支持App操作 */
        is_support_app?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoCraftsmanship`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoConveningOfMeeting
     * @summary 会议召集详情
     * @request POST:/dteqms/infoConveningOfMeeting
     * @secure
     */
    infoConveningOfMeeting: (
      query?: {
        /** 会议标题 */
        m_title?: string;
        /** 会议内容 */
        m_content?: string;
        /** 会议时间 */
        m_time?: string;
        /** 会议地点 */
        m_address?: string;
        /** 现场照片地址 */
        m_scence_picture?: string;
        /** 附件 */
        attachment?: string;
        /** 是否包含施工安全方面内容 */
        has_safety_content?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 单位类型 */
        dept_type?: string;
        /** 工程ID */
        project_id?: string;
        /** 类型 */
        type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoConveningOfMeeting`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoCollectStoreMe
     * @summary 学习笔记-收藏详情
     * @request POST:/dteqms/infoCollectStoreMe
     * @secure
     */
    infoCollectStoreMe: (
      query?: {
        /** 类型 */
        type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 题目ID */
        es_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoCollectStoreMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags check-controller
     * @name InfoCheckAuditingDetail
     * @summary 功能明细
     * @request POST:/dteqms/infoCheckAuditingDetail
     * @secure
     */
    infoCheckAuditingDetail: (
      query: {
        /** 表名 */
        table_name: string;
        /** ID */
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/infoCheckAuditingDetail`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseUser
     * @summary 用户详情
     * @request POST:/dteqms/infoBaseUser
     * @secure
     */
    infoBaseUser: (
      query?: {
        /** 单位ID */
        dept_id?: string;
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone?: string;
        /** 密码 */
        pwd?: string;
        /** 传真电话 */
        tel?: string;
        /** E-Mail */
        email?: string;
        /** 地址 */
        address?: string;
        /** 性别 */
        gender?: string;
        /** 备注 */
        remark?: string;
        /** 昵称 */
        nick_name?: string;
        /** 头像 */
        avatar?: string;
        /** 生日 */
        birthday?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseUserSign
     * @summary 上班签到详情
     * @request POST:/dteqms/infoBaseUserSign
     * @secure
     */
    infoBaseUserSign: (
      query?: {
        /** 签到类型 */
        type?: string;
        /** 签到图片 */
        img?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标段id */
        project_id?: string;
        /** 审核用户id */
        check_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseUserRole
     * @summary 用户角色关系详情
     * @request POST:/dteqms/infoBaseUserRole
     * @secure
     */
    infoBaseUserRole: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户ID */
        uid?: string;
        /** 角色ID */
        role_id?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseUserRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseSectionWork
     * @summary 工作区详情
     * @request POST:/dteqms/infoBaseSectionWork
     * @secure
     */
    infoBaseSectionWork: (
      query?: {
        /** 名称 */
        name?: string;
        /** 工作区范围 */
        map_range?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标段工法ID */
        section_method_id?: string;
        /** 标段ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseSectionWork`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseSectionMethod
     * @summary 标段工法详情
     * @request POST:/dteqms/infoBaseSectionMethod
     * @secure
     */
    infoBaseSectionMethod: (
      query?: {
        /** 工法类型 */
        method_type?: string;
        /** 标段ID */
        project_id?: string;
        /** 施工组织设计 */
        design?: string;
        /** 详细施工方案 */
        scheme?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseSectionMethod`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseRole
     * @summary 角色详情
     * @request POST:/dteqms/infoBaseRole
     * @secure
     */
    infoBaseRole: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 描述 */
        memo?: string;
        /** 角色授权 */
        grants?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 单位ID */
        dept_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseRoleDeptType
     * @summary 角色与单位类型关系表详情
     * @request POST:/dteqms/infoBaseRoleDeptType
     * @secure
     */
    infoBaseRoleDeptType: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 单位类型(121) */
        dept_type?: string;
        /** 授权 */
        grants?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseRoleDeptType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseRegion
     * @summary 区域详情
     * @request POST:/dteqms/infoBaseRegion
     * @secure
     */
    infoBaseRegion: (
      query?: {
        /** 名称 */
        name?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 父ID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseRegion`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseProject7
     * @summary 分项详情
     * @request POST:/dteqms/infoBaseProject7
     * @secure
     */
    infoBaseProject7: (
      query?: {
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseProject7`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseProject6
     * @summary 标段详情
     * @request POST:/dteqms/infoBaseProject6
     * @secure
     */
    infoBaseProject6: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseProject6`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseProject5
     * @summary 监理标段详情
     * @request POST:/dteqms/infoBaseProject5
     * @secure
     */
    infoBaseProject5: (
      query?: {
        /** 监理标段名称 */
        name?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseProject5`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseProject4
     * @summary 分部详情
     * @request POST:/dteqms/infoBaseProject4
     * @secure
     */
    infoBaseProject4: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseProject4`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseProject3
     * @summary 项目详情
     * @request POST:/dteqms/infoBaseProject3
     * @secure
     */
    infoBaseProject3: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseProject3`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseProject2
     * @summary 工程详情
     * @request POST:/dteqms/infoBaseProject2
     * @secure
     */
    infoBaseProject2: (
      query?: {
        /** 所属区域 */
        pid?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 备注 */
        remark?: string;
        /** 工程状态 */
        finish_status?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 层级 */
        level?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseProject2`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseNotice
     * @summary 系统通知详情
     * @request POST:/dteqms/infoBaseNotice
     * @secure
     */
    infoBaseNotice: (
      query?: {
        /** 处理状态 */
        deal_state?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户ID */
        uid?: string;
        /** 会议ID */
        mid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseMethodType
     * @summary 工法类型详情
     * @request POST:/dteqms/infoBaseMethodType
     * @secure
     */
    infoBaseMethodType: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseMethodType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseFunctUserCommon
     * @summary 用户常用功能配置详情
     * @request POST:/dteqms/infoBaseFunctUserCommon
     * @secure
     */
    infoBaseFunctUserCommon: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户ID */
        user_id?: string;
        /** 功能ID */
        funct_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseFunctUserCommon`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoBaseDept
     * @summary 单位详情
     * @request POST:/dteqms/infoBaseDept
     * @secure
     */
    infoBaseDept: (
      query?: {
        /** 单位名称 */
        name?: string;
        /** 所属管理局 */
        admin_room?: string;
        /** 所属地区质检站 */
        quality_station?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** 所属地区ID */
        region_id?: string;
        /** 父ID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoBaseDept`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoAdvice
     * @summary 意见征求详情
     * @request POST:/dteqms/infoAdvice
     * @secure
     */
    infoAdvice: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 标题 */
        title?: string;
        /** 内容 */
        content?: string;
        /** 附件 */
        attachment?: string;
        /** 截止时间 */
        end_time?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoAdvice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoAdviceRecord
     * @summary 意见征求评价详情
     * @request POST:/dteqms/infoAdviceRecord
     * @secure
     */
    infoAdviceRecord: (
      query?: {
        /** 评价内容 */
        content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 意见征求ID */
        advice_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoAdviceRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name InfoAddressBook
     * @summary 通讯录详情
     * @request POST:/dteqms/infoAddressBook
     * @secure
     */
    infoAddressBook: (
      query?: {
        /** 通讯录ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 所属工程 */
        project_id?: string;
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone?: string;
        /** 单位ID */
        dept_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/infoAddressBook`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags export-controller
     * @name ImportTrainTemplate
     * @summary 上传自定义安全培训模版
     * @request POST:/dteqms/import_train_template
     * @secure
     */
    importTrainTemplate: (
      query: {
        /**
         * 进场类别
         * @format int32
         */
        entry_category: number;
        /**
         * 培训类型
         * @format int32
         */
        train_type: number;
        /** 模版url */
        train_template_url: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/import_train_template`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags export-controller
     * @name ImportAddressBook
     * @summary 导入通讯录
     * @request POST:/dteqms/import_addressBook
     * @secure
     */
    importAddressBook: (
      query?: {
        /** 通讯录信息json */
        addressBookset?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/import_addressBook`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags export-controller
     * @name ImportQuestions
     * @summary 导入题
     * @request POST:/dteqms/importQuestions
     * @secure
     */
    importQuestions: (
      query?: {
        /** 题json */
        questionSet?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/importQuestions`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags export-controller
     * @name HazardProjectCount
     * @summary 危大工程统计导出
     * @request POST:/dteqms/hazard_project_count
     * @secure
     */
    hazardProjectCount: (
      query?: {
        /** 开始时间 */
        time_beg?: string;
        /** 结束时间 */
        time_end?: string;
        /**
         * 标段
         * @format int32
         */
        project_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/hazard_project_count`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags export-controller
     * @name ExpTrainCount
     * @summary 安全培训统计导出
     * @request POST:/dteqms/exp_train_count
     * @secure
     */
    expTrainCount: (
      query: {
        /**
         * id
         * @format int32
         */
        id: number;
        /** 开始时间 */
        time_beg?: string;
        /** 结束时间 */
        time_end?: string;
        /**
         * 所属监理标段
         * @format int32
         */
        project_id5?: number;
        /**
         * 所属标段
         * @format int32
         */
        project_id?: number;
        /** 培训名称 */
        train_name?: string;
        /**
         * 进场类别
         * @format int32
         */
        entry_category: number;
        /**
         * 培训类型
         * @format int32
         */
        train_type: number;
        /**
         * 审核状态
         * @format int32
         */
        check_state?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/exp_train_count`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags export-controller
     * @name ExpSafetyReform
     * @summary 问题整改统计导出
     * @request POST:/dteqms/exp_safety_reform
     * @secure
     */
    expSafetyReform: (
      query?: {
        /** 开始时间 */
        time_beg?: string;
        /** 结束时间 */
        time_end?: string;
        /**
         * 所属标段
         * @format int32
         */
        project_id?: number;
        /**
         * 所属单位
         * @format int32
         */
        dept_id?: number;
        /** 标题 */
        title?: string;
        /**
         * 整改状态
         * @format int32
         */
        reform_status?: number;
        /**
         * 审核状态
         * @format int32
         */
        check_state?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/exp_safety_reform`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags export-controller
     * @name ExpHelpData
     * @summary 帮扶统计导出
     * @request POST:/dteqms/exp_help_data
     * @secure
     */
    expHelpData: (
      query?: {
        /** 开始时间 */
        check_date_beg?: string;
        /** 结束时间 */
        check_date_end?: string;
        /**
         * 所属标段
         * @format int32
         */
        project_id?: number;
        /**
         * 单位类型
         * @format int32
         */
        type?: number;
        /**
         * 所属单位
         * @format int32
         */
        dept_id?: number;
        /**
         * 整改状态
         * @format int32
         */
        reform_status?: number;
        /**
         * 检查形式
         * @format int32
         */
        check_form?: number;
        /** 问题描述 */
        question_desc?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/exp_help_data`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags export-controller
     * @name ExpMeetingMinutes
     * @summary 导出会议纪要
     * @request POST:/dteqms/expMeetingMinutes
     * @secure
     */
    expMeetingMinutes: (
      query: {
        /**
         * 会议id
         * @format int32
         */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/expMeetingMinutes`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name ExamResult
     * @summary 考试结果
     * @request POST:/dteqms/exam_result
     * @secure
     */
    examResult: (
      query: {
        /** 考题集合 */
        questionSet: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/exam_result`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name ExamQuestionQuery
     * @summary 考试题
     * @request POST:/dteqms/exam_question_query
     * @secure
     */
    examQuestionQuery: (
      query: {
        /**
         * 题目分类(1,每日一学2,生活常识)
         * @format int32
         */
        type: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/exam_question_query`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditWarningCase
     * @summary 编辑警示案列
     * @request POST:/dteqms/editWarningCase
     * @secure
     */
    editWarningCase: (
      query: {
        /** 标题 */
        title: string;
        /** 内容 */
        content: string;
        /** 附件 */
        attachment: string;
        /** 可见范围 */
        visibility: string;
        /** 所属项目 */
        visibility_project?: string;
        /** 所属地区 */
        visibility_region?: string;
        /** 审核状态 */
        check_state?: string;
        /** 数据状态 */
        data_state: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程名称 */
        project_id?: string;
        /** 审核建议 */
        check_advise?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editWarningCase`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditTechnologyHelp
     * @summary 编辑技术求助
     * @request POST:/dteqms/editTechnologyHelp
     * @secure
     */
    editTechnologyHelp: (
      query: {
        /** 标题 */
        title: string;
        /** 求助内容 */
        content: string;
        /** 是否愿意付费解决 */
        is_pay: string;
        /** 付费详情 */
        pay_details?: string;
        /** 是否匿名 */
        is_anony: string;
        /** 求助附件 */
        attachment?: string;
        /** 解决状态 */
        status: string;
        /** 可见范围 */
        visibility: string;
        /** 所属地区 */
        visibility_region?: string;
        /** 所属项目 */
        visibility_project?: string;
        /** 审核状态 */
        check_state?: string;
        /** 数据状态 */
        data_state: string;
        /** 所属工程 */
        project_id?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editTechnologyHelp`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditTechnologyHelpRecord
     * @summary 编辑技术求助记录
     * @request POST:/dteqms/editTechnologyHelpRecord
     * @secure
     */
    editTechnologyHelpRecord: (
      query?: {
        /** 技术求助记录ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 技术求助ID */
        help_id?: string;
        /** 是否匿名 */
        is_anony?: string;
        /** 附件 */
        attachment?: string;
        /** 评价内容 */
        content?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editTechnologyHelpRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditTechnicalSolveState
     * @summary 编辑技术求助解决状态
     * @request POST:/dteqms/editTechnicalSolveState
     * @secure
     */
    editTechnicalSolveState: (
      query?: {
        /** id */
        id?: string;
        /** 解决状态 */
        status?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editTechnicalSolveState`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditSystemNotice
     * @summary 编辑系统通知
     * @request POST:/dteqms/editSystemNotice
     * @secure
     */
    editSystemNotice: (
      query: {
        /** 标题 */
        title: string;
        /** 内容 */
        content: string;
        /** 有效时间 */
        period_time: string;
        /** id */
        id?: string;
        /** 创建用户id */
        create_user?: string;
        /** 更新用户id */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editSystemNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditSafetyUserSign
     * @summary 编辑加安全员打卡
     * @request POST:/dteqms/editSafetyUserSign
     * @secure
     */
    editSafetyUserSign: (
      query?: {
        /** 签到图片 */
        img?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 签到类型 */
        type?: string;
        /** 标段id */
        project_id?: string;
        /** 审核用户id */
        check_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editSafetyUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditSafetyTraining
     * @summary 编辑安全培训
     * @request POST:/dteqms/editSafetyTraining
     * @secure
     */
    editSafetyTraining: (
      query: {
        /** 培训名称 */
        train_name: string;
        /** 所属监理标段 */
        project_id5: string;
        /** 所属标段 */
        project_id: string;
        /** 进场类别 */
        entry_category: string;
        /** 培训类型 */
        train_type: string;
        /** 备注 */
        remark?: string;
        /** 培训形式 */
        train_format?: string;
        /** 培训时间 */
        meeting_time?: string;
        /** 培训内容简介 */
        train_desc?: string;
        /** 现场照片 */
        scene_photos?: string;
        /** 签到表照片 */
        sign_photos?: string;
        /** 附件 */
        attachment?: string;
        /** 数据状态 */
        data_state: string;
        /** ID */
        id?: string;
        /** 培训单位 */
        dept_id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 审核状态 */
        check_state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editSafetyTraining`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name EditSafetyReform
     * @summary 编辑安全整改
     * @request POST:/dteqms/editSafetyReform
     * @secure
     */
    editSafetyReform: (
      query: {
        /**
         * id
         * @format int32
         */
        id: number;
        /**
         * 数据状态
         * @format int32
         */
        data_state?: number;
        /**
         * 审核状态
         * @format int32
         */
        check_state?: number;
        /**
         * 审核用户id
         * @format int32
         */
        check_uid?: number;
        /** 审核建议 */
        check_advise?: string;
        /**
         * 所属标段
         * @format int32
         */
        project_id?: number;
        /**
         * 单位
         * @format int32
         */
        dept_id?: number;
        /** 标题 */
        title?: string;
        /** 问题描述 */
        question_desc?: string;
        /** 整改要求 */
        requirements?: string;
        /** 整改截止时间 */
        reform_end_time?: string;
        /** 整改前照片 */
        pre_photos?: string;
        /**
         * 整改人
         * @format int32
         */
        reform_uid?: number;
        /** 整改完成时间 */
        reform_finish_time?: string;
        /** 整改后照片 */
        suf_photos?: string;
        /**
         * 整改状态
         * @format int32
         */
        reform_status?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/editSafetyReform`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditSafetyReformAssign
     * @summary 问题整改接收
     * @request POST:/dteqms/editSafetyReformAssign
     * @secure
     */
    editSafetyReformAssign: (
      query: {
        /** 接收人 */
        reform_uid: string;
        /** 整改建议 */
        rect_advise?: string;
        /** ID */
        id?: string;
        /** 整改状态 */
        reform_status?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editSafetyReformAssign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditProjectReport
     * @summary 编辑项目通报
     * @request POST:/dteqms/editProjectReport
     * @secure
     */
    editProjectReport: (
      query: {
        /** 标题 */
        title: string;
        /** 通报类型 */
        type: string;
        /** 通报描述 */
        content: string;
        /** 附件 */
        attachment?: string;
        /** 可见范围 */
        visibility: string;
        /** 所属地区 */
        visibility_region?: string;
        /** 所属项目 */
        visibility_project?: string;
        /** 数据状态 */
        data_state: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 工程ID */
        project_id?: string;
        /** 发布状态 */
        publish_status?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editProjectReport`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name EditProblemReform
     * @summary 编辑问题整改
     * @request POST:/dteqms/editProblemReform
     * @secure
     */
    editProblemReform: (
      query: {
        /** id */
        id: string;
        /** 整改后照片 */
        suf_photos?: string;
        /** 整改完成时间 */
        reform_finish_time?: string;
        /** 整改描述 */
        rect_desc?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/editProblemReform`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditPersonalLedger
     * @summary 编辑人员台账
     * @request POST:/dteqms/editPersonalLedger
     * @secure
     */
    editPersonalLedger: (
      query: {
        /** 所属标段 */
        project_id: string;
        /** 台账类型 */
        type: string;
        /** 附件 */
        attachment: string;
        /** 创建时间 */
        create_time?: string;
        /** 创建人 */
        create_user?: string;
        /** 数据状态 */
        data_state: string;
        /** ID */
        id?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 设备名称 */
        name?: string;
        /** 操作员 */
        operator?: string;
        /** 联系方式 */
        contact?: string;
        /** 事由 */
        reason?: string;
        /** 进场时间 */
        in_time?: string;
        /** 离场时间 */
        out_time?: string;
        /** 备注 */
        remark?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editPersonalLedger`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditPenaltyNotice
     * @summary 编辑处罚公示
     * @request POST:/dteqms/editPenaltyNotice
     * @secure
     */
    editPenaltyNotice: (
      query: {
        /** 处罚类别 */
        penalty_type: string;
        /** 案由 */
        cause_action: string;
        /** 处罚单位 */
        dept_id: string;
        /** 处罚决定书编号 */
        penalty_number: string;
        /** 执行情况 */
        execute_state: string;
        /** 可见范围 */
        visibility: string;
        /** 所属地区 */
        visibility_region?: string;
        /** 所属项目 */
        visibility_project?: string;
        /** 处罚决定内容 */
        penalty_desc: string;
        /** 附件 */
        attachment?: string;
        /** 数据状态 */
        data_state: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 处罚工程 */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editPenaltyNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditOffShelfProjectReport
     * @summary 项目通报下架
     * @request POST:/dteqms/editOffShelfProjectReport
     * @secure
     */
    editOffShelfProjectReport: (
      query?: {
        /** id */
        id?: string;
        /** 状态 */
        check_state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editOffShelfProjectReport`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditLaw
     * @summary 编辑法律法规
     * @request POST:/dteqms/editLaw
     * @secure
     */
    editLaw: (
      query: {
        /** 栏目类型 */
        column_type: string;
        /** 标题 */
        name: string;
        /** 编号 */
        code?: string;
        /** 内容 */
        content?: string;
        /** 附件 */
        attachment: string;
        /** 实施日期 */
        implement_date?: string;
        /** 颁布日期 */
        promulgate_date?: string;
        /** 数据状态 */
        data_state: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editLaw`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditLawRecord
     * @summary 编辑法律法规建议
     * @request POST:/dteqms/editLawRecord
     * @secure
     */
    editLawRecord: (
      query?: {
        /** 建议内容 */
        content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 法律法规ID */
        law_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editLawRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditIndustryExplore
     * @summary 编辑先行者探索
     * @request POST:/dteqms/editIndustryExplore
     * @secure
     */
    editIndustryExplore: (
      query: {
        /** 标题 */
        title?: string;
        /** 提出人 */
        proposer: string;
        /** 提出单位 */
        propose_unit: string;
        /** 使用项目数 */
        usepro_number: string;
        /** 学习成本 */
        learning_cost: string;
        /** 解决问题描述 */
        content: string;
        /** 轮播图片 */
        turning_picture: string;
        /** 其他图片 */
        other_picture?: string;
        /** 是否支持App操作 */
        is_support_app?: string;
        /** 数据状态 */
        data_state: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editIndustryExplore`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditHelpCheck
     * @summary 编辑帮扶检查
     * @request POST:/dteqms/editHelpCheck
     * @secure
     */
    editHelpCheck: (
      query: {
        /** 所属标段 */
        project_id: string;
        /** 所属单位 */
        dept_id: string;
        /** 检查日期 */
        check_date: string;
        /** 检查形式 */
        check_form: string;
        /** 备注 */
        remark?: string;
        /** 整改前照片 */
        pre_photos: string;
        /** 问题描述 */
        question_desc: string;
        /** 依据 */
        basis?: string;
        /** 整改后照片 */
        suf_photos?: string;
        /** 整改描述 */
        requirements?: string;
        /** 整改日期 */
        reform_finish_time?: string;
        /** 整改状态 */
        status?: string;
        /** 数据状态 */
        data_state: string;
        /** 标题 */
        title?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 整改状态 */
        reform_status?: string;
        /** 审核建议 */
        check_advise?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 整改截止时间 */
        reform_end_time?: string;
        /** 整改人 */
        reform_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editHelpCheck`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditHazardProject
     * @summary 编辑危大工程
     * @request POST:/dteqms/editHazardProject
     * @secure
     */
    editHazardProject: (
      query: {
        /** 所属标段 */
        project_id: string;
        /** 施工单位名称 */
        dept_id: string;
        /** 名称 */
        name: string;
        /** 级别 */
        level: string;
        /** 专项方案审批记录 */
        approval_record?: string;
        /** 专项方案论证记录 */
        rationale_record?: string;
        /** 不停航施工组织方案批准记录 */
        constr_approval_record?: string;
        /** 航行通告 */
        navigation_bulletin?: string;
        /** 安全保证责任书 */
        safety_res_letter?: string;
        /** 其他 */
        other?: string;
        /** 交底记录 */
        dis_record?: string;
        /** 审批记录 */
        exam_approve_record?: string;
        /** 专职安管人员 */
        appoint_person?: string;
        /** 数据状态 */
        data_state: string;
        /** 危大工程分项 */
        itemize?: string;
        /** 交底/验收状态 */
        status?: string;
        /** 交底状态 */
        delivery_status?: string;
        /** 验收状态 */
        acc_record_status?: string;
        /** 位置1 */
        position_1?: string;
        /** 位置2 */
        position_2?: string;
        /** 位置3 */
        position_3?: string;
        /** 位置4 */
        position_4?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 验收记录 */
        acc_record?: string;
        /** 审批状态 */
        approval_state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editHazardProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditExamStore
     * @summary 编辑题库
     * @request POST:/dteqms/editExamStore
     * @secure
     */
    editExamStore: (
      query: {
        /** 题目分类 */
        type: string;
        /** 题目类型 */
        category: string;
        /** 问题 */
        question: string;
        /** 正确答案 */
        answer: string;
        /** 选项 */
        option: string;
        /** 出处 */
        source?: string;
        /** 章节 */
        chapter?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editExamStore`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditExamStoreMe
     * @summary 编辑学习笔记-错题
     * @request POST:/dteqms/editExamStoreMe
     * @secure
     */
    editExamStoreMe: (
      query?: {
        /** 类型 */
        type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 题目ID */
        es_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editExamStoreMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditExamRecord
     * @summary 编辑考试记录
     * @request POST:/dteqms/editExamRecord
     * @secure
     */
    editExamRecord: (
      query?: {
        /** 考试类型 */
        exam_type?: string;
        /** 考试分数 */
        score?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editExamRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditExamConf
     * @summary 编辑题库配置
     * @request POST:/dteqms/editExamConf
     * @secure
     */
    editExamConf: (
      query?: {
        /** 题目分类 */
        type?: string;
        /** 每题分数 */
        score?: string;
        /** 题目数量 */
        cnt?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editExamConf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditCraftsmanship
     * @summary 行业匠心
     * @request POST:/dteqms/editCraftsmanship
     * @secure
     */
    editCraftsmanship: (
      query: {
        /** 标题 */
        title: string;
        /** 提出人 */
        proposer: string;
        /** 提出单位 */
        propose_unit: string;
        /** 使用项目数 */
        usepro_number: string;
        /** 是否支持App操作 */
        is_support_app?: string;
        /** 学习成本 */
        learning_cost: string;
        /** 解决问题描述 */
        content: string;
        /** 轮播图片 */
        turning_picture: string;
        /** 其他图片 */
        other_picture?: string;
        /** 数据状态 */
        data_state: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editCraftsmanship`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name EditConveneNotice
     * @summary 编辑安全会议召集
     * @request POST:/dteqms/editConveneNotice
     * @secure
     */
    editConveneNotice: (
      query?: {
        /**
         * 数据状态
         * @format int32
         */
        data_state?: number;
        /**
         * 审核状态
         * @format int32
         */
        check_state?: number;
        /**
         * 审核用户id
         * @format int32
         */
        check_uid?: number;
        /** 审核建议 */
        check_advise?: string;
        /**
         * 工程id
         * @format int32
         */
        project_id?: number;
        /**
         * 类型
         * @format int32
         */
        type?: number;
        /** 会议标题 */
        m_title?: string;
        /** 会议内容 */
        m_content?: string;
        /** 会议时间 */
        m_time?: string;
        /** 会议地址 */
        m_address?: string;
        /** 现场照片 */
        m_scence_picture?: string;
        /** 附件 */
        attachment?: string;
        /**
         * 是否包含施工安全方面
         * @format int32
         */
        has_safety_content?: number;
        /** 与会人 */
        attendees?: string;
        /**
         * 短信并行通知
         * @format int32
         */
        mes_notice?: number;
        /** 签到表 */
        att_sheet?: string;
        /**
         * id
         * @format int32
         */
        id?: number;
        /**
         * 单位
         * @format int32
         */
        dept_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/editConveneNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name EditConferenceNotice
     * @summary 编辑会议通知
     * @request POST:/dteqms/editConferenceNotice
     * @secure
     */
    editConferenceNotice: (
      query?: {
        /**
         * 数据状态
         * @format int32
         */
        data_state?: number;
        /**
         * 审核状态
         * @format int32
         */
        check_state?: number;
        /**
         * 审核用户id
         * @format int32
         */
        check_uid?: number;
        /** 审核建议 */
        check_advise?: string;
        /**
         * 工程id
         * @format int32
         */
        project_id?: number;
        /**
         * 类型
         * @format int32
         */
        type?: number;
        /** 会议标题 */
        m_title?: string;
        /** 会议内容 */
        m_content?: string;
        /** 会议时间 */
        m_time?: string;
        /** 会议地址 */
        m_address?: string;
        /** 现场照片 */
        m_scence_picture?: string;
        /** 附件 */
        attachment?: string;
        /**
         * 是否包含施工安全方面
         * @format int32
         */
        has_safety_content?: number;
        /** 与会人 */
        attendees?: string;
        /**
         * 短信并行通知
         * @format int32
         */
        mes_notice?: number;
        /** 签到表 */
        att_sheet?: string;
        /**
         * id
         * @format int32
         */
        id?: number;
        /**
         * 单位
         * @format int32
         */
        dept_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/editConferenceNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditCollectStoreMe
     * @summary 编辑学习笔记-收藏
     * @request POST:/dteqms/editCollectStoreMe
     * @secure
     */
    editCollectStoreMe: (
      query?: {
        /** 类型 */
        type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 题目ID */
        es_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editCollectStoreMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name EditBaseUser
     * @summary 编辑用户
     * @request POST:/dteqms/editBaseUser
     * @secure
     */
    editBaseUser: (
      query: {
        /**
         * ID
         * @format int32
         */
        id: number;
        /**
         * 单位名称
         * @format int32
         */
        dept_id?: number;
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone: string;
        /** 电话 */
        tel?: string;
        /** 邮箱 */
        email?: string;
        /** 地址 */
        address?: string;
        /**
         * 性别
         * @format int32
         */
        gender?: number;
        /** 备注 */
        remark?: string;
        /** 头像 */
        avatar?: string;
        /** 生日 */
        birthday?: string;
        /** 昵称 */
        nick_name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/editBaseUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseUserSign
     * @summary 编辑用户签到
     * @request POST:/dteqms/editBaseUserSign
     * @secure
     */
    editBaseUserSign: (
      query?: {
        /** 签到类型 */
        type?: string;
        /** 签到图片 */
        img?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标段id */
        project_id?: string;
        /** 审核用户id */
        check_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseUserRole
     * @summary 编辑用户角色关系
     * @request POST:/dteqms/editBaseUserRole
     * @secure
     */
    editBaseUserRole: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户ID */
        uid?: string;
        /** 角色ID */
        role_id?: string;
        /** 工程ID */
        project_id?: string;
        /** 授权 */
        grants?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseUserRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseSectionWork
     * @summary 编辑工作区
     * @request POST:/dteqms/editBaseSectionWork
     * @secure
     */
    editBaseSectionWork: (
      query: {
        /** 标段 */
        project_id?: string;
        /** 标段工法 */
        section_method_id?: string;
        /** 施工单位 */
        dept_id: string;
        /** 名称 */
        name: string;
        /** 工作区范围 */
        map_range: string;
        /** 附件 */
        attach: string;
        /** 数据状态 */
        data_state: string;
        /** 施工开始日期 */
        start_time: string;
        /** 施工完工日期 */
        end_time: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标段 */
        project_name?: string;
        /** 项目名称 */
        project_name3?: string;
        /** 施工单位 */
        dept_name?: string;
        /** 标段工法 */
        method_type_name?: string;
        /** 完成状态 */
        finish_status?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseSectionWork`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseSectionMethod
     * @summary 编辑标段工法
     * @request POST:/dteqms/editBaseSectionMethod
     * @secure
     */
    editBaseSectionMethod: (
      query: {
        /** 标段 */
        project_id: string;
        /** 工法类型 */
        method_type: string;
        /** 施工组织设计 */
        design?: string;
        /** 详细施工方案 */
        scheme?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseSectionMethod`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseRole
     * @summary 编辑角色
     * @request POST:/dteqms/editBaseRole
     * @secure
     */
    editBaseRole: (
      query: {
        /** 角色名称 */
        name: string;
        /** 描述 */
        memo?: string;
        /** 单位名称 */
        dept_id: string;
        /** 角色授权 */
        grants?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseRoleDeptType
     * @summary 编辑角色与单位类型关系表
     * @request POST:/dteqms/editBaseRoleDeptType
     * @secure
     */
    editBaseRoleDeptType: (
      query: {
        /** 角色模板名称 */
        name: string;
        /** 单位类型 */
        dept_type: string;
        /** 授权 */
        grants: string;
        /** 创建时间 */
        create_time?: string;
        /** ID */
        id?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 更新时间 */
        update_time?: string;
        /** 创建用户ID */
        create_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseRoleDeptType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseRegion
     * @summary 编辑区域
     * @request POST:/dteqms/editBaseRegion
     * @secure
     */
    editBaseRegion: (
      query: {
        /** 地区名称 */
        name: string;
        /** 描述 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 父ID */
        pid?: string;
        /** 排序 */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseRegion`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseProject7
     * @summary 编辑分项
     * @request POST:/dteqms/editBaseProject7
     * @secure
     */
    editBaseProject7: (
      query: {
        /** 分项名称 */
        name: string;
        /** 编码 */
        code: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseProject7`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseProject6
     * @summary 编辑标段
     * @request POST:/dteqms/editBaseProject6
     * @secure
     */
    editBaseProject6: (
      query: {
        /** 标段名称 */
        name: string;
        /** 编码 */
        code: string;
        /** 地图范围 */
        map_range?: string;
        /** 应配备安全人员数量 */
        safety_number?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
        /** 层级 */
        level?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** 定位点坐标(经度,纬度) */
        fixed_position?: string;
        /** 控制点文件 */
        ctrl_file?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseProject6`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseProject5
     * @summary 编辑监理标段
     * @request POST:/dteqms/editBaseProject5
     * @secure
     */
    editBaseProject5: (
      query: {
        /** 监理标段名称 */
        name: string;
        /** 编码 */
        code: string;
        /** 应配备安全人员数量 */
        safety_number?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseProject5`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseProject4
     * @summary 编辑分部
     * @request POST:/dteqms/editBaseProject4
     * @secure
     */
    editBaseProject4: (
      query: {
        /** 分部名称 */
        name: string;
        /** 编码 */
        code: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseProject4`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseProject3
     * @summary 编辑项目
     * @request POST:/dteqms/editBaseProject3
     * @secure
     */
    editBaseProject3: (
      query: {
        /** 项目名称 */
        name: string;
        /** 编码 */
        code: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 项目状态 */
        finish_status?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 备注 */
        remark?: string;
        /** 控制点文件 */
        ctrl_file?: string;
        /** 定位点坐标(经度,纬度) */
        fixed_position?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseProject3`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name EditBaseProject2
     * @summary 编辑工程
     * @request POST:/dteqms/editBaseProject2
     * @secure
     */
    editBaseProject2: (
      query: {
        /** ID */
        id: string;
        /** 所属地区 */
        pid: string;
        /** 工程名称 */
        name: string;
        /** 编码 */
        code: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 地图范围 */
        map_range?: string;
        /** 专职安全管理人员数量 */
        safety_number?: string;
        /** 控制点文件 */
        ctrl_file?: string;
        /** 定位点坐标(经纬度) */
        fixed_position?: string;
        /** 备注 */
        remark?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/editBaseProject2`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseNotice
     * @summary 编辑系统通知
     * @request POST:/dteqms/editBaseNotice
     * @secure
     */
    editBaseNotice: (
      query?: {
        /** 处理状态 */
        deal_state?: string;
        /** 是否参会 */
        is_attend?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户ID */
        uid?: string;
        /** 会议ID */
        mid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseMethodType
     * @summary 编辑工法类型
     * @request POST:/dteqms/editBaseMethodType
     * @secure
     */
    editBaseMethodType: (
      query: {
        /** 工法名称 */
        name: string;
        /** 描述 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 排序 */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseMethodType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseFunctUserCommon
     * @summary 编辑用户常用功能配置
     * @request POST:/dteqms/editBaseFunctUserCommon
     * @secure
     */
    editBaseFunctUserCommon: (
      query: {
        /** 功能 */
        funct_id: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户 */
        user_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseFunctUserCommon`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditBaseDept
     * @summary 编辑单位
     * @request POST:/dteqms/editBaseDept
     * @secure
     */
    editBaseDept: (
      query: {
        /** 所属地区 */
        region_id: string;
        /** 单位类型 */
        type: string;
        /** 单位名称 */
        name: string;
        /** 描述 */
        remark?: string;
        /** 附件 */
        attach?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 父ID */
        pid?: string;
        /** 排序 */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editBaseDept`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditAdvice
     * @summary 编辑意见征求
     * @request POST:/dteqms/editAdvice
     * @secure
     */
    editAdvice: (
      query: {
        /** 标题 */
        title: string;
        /** 内容 */
        content: string;
        /** 截止时间 */
        end_time?: string;
        /** 可见范围 */
        visibility: string;
        /** 所属地区 */
        visibility_region?: string;
        /** 所属项目 */
        visibility_project?: string;
        /** 附件 */
        attachment?: string;
        /** 数据状态 */
        data_state: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 所属工程 */
        project_id?: string;
        /** 审核状态 */
        check_state: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editAdvice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name EditAdviceRecord
     * @summary 编辑意见征求评价
     * @request POST:/dteqms/editAdviceRecord
     * @secure
     */
    editAdviceRecord: (
      query?: {
        /** 评价内容 */
        content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 意见征求ID */
        advice_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/editAdviceRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name EditAddressBook
     * @summary 编辑通讯录
     * @request POST:/dteqms/editAddressBook
     * @secure
     */
    editAddressBook: (
      query: {
        /**
         * ID
         * @format int32
         */
        id: number;
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone?: string;
        /** 职务 */
        duties?: string;
        /**
         * 所属地区
         * @format int32
         */
        region_id?: number;
        /**
         * 单位
         * @format int32
         */
        dept_id?: number;
        /**
         * 项目id
         * @format int32
         */
        project_id3?: number;
        /**
         * 监理标段id
         * @format int32
         */
        project_id5?: number;
        /**
         * 标段id
         * @format int32
         */
        project_id6?: number;
        /**
         * 单位类型
         * @format int32
         */
        type?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/editAddressBook`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteWarningCase
     * @summary 删除警示案列
     * @request POST:/dteqms/deleteWarningCase
     * @secure
     */
    deleteWarningCase: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 标题 */
        title?: string;
        /** 内容 */
        content?: string;
        /** 附件地址 */
        attachment?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteWarningCase`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteTechnologyHelp
     * @summary 删除技术求助
     * @request POST:/dteqms/deleteTechnologyHelp
     * @secure
     */
    deleteTechnologyHelp: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 标题 */
        title?: string;
        /** 求助内容 */
        content?: string;
        /** 是否愿意付费解决 */
        is_pay?: string;
        /** 是否匿名 */
        is_anony?: string;
        /** 状态 */
        status?: string;
        /** 附件地址 */
        attachment?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteTechnologyHelp`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteTechnologyHelpRecord
     * @summary 删除技术求助记录
     * @request POST:/dteqms/deleteTechnologyHelpRecord
     * @secure
     */
    deleteTechnologyHelpRecord: (
      query?: {
        /** 技术求助记录ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 技术求助ID */
        help_id?: string;
        /** 评价内容 */
        content?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteTechnologyHelpRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteSystemNotice
     * @summary 删除系统通知
     * @request POST:/dteqms/deleteSystemNotice
     * @secure
     */
    deleteSystemNotice: (
      query?: {
        /** id */
        id?: string;
        /** 创建用户id */
        create_user?: string;
        /** 更新用户id */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标题 */
        title?: string;
        /** 内容 */
        content?: string;
        /** 有效时间 */
        period_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteSystemNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteSafetyUserSign
     * @summary 删除用户签到
     * @request POST:/dteqms/deleteSafetyUserSign
     * @secure
     */
    deleteSafetyUserSign: (
      query?: {
        /** 签到类型 */
        type?: string;
        /** 签到图片 */
        img?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标段id */
        project_id?: string;
        /** 审核用户id */
        check_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteSafetyUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteSafetyTraining
     * @summary 删除安全培训
     * @request POST:/dteqms/deleteSafetyTraining
     * @secure
     */
    deleteSafetyTraining: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 培训名称 */
        train_name?: string;
        /** 进场类别 */
        entry_category?: string;
        /** 培训类型 */
        train_type?: string;
        /** 培训形式 */
        train_format?: string;
        /** 会议时间 */
        meeting_time?: string;
        /** 培训内容简介 */
        train_desc?: string;
        /** 现场照片 */
        scene_photos?: string;
        /** 签到表照片 */
        sign_photos?: string;
        /** 附件 */
        attachment?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 标段id */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteSafetyTraining`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name DeleteSafetyReform
     * @summary 删除问题整改
     * @request POST:/dteqms/deleteSafetyReform
     * @secure
     */
    deleteSafetyReform: (
      query: {
        /** id */
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/deleteSafetyReform`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteProjectReport
     * @summary 删除项目通报
     * @request POST:/dteqms/deleteProjectReport
     * @secure
     */
    deleteProjectReport: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 通报类型 */
        type?: string;
        /** 标题 */
        title?: string;
        /** 通报描述 */
        content?: string;
        /** 附件地址 */
        attachment?: string;
        /** 发布状态 */
        publish_status?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteProjectReport`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeletePersonalLedger
     * @summary 删除人员台账
     * @request POST:/dteqms/deletePersonalLedger
     * @secure
     */
    deletePersonalLedger: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 设备名称 */
        name?: string;
        /** 操作员 */
        operator?: string;
        /** 联系方式 */
        contact?: string;
        /** 事由 */
        reason?: string;
        /** 附件地址 */
        attachment?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deletePersonalLedger`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeletePenaltyNotice
     * @summary 删除处罚公示
     * @request POST:/dteqms/deletePenaltyNotice
     * @secure
     */
    deletePenaltyNotice: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 案由 */
        cause_action?: string;
        /** 处罚决定书编号 */
        penalty_number?: string;
        /** 处罚决定内容 */
        penalty_desc?: string;
        /** 执行情况 */
        execute_state?: string;
        /** 处罚类别 */
        penalty_type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
        /** 处罚单位ID */
        dept_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deletePenaltyNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteMeetingNotice
     * @summary 删除会议通知
     * @request POST:/dteqms/deleteMeetingNotice
     * @secure
     */
    deleteMeetingNotice: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 会议标题 */
        m_title?: string;
        /** 会议内容 */
        m_content?: string;
        /** 会议时间 */
        m_time?: string;
        /** 会议地点 */
        m_address?: string;
        /** 现场照片地址 */
        m_scence_picture?: string;
        /** 附件 */
        attachment?: string;
        /** 是否包含施工安全方面内容 */
        has_safety_content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteMeetingNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteLaw
     * @summary 删除法律法规
     * @request POST:/dteqms/deleteLaw
     * @secure
     */
    deleteLaw: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 栏目类型 */
        column_type?: string;
        /** 编号 */
        code?: string;
        /** 名称 */
        name?: string;
        /** 内容 */
        content?: string;
        /** 附件地址 */
        attachment?: string;
        /** 实施日期 */
        implement_date?: string;
        /** 颁布日期 */
        promulgate_date?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteLaw`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteLawRecord
     * @summary 删除法律法规建议
     * @request POST:/dteqms/deleteLawRecord
     * @secure
     */
    deleteLawRecord: (
      query?: {
        /** 建议内容 */
        content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 法律法规ID */
        law_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteLawRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteIndustryExplore
     * @summary 删除先行者探索
     * @request POST:/dteqms/deleteIndustryExplore
     * @secure
     */
    deleteIndustryExplore: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 标题 */
        title?: string;
        /** 解决问题描述 */
        content?: string;
        /** 是否支持App操作 */
        is_support_app?: string;
        /** 学习成本 */
        learning_cost?: string;
        /** 轮播图片地址 */
        turning_picture?: string;
        /** 其他图片地址 */
        other_picture?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteIndustryExplore`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteHelpCheck
     * @summary 删除帮扶检查
     * @request POST:/dteqms/deleteHelpCheck
     * @secure
     */
    deleteHelpCheck: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 标题 */
        title?: string;
        /** 整改状态 */
        reform_status?: string;
        /** 整改截止时间 */
        reform_end_time?: string;
        /** 整改完成时间 */
        reform_finish_time?: string;
        /** 问题描述 */
        question_desc?: string;
        /** 整改要求 */
        requirements?: string;
        /** 整改前照片 */
        pre_photos?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 标段ID */
        project_id?: string;
        /** 单位ID */
        dept_id?: string;
        /** 整改人ID */
        reform_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteHelpCheck`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteHazardProject
     * @summary 删除危大工程
     * @request POST:/dteqms/deleteHazardProject
     * @secure
     */
    deleteHazardProject: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 名称 */
        name?: string;
        /** 级别 */
        level?: string;
        /** 状态 */
        status?: string;
        /** 位置1 */
        position_1?: string;
        /** 位置1 */
        position_2?: string;
        /** 位置1 */
        position_3?: string;
        /** 位置1 */
        position_4?: string;
        /** 专项方案审批记录 */
        approval_record?: string;
        /** 专项方案论证记录 */
        rationale_record?: string;
        /** 施工批准记录 */
        constr_approval_record?: string;
        /** 航行通告(不停航施工） */
        navigation_bulletin?: string;
        /** 安全保证责任书 */
        safety_res_letter?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 标段id */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteHazardProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteExamStore
     * @summary 删除题库
     * @request POST:/dteqms/deleteExamStore
     * @secure
     */
    deleteExamStore: (
      query?: {
        /** 题目分类 */
        type?: string;
        /** 题目类型 */
        category?: string;
        /** 问题 */
        question?: string;
        /** 正确答案 */
        answer?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteExamStore`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteExamStoreMe
     * @summary 删除学习笔记-错题
     * @request POST:/dteqms/deleteExamStoreMe
     * @secure
     */
    deleteExamStoreMe: (
      query?: {
        /** 类型 */
        type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 题目ID */
        es_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteExamStoreMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteExamRecord
     * @summary 删除考试记录
     * @request POST:/dteqms/deleteExamRecord
     * @secure
     */
    deleteExamRecord: (
      query?: {
        /** 考试类型 */
        exam_type?: string;
        /** 考试分数 */
        score?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteExamRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteExamConf
     * @summary 删除题库配置
     * @request POST:/dteqms/deleteExamConf
     * @secure
     */
    deleteExamConf: (
      query?: {
        /** 每题分数 */
        score?: string;
        /** 题目数量 */
        cnt?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteExamConf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteCraftsmanship
     * @summary 行业匠心
     * @request POST:/dteqms/deleteCraftsmanship
     * @secure
     */
    deleteCraftsmanship: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 标题 */
        title?: string;
        /** 解决问题描述 */
        content?: string;
        /** 是否支持App操作 */
        is_support_app?: string;
        /** 学习成本 */
        learning_cost?: string;
        /** 轮播图片地址 */
        turning_picture?: string;
        /** 其他图片地址 */
        other_picture?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteCraftsmanship`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteConveningOfMeeting
     * @summary 删除会议召集
     * @request POST:/dteqms/deleteConveningOfMeeting
     * @secure
     */
    deleteConveningOfMeeting: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 会议标题 */
        m_title?: string;
        /** 会议内容 */
        m_content?: string;
        /** 会议时间 */
        m_time?: string;
        /** 会议地点 */
        m_address?: string;
        /** 现场照片地址 */
        m_scence_picture?: string;
        /** 附件 */
        attachment?: string;
        /** 是否包含施工安全方面内容 */
        has_safety_content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteConveningOfMeeting`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteCollectStoreMe
     * @summary 删除学习笔记-收藏
     * @request POST:/dteqms/deleteCollectStoreMe
     * @secure
     */
    deleteCollectStoreMe: (
      query?: {
        /** 类型 */
        type?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 题目ID */
        es_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteCollectStoreMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseUser
     * @summary 删除用户
     * @request POST:/dteqms/deleteBaseUser
     * @secure
     */
    deleteBaseUser: (
      query?: {
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone?: string;
        /** 密码 */
        pwd?: string;
        /** 传真电话 */
        tel?: string;
        /** E-Mail */
        email?: string;
        /** 地址 */
        address?: string;
        /** 性别 */
        gender?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 单位ID */
        dept_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseUserSign
     * @summary 删上班签到
     * @request POST:/dteqms/deleteBaseUserSign
     * @secure
     */
    deleteBaseUserSign: (
      query?: {
        /** 签到类型 */
        type?: string;
        /** 签到图片 */
        img?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标段id */
        project_id?: string;
        /** 审核用户id */
        check_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseUserRole
     * @summary 删除用户角色关系
     * @request POST:/dteqms/deleteBaseUserRole
     * @secure
     */
    deleteBaseUserRole: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户ID */
        uid?: string;
        /** 角色ID */
        role_id?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseUserRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseSectionWork
     * @summary 删除工作区
     * @request POST:/dteqms/deleteBaseSectionWork
     * @secure
     */
    deleteBaseSectionWork: (
      query?: {
        /** 名称 */
        name?: string;
        /** 工作区范围 */
        map_range?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标段工法ID */
        section_method_id?: string;
        /** 标段ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseSectionWork`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseSectionMethod
     * @summary 删除标段工法
     * @request POST:/dteqms/deleteBaseSectionMethod
     * @secure
     */
    deleteBaseSectionMethod: (
      query?: {
        /** 工法类型 */
        method_type?: string;
        /** 施工组织设计 */
        design?: string;
        /** 详细施工方案 */
        scheme?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 标段ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseSectionMethod`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseRole
     * @summary 删除角色
     * @request POST:/dteqms/deleteBaseRole
     * @secure
     */
    deleteBaseRole: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 描述 */
        memo?: string;
        /** 角色授权 */
        grants?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 单位ID */
        dept_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseRoleDeptType
     * @summary 删除角色与单位类型关系表
     * @request POST:/dteqms/deleteBaseRoleDeptType
     * @secure
     */
    deleteBaseRoleDeptType: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 单位类型(121) */
        dept_type?: string;
        /** 授权 */
        grants?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseRoleDeptType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseRegion
     * @summary 删除区域
     * @request POST:/dteqms/deleteBaseRegion
     * @secure
     */
    deleteBaseRegion: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 父ID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseRegion`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseProject7
     * @summary 删除分项
     * @request POST:/dteqms/deleteBaseProject7
     * @secure
     */
    deleteBaseProject7: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseProject7`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseProject6
     * @summary 删除标段
     * @request POST:/dteqms/deleteBaseProject6
     * @secure
     */
    deleteBaseProject6: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseProject6`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseProject5
     * @summary 删除监理标段
     * @request POST:/dteqms/deleteBaseProject5
     * @secure
     */
    deleteBaseProject5: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 地图范围 */
        map_range?: string;
        /** 层级 */
        level?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseProject5`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseProject4
     * @summary 删除分部
     * @request POST:/dteqms/deleteBaseProject4
     * @secure
     */
    deleteBaseProject4: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseProject4`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseProject3
     * @summary 删除项目
     * @request POST:/dteqms/deleteBaseProject3
     * @secure
     */
    deleteBaseProject3: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseProject3`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseProject2
     * @summary 删除工程
     * @request POST:/dteqms/deleteBaseProject2
     * @secure
     */
    deleteBaseProject2: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseProject2`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseNotice
     * @summary 删除系统通知
     * @request POST:/dteqms/deleteBaseNotice
     * @secure
     */
    deleteBaseNotice: (
      query?: {
        /** 处理状态 */
        deal_state?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户ID */
        uid?: string;
        /** 会议ID */
        mid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseMethodType
     * @summary 删除工法类型
     * @request POST:/dteqms/deleteBaseMethodType
     * @secure
     */
    deleteBaseMethodType: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseMethodType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseFunctUserCommon
     * @summary 删除用户常用功能配置
     * @request POST:/dteqms/deleteBaseFunctUserCommon
     * @secure
     */
    deleteBaseFunctUserCommon: (
      query?: {
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户ID */
        user_id?: string;
        /** 功能ID */
        funct_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseFunctUserCommon`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteBaseDept
     * @summary 删除单位
     * @request POST:/dteqms/deleteBaseDept
     * @secure
     */
    deleteBaseDept: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 所属管理局 */
        admin_room?: string;
        /** 所属地区质检站 */
        quality_station?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 所属地区ID */
        region_id?: string;
        /** 父ID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteBaseDept`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteAdvice
     * @summary 删除意见征求
     * @request POST:/dteqms/deleteAdvice
     * @secure
     */
    deleteAdvice: (
      query?: {
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 标题 */
        title?: string;
        /** 内容 */
        content?: string;
        /** 附件地址 */
        attachment?: string;
        /** 截止时间 */
        end_time?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteAdvice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteAdviceRecord
     * @summary 删除意见征求评价
     * @request POST:/dteqms/deleteAdviceRecord
     * @secure
     */
    deleteAdviceRecord: (
      query?: {
        /** 评价内容 */
        content?: string;
        /** ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 意见征求ID */
        advice_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteAdviceRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name DeleteAddressBook
     * @summary 删除通讯录
     * @request POST:/dteqms/deleteAddressBook
     * @secure
     */
    deleteAddressBook: (
      query?: {
        /** 通讯录ID */
        id?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 所属工程 */
        project_id?: string;
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone?: string;
        /** 单位ID */
        dept_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/deleteAddressBook`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CommWeather
     * @summary 获取天气情况
     * @request POST:/dteqms/commWeather
     * @secure
     */
    commWeather: (params: RequestParams = {}) =>
      this.request<JSONObject, any>({
        path: `/dteqms/commWeather`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CommUserUnlock
     * @summary 用户解锁
     * @request POST:/dteqms/commUserUnlock
     * @secure
     */
    commUserUnlock: (
      query: {
        /** 用户ID */
        uid: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/commUserUnlock`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CommUserSmsCode
     * @summary 获取短信验证码
     * @request POST:/dteqms/commUserSmsCode
     * @secure
     */
    commUserSmsCode: (
      query: {
        /** 手机号 */
        phone: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/commUserSmsCode`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CommUserSign
     * @summary 用户签到
     * @request POST:/dteqms/commUserSign
     * @secure
     */
    commUserSign: (
      query: {
        /** 签到类型(1:直接打卡;2:拍照打卡) */
        sign_type: string;
        /** 签到照片 */
        sign_img?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/commUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CommUserRepwd
     * @summary 用户修改密码
     * @request POST:/dteqms/commUserRepwd
     * @secure
     */
    commUserRepwd: (
      query: {
        /** 用户ID */
        uid?: string;
        /** 密码 */
        pwd: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/commUserRepwd`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CommUserLogin
     * @summary 用户登录
     * @request POST:/dteqms/commUserLogin
     * @secure
     */
    commUserLogin: (
      query: {
        /** 手机号 */
        phone: string;
        /** 短信验证码 */
        sms_code?: string;
        /** 密码 */
        pwd?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/commUserLogin`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CommUserGrants
     * @summary 用户权限
     * @request POST:/dteqms/commUserGrants
     * @secure
     */
    commUserGrants: (
      query: {
        /** 工程ID */
        project_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/commUserGrants`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CommGetUserSign
     * @summary 获取用户签到
     * @request POST:/dteqms/commGetUserSign
     * @secure
     */
    commGetUserSign: (
      query: {
        /** 开始日期 */
        start_date: string;
        /** 结束日期 */
        end_date: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/commGetUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CommEditUser
     * @summary 修改个人资料
     * @request POST:/dteqms/commEditUser
     * @secure
     */
    commEditUser: (
      query?: {
        /** 头像 */
        avatar?: string;
        /** 昵称 */
        nick_name?: string;
        /** 性别 */
        gender?: string;
        /** 生日 */
        birthday?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/commEditUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags check-controller
     * @name CheckAuditingDual
     * @summary 功能审核
     * @request POST:/dteqms/checkAuditingDual
     * @secure
     */
    checkAuditingDual: (
      query: {
        /** 表名 */
        table_name: string;
        /** ID */
        id: string;
        /** 审核状态(1:暂存;2:待审核;3:审核通过;4:驳回) */
        check_state: string;
        /** 审核建议 */
        check_advise?: string;
        /** 交底状态 */
        status?: string;
        /** 审批状态 */
        approval_state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/checkAuditingDual`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name BatchNoticeByType
     * @summary 根会议状态更改为已读
     * @request POST:/dteqms/batchNoticeByType
     * @secure
     */
    batchNoticeByType: (
      query?: {
        /** 类型(1:会议通知;2:安全会议;) */
        type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/batchNoticeByType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddWarningCase
     * @summary 添加警示案列
     * @request POST:/dteqms/addWarningCase
     * @secure
     */
    addWarningCase: (
      query: {
        /** 标题 */
        title: string;
        /** 内容 */
        content: string;
        /** 附件 */
        attachment: string;
        /** 可见范围 */
        visibility: string;
        /** 所属地区 */
        visibility_region?: string;
        /** 所属项目 */
        visibility_project?: string;
        /** 数据状态 */
        data_state: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 工程名称 */
        project_id?: string;
        /** 审核建议 */
        check_advise?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addWarningCase`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddTechnologyHelp
     * @summary 添加技术求助
     * @request POST:/dteqms/addTechnologyHelp
     * @secure
     */
    addTechnologyHelp: (
      query: {
        /** 标题 */
        title: string;
        /** 求助内容 */
        content: string;
        /** 是否愿意付费解决 */
        is_pay: string;
        /** 付费详情 */
        pay_details?: string;
        /** 是否匿名 */
        is_anony: string;
        /** 求助附件 */
        attachment?: string;
        /** 可见范围 */
        visibility: string;
        /** 所属地区 */
        visibility_region?: string;
        /** 所属项目 */
        visibility_project?: string;
        /** 数据状态 */
        data_state: string;
        /** 所属工程 */
        project_id?: string;
        /** 解决状态 */
        status?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addTechnologyHelp`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddTechnologyHelpRecord
     * @summary 添加技术求助记录
     * @request POST:/dteqms/addTechnologyHelpRecord
     * @secure
     */
    addTechnologyHelpRecord: (
      query: {
        /** 评价内容 */
        content: string;
        /** 是否匿名 */
        is_anony?: string;
        /** 附件 */
        attachment?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 技术求助ID */
        help_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addTechnologyHelpRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddSystemNotice
     * @summary 添加系统通知
     * @request POST:/dteqms/addSystemNotice
     * @secure
     */
    addSystemNotice: (
      query: {
        /** 标题 */
        title: string;
        /** 内容 */
        content: string;
        /** 有效时间 */
        period_time: string;
        /** 创建用户id */
        create_user?: string;
        /** 更新用户id */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addSystemNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddSafetyUserSign
     * @summary 添加安全员打卡
     * @request POST:/dteqms/addSafetyUserSign
     * @secure
     */
    addSafetyUserSign: (
      query?: {
        /** 签到图片 */
        img?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 签到类型 */
        type?: string;
        /** 标段id */
        project_id?: string;
        /** 审核用户id */
        check_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addSafetyUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddSafetyTraining
     * @summary 添加安全培训
     * @request POST:/dteqms/addSafetyTraining
     * @secure
     */
    addSafetyTraining: (
      query: {
        /** 培训名称 */
        train_name: string;
        /** 所属监理标段 */
        project_id5: string;
        /** 所属标段 */
        project_id: string;
        /** 进场类别 */
        entry_category: string;
        /** 培训类型 */
        train_type: string;
        /** 备注 */
        remark?: string;
        /** 培训形式 */
        train_format?: string;
        /** 培训时间 */
        meeting_time?: string;
        /** 培训内容简介 */
        train_desc?: string;
        /** 现场照片 */
        scene_photos?: string;
        /** 签到表照片 */
        sign_photos?: string;
        /** 附件 */
        attachment?: string;
        /** 数据状态 */
        data_state: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 培训单位 */
        dept_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addSafetyTraining`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddSafetyReform
     * @summary 添加安全整改
     * @request POST:/dteqms/addSafetyReform
     * @secure
     */
    addSafetyReform: (
      query: {
        /** 所属标段 */
        project_id: string;
        /** 所属单位 */
        dept_id: string;
        /** 标题 */
        title: string;
        /** 问题描述 */
        question_desc: string;
        /** 整改要求 */
        requirements: string;
        /** 整改截止时间 */
        reform_end_time: string;
        /** 整改前照片 */
        pre_photos: string;
        /** 数据状态 */
        data_state: string;
        /** 整改人 */
        reform_uid?: string;
        /** 整改完成时间 */
        reform_finish_time?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 审核状态 */
        check_state?: string;
        /** 整改状态 */
        reform_status?: string;
        /** 整改后照片 */
        suf_photos?: string;
        /** 单位类型 */
        dept_type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addSafetyReform`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddProjectReport
     * @summary 添加项目通报
     * @request POST:/dteqms/addProjectReport
     * @secure
     */
    addProjectReport: (
      query: {
        /** 标题 */
        title: string;
        /** 通报类型 */
        type: string;
        /** 附件 */
        attachment?: string;
        /** 通报描述 */
        content: string;
        /** 可见范围 */
        visibility: string;
        /** 所属项目 */
        visibility_project?: string;
        /** 所属地区 */
        visibility_region?: string;
        /** 数据状态 */
        data_state: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 工程ID */
        project_id?: string;
        /** 通报状态 */
        check_state?: string;
        /** 发布状态 */
        publish_status?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addProjectReport`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddPersonalLedger
     * @summary 添加人员台账
     * @request POST:/dteqms/addPersonalLedger
     * @secure
     */
    addPersonalLedger: (
      query: {
        /** 所属标段 */
        project_id: string;
        /** 台账类型 */
        type: string;
        /** 附件 */
        attachment: string;
        /** 数据状态 */
        data_state: string;
        /** 设备名称 */
        name?: string;
        /** 操作员 */
        operator?: string;
        /** 联系方式 */
        contact?: string;
        /** 事由 */
        reason?: string;
        /** 备注 */
        remark?: string;
        /** 审核状态 */
        check_state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addPersonalLedger`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddPenaltyNotice
     * @summary 添加处罚公示
     * @request POST:/dteqms/addPenaltyNotice
     * @secure
     */
    addPenaltyNotice: (
      query: {
        /** 处罚类别 */
        penalty_type: string;
        /** 案由 */
        cause_action: string;
        /** 处罚决定书编号 */
        penalty_number: string;
        /** 被处罚单位 */
        dept_id: string;
        /** 执行情况 */
        execute_state: string;
        /** 可见范围 */
        visibility: string;
        /** 所属地区 */
        visibility_region?: string;
        /** 所属项目 */
        visibility_project?: string;
        /** 处罚决定内容 */
        penalty_desc: string;
        /** 附件 */
        attachment?: string;
        /** 数据状态 */
        data_state: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 处罚工程 */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addPenaltyNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddLaw
     * @summary 添加法律法规
     * @request POST:/dteqms/addLaw
     * @secure
     */
    addLaw: (
      query: {
        /** 公告栏目 */
        column_type: string;
        /** 标题 */
        name: string;
        /** 编号 */
        code?: string;
        /** 内容 */
        content?: string;
        /** 附件 */
        attachment: string;
        /** 实施日期 */
        implement_date?: string;
        /** 颁布日期 */
        promulgate_date?: string;
        /** 数据状态 */
        data_state: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addLaw`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddLawRecord
     * @summary 添加法律法规建议
     * @request POST:/dteqms/addLawRecord
     * @secure
     */
    addLawRecord: (
      query: {
        /** 建议内容 */
        content: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 法律法规ID */
        law_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addLawRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddIndustryExplore
     * @summary 添加先行者探索
     * @request POST:/dteqms/addIndustryExplore
     * @secure
     */
    addIndustryExplore: (
      query: {
        /** 标题 */
        title: string;
        /** 提出人 */
        proposer: string;
        /** 提出单位 */
        propose_unit: string;
        /** 使用项目数 */
        usepro_number: string;
        /** 学习成本 */
        learning_cost: string;
        /** 解决问题描述 */
        content: string;
        /** 轮播图片 */
        turning_picture: string;
        /** 其他图片 */
        other_picture?: string;
        /** 是否支持App操作 */
        is_support_app?: string;
        /** 数据状态 */
        data_state: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addIndustryExplore`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddHelpCheck
     * @summary 添加帮扶检查
     * @request POST:/dteqms/addHelpCheck
     * @secure
     */
    addHelpCheck: (
      query: {
        /** 所属标段 */
        project_id: string;
        /** 所属单位 */
        dept_id: string;
        /** 检查日期 */
        check_date: string;
        /** 检查形式 */
        check_form: string;
        /** 备注 */
        remark?: string;
        /** 整改前照片 */
        pre_photos: string;
        /** 问题描述 */
        question_desc: string;
        /** 依据 */
        basis?: string;
        /** 数据状态 */
        data_state: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 标题 */
        title?: string;
        /** 整改状态 */
        reform_status?: string;
        /** 整改人ID */
        reform_uid?: string;
        /** 整改完成时间 */
        reform_finish_time?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 整改后照片 */
        suf_photos?: string;
        /** 整改要求 */
        requirements?: string;
        /** 整改截止时间 */
        reform_end_time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addHelpCheck`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddHazardProject
     * @summary 添加危大工程
     * @request POST:/dteqms/addHazardProject
     * @secure
     */
    addHazardProject: (
      query: {
        /** 所属标段 */
        project_id: string;
        /** 施工单位名称 */
        dept_id: string;
        /** 危大工程名称 */
        name: string;
        /** 级别 */
        level: string;
        /** 专项方案审批记录 */
        approval_record?: string;
        /** 专项方案论证记录 */
        rationale_record?: string;
        /** 不停航施工组织方案批准记录 */
        constr_approval_record?: string;
        /** 航行通告 */
        navigation_bulletin?: string;
        /** 安全保证责任书 */
        safety_res_letter?: string;
        /** 其他 */
        other?: string;
        /** 专职安管人员 */
        appoint_person?: string;
        /** 数据状态 */
        data_state: string;
        /** 危大工程分项 */
        itemize?: string;
        /** 交底/验收状态 */
        status?: string;
        /** 位置1 */
        position_1?: string;
        /** 位置2 */
        position_2?: string;
        /** 位置3 */
        position_3?: string;
        /** 位置4 */
        position_4?: string;
        /** 审核状态 */
        check_state?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 审批状态 */
        approval_state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addHazardProject`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddExamStore
     * @summary 添加题库
     * @request POST:/dteqms/addExamStore
     * @secure
     */
    addExamStore: (
      query: {
        /** 题目分类 */
        type: string;
        /** 题目类型 */
        category: string;
        /** 问题 */
        question: string;
        /** 正确答案 */
        answer: string;
        /** 选项 */
        option: string;
        /** 出处 */
        source?: string;
        /** 章节 */
        chapter?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addExamStore`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddExamStoreMe
     * @summary 添加学习笔记-错题
     * @request POST:/dteqms/addExamStoreMe
     * @secure
     */
    addExamStoreMe: (
      query?: {
        /** 类型 */
        type?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 题目ID */
        es_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addExamStoreMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddExamRecord
     * @summary 添加考试记录
     * @request POST:/dteqms/addExamRecord
     * @secure
     */
    addExamRecord: (
      query?: {
        /** 考试类型 */
        exam_type?: string;
        /** 考试分数 */
        score?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addExamRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddExamConf
     * @summary 添加题库配置
     * @request POST:/dteqms/addExamConf
     * @secure
     */
    addExamConf: (
      query?: {
        /** 题目分类 */
        type?: string;
        /** 每题分数 */
        score?: string;
        /** 题目数量 */
        cnt?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addExamConf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddCraftsmanship
     * @summary 行业匠心
     * @request POST:/dteqms/addCraftsmanship
     * @secure
     */
    addCraftsmanship: (
      query: {
        /** 标题 */
        title: string;
        /** 提出人 */
        proposer: string;
        /** 提出单位 */
        propose_unit: string;
        /** 使用项目数 */
        usepro_number: string;
        /** 是否支持App操作 */
        is_support_app?: string;
        /** 学习成本 */
        learning_cost: string;
        /** 解决问题描述 */
        content: string;
        /** 轮播图片 */
        turning_picture: string;
        /** 其他图片 */
        other_picture?: string;
        /** 数据状态 */
        data_state: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 类型 */
        type?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addCraftsmanship`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name AddConveneNotice
     * @summary 添加安全会议召集
     * @request POST:/dteqms/addConveneNotice
     * @secure
     */
    addConveneNotice: (
      query?: {
        /**
         * 数据状态
         * @format int32
         */
        data_state?: number;
        /**
         * 审核状态
         * @format int32
         */
        check_state?: number;
        /**
         * 审核用户id
         * @format int32
         */
        check_uid?: number;
        /** 审核建议 */
        check_advise?: string;
        /**
         * 工程id
         * @format int32
         */
        project_id?: number;
        /**
         * 类型
         * @format int32
         */
        type?: number;
        /** 会议标题 */
        m_title?: string;
        /** 会议内容 */
        m_content?: string;
        /** 会议时间 */
        m_time?: string;
        /** 会议地址 */
        m_address?: string;
        /** 现场照片 */
        m_scence_picture?: string;
        /** 附件 */
        attachment?: string;
        /**
         * 是否包含施工安全方面
         * @format int32
         */
        has_safety_content?: number;
        /** 与会人 */
        attendees?: string;
        /**
         * 短信并行通知
         * @format int32
         */
        mes_notice?: number;
        /** 签到表 */
        att_sheet?: string;
        /**
         * 单位
         * @format int32
         */
        dept_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/addConveneNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name AddConferenceNotice
     * @summary 添加会议通知
     * @request POST:/dteqms/addConferenceNotice
     * @secure
     */
    addConferenceNotice: (
      query?: {
        /**
         * 数据状态
         * @format int32
         */
        data_state?: number;
        /**
         * 审核状态
         * @format int32
         */
        check_state?: number;
        /**
         * 审核用户id
         * @format int32
         */
        check_uid?: number;
        /** 审核建议 */
        check_advise?: string;
        /**
         * 工程id
         * @format int32
         */
        project_id?: number;
        /**
         * 类型
         * @format int32
         */
        type?: number;
        /** 会议标题 */
        m_title?: string;
        /** 会议内容 */
        m_content?: string;
        /** 会议时间 */
        m_time?: string;
        /** 会议地址 */
        m_address?: string;
        /** 现场照片 */
        m_scence_picture?: string;
        /** 附件 */
        attachment?: string;
        /**
         * 是否包含施工安全方面
         * @format int32
         */
        has_safety_content?: number;
        /** 与会人 */
        attendees?: string;
        /**
         * 短信并行通知
         * @format int32
         */
        mes_notice?: number;
        /** 签到表 */
        att_sheet?: string;
        /**
         * 单位id
         * @format int32
         */
        dept_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/addConferenceNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddCollectStoreMe
     * @summary 添加学习笔记-收藏
     * @request POST:/dteqms/addCollectStoreMe
     * @secure
     */
    addCollectStoreMe: (
      query?: {
        /** 类型 */
        type?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 题目ID */
        es_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addCollectStoreMe`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name AddBaseUser
     * @summary 添加用户
     * @request POST:/dteqms/addBaseUser
     * @secure
     */
    addBaseUser: (
      query: {
        /**
         * 单位名称
         * @format int32
         */
        dept_id?: number;
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone: string;
        /** 电话 */
        tel?: string;
        /** 邮箱 */
        email?: string;
        /** 地址 */
        address?: string;
        /**
         * 性别
         * @format int32
         */
        gender?: number;
        /** 备注 */
        remark?: string;
        /** 头像 */
        avatar?: string;
        /** 生日 */
        birthday?: string;
        /** 昵称 */
        nick_name?: string;
        /** 密码 */
        pwd: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/addBaseUser`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseUserSign
     * @summary 添加上班签到
     * @request POST:/dteqms/addBaseUserSign
     * @secure
     */
    addBaseUserSign: (
      query?: {
        /** 签到图片 */
        img?: string;
        /** 审核状态 */
        check_state?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 签到类型 */
        type?: string;
        /** 标段id */
        project_id?: string;
        /** 审核用户id */
        check_uid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseUserSign`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseUserRole
     * @summary 添加用户角色关系
     * @request POST:/dteqms/addBaseUserRole
     * @secure
     */
    addBaseUserRole: (
      query?: {
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 用户ID */
        uid?: string;
        /** 角色ID */
        role_id?: string;
        /** 工程ID */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseUserRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseSectionWork
     * @summary 添加工作区
     * @request POST:/dteqms/addBaseSectionWork
     * @secure
     */
    addBaseSectionWork: (
      query: {
        /** 标段 */
        project_id: string;
        /** 施工单位 */
        dept_id: string;
        /** 标段工法 */
        section_method_id: string;
        /** 名称 */
        name: string;
        /** 工作区范围 */
        map_range: string;
        /** 附件 */
        attach: string;
        /** 数据状态 */
        data_state: string;
        /** 施工开始日期 */
        start_time: string;
        /** 施工完工日期 */
        end_time: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 完成状态 */
        finish_status?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseSectionWork`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseSectionMethod
     * @summary 添加标段工法
     * @request POST:/dteqms/addBaseSectionMethod
     * @secure
     */
    addBaseSectionMethod: (
      query: {
        /** 标段 */
        project_id: string;
        /** 工法类型 */
        method_type: string;
        /** 施工组织设计 */
        design?: string;
        /** 详细施工方案 */
        scheme?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseSectionMethod`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseRole
     * @summary 添加角色
     * @request POST:/dteqms/addBaseRole
     * @secure
     */
    addBaseRole: (
      query: {
        /** 角色名称 */
        name: string;
        /** 描述 */
        memo: string;
        /** 单位名称 */
        dept_id: string;
        /** 角色授权 */
        grants: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 排序 */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseRole`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name AddBaseRoleDeptType
     * @summary 添加用户角色关系
     * @request POST:/dteqms/addBaseRoleDeptType
     * @secure
     */
    addBaseRoleDeptType: (
      query?: {
        /** 名称 */
        name?: string;
        /**
         * 单位类型
         * @format int32
         */
        dept_type?: number;
        /** 权限 */
        grants?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/addBaseRoleDeptType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseRegion
     * @summary 添加区域
     * @request POST:/dteqms/addBaseRegion
     * @secure
     */
    addBaseRegion: (
      query: {
        /** 地区名称 */
        name: string;
        /** 描述 */
        remark?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 父ID */
        pid?: string;
        /** 排序 */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseRegion`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseProject7
     * @summary 添加分项
     * @request POST:/dteqms/addBaseProject7
     * @secure
     */
    addBaseProject7: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseProject7`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseProject6
     * @summary 添加标段
     * @request POST:/dteqms/addBaseProject6
     * @secure
     */
    addBaseProject6: (
      query?: {
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** 控制点文件 */
        ctrl_file?: string;
        /** 定位点坐标(经度,纬度) */
        fixed_position?: string;
        /** 专职安全管理人员数量 */
        safety_number?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
        /** 层级 */
        level?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseProject6`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseProject5
     * @summary 添加监理标段
     * @request POST:/dteqms/addBaseProject5
     * @secure
     */
    addBaseProject5: (
      query?: {
        /** 排序 */
        sort?: string;
        /** 名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 结束时间 */
        end_time?: string;
        /** 开工时间 */
        start_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** 专职安全管理人员数量 */
        safety_number?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseProject5`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseProject4
     * @summary 添加分部
     * @request POST:/dteqms/addBaseProject4
     * @secure
     */
    addBaseProject4: (
      query?: {
        /** 分部名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseProject4`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseProject3
     * @summary 添加项目
     * @request POST:/dteqms/addBaseProject3
     * @secure
     */
    addBaseProject3: (
      query?: {
        /** 项目名称 */
        name?: string;
        /** 编码 */
        code?: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 定位点坐标(经度,纬度) */
        fixed_position?: string;
        /** 控制点文件 */
        ctrl_file?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 排序 */
        sort?: string;
        /** PID */
        pid?: string;
        /** 层级 */
        level?: string;
        /** 地图范围 */
        map_range?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 备注 */
        remark?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseProject3`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name AddBaseProject2
     * @summary 添加工程
     * @request POST:/dteqms/addBaseProject2
     * @secure
     */
    addBaseProject2: (
      query: {
        /** 所属地区 */
        pid: string;
        /** 工程名称 */
        name: string;
        /** 编码 */
        code: string;
        /** 所属机场 */
        airport?: string;
        /** 开工时间 */
        start_time?: string;
        /** 结束时间 */
        end_time?: string;
        /** 图片 */
        img?: string;
        /** 描述 */
        description?: string;
        /** 附件 */
        attach?: string;
        /** 工程状态 */
        finish_status?: string;
        /** 地图范围 */
        map_range?: string;
        /** 专职安全管理人员数量 */
        safety_number?: string;
        /** 控制点文件 */
        ctrl_file?: string;
        /** 定位点坐标(经纬度) */
        fixed_position?: string;
        /** 备注 */
        remark?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/addBaseProject2`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseNotice
     * @summary 添加系统通知
     * @request POST:/dteqms/addBaseNotice
     * @secure
     */
    addBaseNotice: (
      query?: {
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 用户ID */
        uid?: string;
        /** 会议ID */
        mid?: string;
        /** 处理状态 */
        deal_state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseNotice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseMethodType
     * @summary 添加工法类型
     * @request POST:/dteqms/addBaseMethodType
     * @secure
     */
    addBaseMethodType: (
      query: {
        /** 工法名称 */
        name: string;
        /** 描述 */
        remark?: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 排序 */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseMethodType`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddBaseFunctUserCommon
     * @summary 添加用户常用功能配置
     * @request POST:/dteqms/addBaseFunctUserCommon
     * @secure
     */
    addBaseFunctUserCommon: (
      query: {
        /** 功能 */
        funct_id: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 创建时间 */
        create_time?: string;
        /** 更新时间 */
        update_time?: string;
        /** 用户 */
        user_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addBaseFunctUserCommon`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dept-controller
     * @name AddBaseDept
     * @summary 添加单位
     * @request POST:/dteqms/addBaseDept
     * @secure
     */
    addBaseDept: (
      query: {
        /** 所属地区 */
        region_id: string;
        /** 单位类型 */
        type: string;
        /** 单位名称 */
        name: string;
        /** 附件 */
        attach?: string;
        /** 描述 */
        remark?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/addBaseDept`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 最后修改时间:2024-10-31 10:59:15
     *
     * @tags dteqms-1732697495363
     * @name AddAdvice
     * @summary 添加意见征求
     * @request POST:/dteqms/addAdvice
     * @secure
     */
    addAdvice: (
      query: {
        /** 标题 */
        title: string;
        /** 内容 */
        content: string;
        /** 截止时间 */
        end_time?: string;
        /** 可见范围 */
        visibility: string;
        /** 所属地区 */
        visibility_region?: string;
        /** 所属项目 */
        visibility_project?: string;
        /** 附件 */
        attachment?: string;
        /** 数据状态 */
        data_state: string;
        /** 创建用户ID */
        create_user?: string;
        /** 更新用户ID */
        update_user?: string;
        /** 审核状态 */
        check_state: string;
        /** 审核用户id */
        check_uid?: string;
        /** 审核建议 */
        check_advise?: string;
        /** 所属工程 */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/dteqms/addAdvice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name AddAdviceRecord
     * @summary 添加意见征求评价
     * @request POST:/dteqms/addAdviceRecord
     * @secure
     */
    addAdviceRecord: (
      query: {
        /** 建议内容 */
        content: string;
        /**
         * 意见征求ID
         * @format int32
         */
        advice_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/addAdviceRecord`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags eqms-controller
     * @name AddAddressBook
     * @summary 添加通讯录
     * @request POST:/dteqms/addAddressBook
     * @secure
     */
    addAddressBook: (
      query?: {
        /** 姓名 */
        name?: string;
        /** 手机号 */
        phone?: string;
        /** 职务 */
        duties?: string;
        /**
         * 所属地区
         * @format int32
         */
        region_id?: number;
        /**
         * 单位
         * @format int32
         */
        dept_id?: number;
        /**
         * 项目id
         * @format int32
         */
        project_id3?: number;
        /**
         * 监理标段id
         * @format int32
         */
        project_id5?: number;
        /**
         * 标段id
         * @format int32
         */
        project_id6?: number;
        /**
         * 单位类型
         * @format int32
         */
        type?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<JSONObject, any>({
        path: `/dteqms/addAddressBook`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),
  };
}
